/**
 * 人件費分析　月間データ・カレンダー
 */

import * as React from 'react';
import styled from 'styled-components';
import { ActivityIndicator } from '../../../../components/common';
import {
  baseFontSize,
  prefixUnit,
  postfixUnit,
  Decimal,
  LocaleInteger,
} from '../../../../components/common/atoms/Number';
import {
  uploadBorderColor,
  airblue,
  red,
  textLinkColor,
  white,
  black,
  blueBg,
} from '../../../../constants/colors';
import { CROSSSELL_PRODUCT_TYPE } from '../../../../constants/crossSellProduct';
import { CROSSSELL_MODAL_TYPE } from '../LaborCostAnalysisConstants';
import { actions } from '../../../../modules/laborCostAnalysis';
import { track } from '../../../../modules/logging';
import { StoresData } from '../../../../modules/user';
import { MonthlyLaborCostResponse, MonthlyLaborCostResponseDaily } from '../../../../typedef/api/LaborCost';
import { ApiState } from '../../../../typedef/api/Utility';
import { BatchProcessedDate } from '../../../../typedef/BatchProcessedDate';
import { AppealModal } from '../../../../components/common/appealModal/AppealModal';
import { assertNever } from '../../../../helpers/util';
import { genGaLog } from '../../../../gaLogger';
import OpenLink from '../../../../icons/openLink.svg';
import HumanoidIcon from '../../../../icons/HumanoidIconGray.svg';
import YenIcon from '../../../../icons/YenIconGray.svg';
import TimeIcon from '../../../../icons/TimeIconGray.svg';
import ArrowBlueRight from '../../../../icons/arrowBlueRight.svg';
import Hint from '../../../../icons/hint.svg';
import RightArrow from '../../../../icons/arrowBlueRightNormal.svg';
import Close from '../../../../icons/close.svg';

import { formatter, mclDayjs, parser } from '../../../../helpers/mclDate';
import Tooltip from '../../../../components/common/molecules/Tooltip/UpperLeftPortal';
import CalendarSamplePopup from './CalendarSamplePopup';
import { LABOR_COST_CALENDAR_POPUP, LABOR_COST_SHIFT_BANNER } from '../../../../constants/LocalStorage';
import { ShiftImportSettingForm } from '../../../../typedef/api/ShiftImportSetting';
import SalesInputMethodModal from './SalesInputMethodModal';
import { Link } from 'react-router-dom';
import { hourlyWageSettingFaq } from '../../../../constants/faqUrls';

type Props = {
  readonly storeData?: StoresData;
  yearMonth: string;
  date: string;
  batchProcessedDate: BatchProcessedDate;
  fetchDailyLaborCost: typeof actions.fetchDailyLaborCost;
  setDate: typeof actions.setDate;
  logger: typeof track;
  readonly showFactorModal: () => void;
  readonly monthlyLaborCostState: ApiState<{
    monthlyData: MonthlyLaborCostResponse;
    shiftImportSetting: ShiftImportSettingForm;
  }>;
  toTargetSetting: () => void;
};

type State = {
  isShowModal: boolean;
  isShowSalesInputMethodModal: boolean;
  isShowPopup: boolean;
  isShowFactorLegendModal: boolean;
  isShowShiftBanner: boolean;
};

const FormatRate = baseFontSize(18)(postfixUnit('%')(Decimal));
const FormatSummaryRate = baseFontSize(22)(postfixUnit('%')(Decimal));
const FormatGoalRate = baseFontSize(12)(postfixUnit('%')(Decimal));
const FormatYen = baseFontSize(20)(prefixUnit('¥')(LocaleInteger));
const FormatYenSmall = baseFontSize(12)(prefixUnit('¥')(LocaleInteger));

class MonthlyLaborCost extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isShowModal: false,
      isShowSalesInputMethodModal: false,
      isShowPopup: false,
      isShowFactorLegendModal: false,
      isShowShiftBanner: false,
    };
  }

  componentDidMount(): void {
    // カレンダーサンプルポップアップを初回表示したか確認
    if (localStorage.getItem(LABOR_COST_CALENDAR_POPUP) == null) {
      this.setState({ isShowPopup: true });
      localStorage.setItem(LABOR_COST_CALENDAR_POPUP, 'show');
    }
    // シフト訴求バナーを初回表示したか確認
    if (localStorage.getItem(LABOR_COST_SHIFT_BANNER) == null) {
      this.setState({ isShowShiftBanner: true });
    }
  }

  render() {
    const {
      yearMonth,
      date,
      batchProcessedDate,
      storeData,
      monthlyLaborCostState,
      showFactorModal,
      toTargetSetting,
    } = this.props;
    const { fetchDailyLaborCost, setDate, logger } = this.props;
    const { isShowModal, isShowPopup, isShowSalesInputMethodModal, isShowShiftBanner } = this.state;
    switch (monthlyLaborCostState.type) {
      case 'API_STATE_INITIAL':
      case 'API_STATE_STARTED':
        return (
          <Wrapper>
            <ActivityIndicator />
          </Wrapper>
        );
      case 'API_STATE_FAILED':
        return (
          <ErrorText>
            ページを更新してから再度実行してください。
            <br />
            また、何度も発生する場合はお問い合わせください。
          </ErrorText>
        );
      case 'API_STATE_COMPLETED':
        const { monthlyData, shiftImportSetting } = monthlyLaborCostState.payload;
        const yearMonthParam = mclDayjs(yearMonth, formatter.mapiYearMonth) || mclDayjs();

        const beginOfMonth = yearMonthParam.startOf('month');
        const endOfMonth = yearMonthParam.endOf('month');
        const headPushcount = beginOfMonth.weekDay() - 1;
        const footPushcount = 7 - endOfMonth.weekDay();

        const copyDataList: Array<MonthlyLaborCostResponseDaily | undefined> = [...monthlyData.daily];

        for (let i = 0; i < headPushcount; i++) {
          copyDataList.unshift(undefined);
        }

        for (let i = 0; i < footPushcount; i++) {
          copyDataList.push(undefined);
        }

        /**
         * attendance:打刻
         * confirmed_shift:確定シフト
         */
        const importType =
          shiftImportSetting.shiftImportSetting.shiftImportType === 'attendance'
            ? 'attendanceBasedValue'
            : 'confirmedShiftBasedValue';

        return (
          <Wrapper>
            {!storeData?.isShiftUse ? (
              monthlyData.monthly.salesInfo.sales === 0 ? (
                // シフト未useの場合 & 売上0 の場合、売上訴求バナー表示
                <Banner>
                  <Hint />
                  &ensp;
                  <p>売上を入力すると人件費率を表示できます。</p>&ensp;
                  <BannerButton
                    isAirBlue={true}
                    onClick={() => {
                      this.setState({ isShowSalesInputMethodModal: true });
                      logger(_genShowSalesInputMethodModalLog());
                    }}
                    fontSize={14}
                  >
                    売上の入力方法
                  </BannerButton>
                  &nbsp;
                  <RightArrow />
                </Banner>
              ) : (
                // シフト未useの場合 & 売上あり & シフト訴求バナーを一度も閉じていない場合、シフト訴求バナー表示
                <AppealShiftBanner isShowShiftBanner={isShowShiftBanner}>
                  <Hint />
                  &ensp;
                  <BannerButton
                    isAirBlue={false}
                    onClick={() => {
                      this.setState({ isShowModal: true });
                      logger(_genOpenShiftCrossUseBannerLinkLog());
                    }}
                    fontSize={12}
                  >
                    Airシフトから人件費を自動連携する
                  </BannerButton>
                  <CloseButton
                    onClick={() => {
                      localStorage.setItem(LABOR_COST_SHIFT_BANNER, 'close');
                      this.setState({ isShowShiftBanner: false });
                      logger(_genShiftCrossUseBannerCloseLog());
                    }}
                  >
                    <Close />
                  </CloseButton>
                </AppealShiftBanner>
              )
            ) : null}
            <TopPane>
              <Panel width={235}>
                <TopPaneData>
                  <TopPaneTitle>月間人件費率</TopPaneTitle>
                  <Coloring
                    isRed={
                      monthlyData.goalLaborRate != null
                        ? monthlyData.goalLaborRate < monthlyData.monthly.laborCostRate[importType]
                        : false
                    }
                  >
                    <FormatSummaryRate value={monthlyData.monthly.laborCostRate[importType]} />
                  </Coloring>
                </TopPaneData>
                <TopPaneGoalData>
                  （目標　
                  {monthlyData.goalLaborRate != null ? (
                    <FormatGoalRate value={monthlyData.goalLaborRate} />
                  ) : (
                    <div>
                      <p>-%</p>
                      <Link to={'/set_target/'} onClick={toTargetSetting}>
                        設定する
                      </Link>
                    </div>
                  )}
                  ）
                </TopPaneGoalData>
              </Panel>
              <Panel width={294}>
                <TopPaneData>
                  <TopPaneTitle>
                    月間人件費額
                    <StyledTooltip
                      viewName={'labor_cost_analysis'}
                      feature={'labor_cost_analysis'}
                      name={'monthlyshift'}
                    >
                      Airメイトで入力した人件費と、Airシフトから連携した概算人件費を足したものです。
                      <br />
                      <br />
                      Airシフト概算人件費は、時給が設定されていないスタッフがいる場合、正確な人件費を算出できません。
                      {storeData?.isShiftUse && (
                        <React.Fragment>
                          <br />
                          <br />
                          <LinkText
                            target="_blank"
                            href={hourlyWageSettingFaq}
                            onClick={() => {
                              logger(_genOpenTooltipLinkLog());
                            }}
                          >
                            Airシフトで時給を設定する
                            <StyledOpenLink />
                          </LinkText>
                        </React.Fragment>
                      )}
                    </StyledTooltip>
                  </TopPaneTitle>
                  <FormatYen value={monthlyData.monthly.totalLaborCost[importType]} />
                </TopPaneData>
                <TopPaneGoalData>
                  （目標　
                  {monthlyData.monthly.totalLaborCost.target != null ? (
                    <FormatYenSmall value={monthlyData.monthly.totalLaborCost.target} />
                  ) : (
                    '¥-'
                  )}
                  ）
                </TopPaneGoalData>
              </Panel>
              <Panel width={246}>
                <TopPaneData>
                  <TopPaneTitle>月間売上</TopPaneTitle>
                  <FormatYen value={monthlyData.monthly.salesInfo.sales} />
                </TopPaneData>
                <TopPaneGoalData>
                  （目標達成率　
                  {monthlyData.monthly.salesInfo.targetAchievementRate != null ? (
                    <FormatGoalRate value={monthlyData.monthly.salesInfo.targetAchievementRate} />
                  ) : (
                    <div>
                      <p>-%</p>
                      <Link to={'/set_target/'} onClick={toTargetSetting}>
                        設定する
                      </Link>
                    </div>
                  )}
                  ）
                </TopPaneGoalData>
              </Panel>
            </TopPane>
            <Calendar id="labor_cost_analysis_calendar">
              <DaysCell>月</DaysCell>
              <DaysCell>火</DaysCell>
              <DaysCell>水</DaysCell>
              <DaysCell>木</DaysCell>
              <DaysCell>金</DaysCell>
              <DaysCell>土</DaysCell>
              <DaysCell>日</DaysCell>
              {copyDataList.map((item, index) => {
                if (item == null) {
                  return <Cell key={`key_${index}`} />;
                }

                const active = mclDayjs(item.businessDate, formatter.mapiDate).isSameOrBefore(
                  parser.fromDateObject(batchProcessedDate)
                );
                return (
                  <Cell
                    key={item.businessDate}
                    selected={date === item.businessDate}
                    active={active}
                    onClick={() => {
                      // カレンダーポップアップが表示されている場合に押下した場合は日別詳細はロードせずにポップアップを消す
                      if (isShowPopup) {
                        this.setState({ isShowPopup: false });
                        return;
                      }
                      if (!active) {
                        return;
                      }
                      setDate(item.businessDate);
                      fetchDailyLaborCost();
                      logger(
                        genGaLog(
                          'labor_cost_analysis',
                          'labor_cost_analysis',
                          'click_calendar',
                          {},
                          {
                            businessDate: item.businessDate,
                          },
                          'click'
                        )
                      );
                    }}
                  >
                    <Day active={active}>{mclDayjs(item.businessDate, formatter.mapiDate).date()}</Day>
                    {active && (
                      <React.Fragment>
                        <CostRate
                          isRed={
                            monthlyData.goalLaborRate != null
                              ? monthlyData.goalLaborRate < item.laborCostRate[importType]
                              : false
                          }
                        >
                          <FormatRate value={item.laborCostRate[importType]} />
                        </CostRate>
                        <Sales>
                          <FormatYenSmall value={item.sales} />
                        </Sales>
                      </React.Fragment>
                    )}
                    {/* 要因があればアイコン表示 */}
                    <IconWrapper>
                      {item.isShiftReviewFlag && <TimeIcon />}
                      {item.isAttendanceManagementFlag && <HumanoidIcon />}
                      {item.isSalesImprovementMeasuresFlag && <YenIcon />}
                    </IconWrapper>
                  </Cell>
                );
              })}
              <DescriptionWrapper>
                <ShowPopUpButton>
                  <Description>上段：人件費率&emsp;下段：売上&emsp;アイコン：目標未達成の要因</Description>
                  &ensp;
                  <button
                    onClick={() => {
                      this.setState({ isShowPopup: true });
                      logger(_genShowCalendarPopupLog());
                    }}
                  >
                    <span>凡例</span>&nbsp;
                    <ArrowBlueRight />
                  </button>
                </ShowPopUpButton>
                <DescriptionText>
                  <TimeIcon />
                  &nbsp;
                  <Description>シフト計画超過</Description>&emsp;
                  <HumanoidIcon />
                  &nbsp;
                  <Description>労働時間超過</Description>&emsp;
                  <YenIcon />
                  &nbsp;
                  <Description>売上未達成</Description>&emsp;
                  <button
                    onClick={() => {
                      showFactorModal();
                      logger(_genShowCalendarDetailLinkLog());
                    }}
                  >
                    詳細&nbsp;
                    <ArrowBlueRight />
                  </button>
                </DescriptionText>
              </DescriptionWrapper>
              {isShowPopup && (
                <PopupWrapper>
                  <CalendarSamplePopup
                    onClickClose={() => {
                      this.setState({ isShowPopup: false });
                    }}
                    track={logger}
                  />
                </PopupWrapper>
              )}
            </Calendar>
            {isShowModal && (
              <AppealModal
                onClick={() => {
                  this.setState({ isShowModal: false });
                  logger(_genAppealModalClickCloseLog());
                }}
                productType={CROSSSELL_MODAL_TYPE['sft'].type}
                buttonText={CROSSSELL_MODAL_TYPE['sft'].buttonText}
                onSubmit={() => {
                  window.open(CROSSSELL_MODAL_TYPE['sft'].url);
                  logger(_genAppealModalSubmitLog());
                }}
              />
            )}
            {/* 売上入力方法モーダル */}
            {isShowSalesInputMethodModal && (
              <SalesInputMethodModal
                onClickClose={() => {
                  this.setState({ isShowSalesInputMethodModal: false });
                }}
                track={logger}
              />
            )}
          </Wrapper>
        );
      default:
        return assertNever(monthlyLaborCostState);
    }
  }
}

export default MonthlyLaborCost;

const Wrapper = styled.div`
  margin-top: 24px;
  width: 100%;
`;

const Banner = styled.div`
  width: 100%;
  border: 1px solid ${airblue};
  border-radius: 4px;
  align-items: center;
  font-size: 14px;
  display: flex;
  align-items: center;
  padding: 13px;
  margin-bottom: 23px;
  background-color: ${blueBg};
  p {
    font-weight: 400;
    color: ${black};
  }
`;

const AppealShiftBanner = styled.div<{ isShowShiftBanner: boolean }>`
  width: 100%;
  border: 1px solid ${airblue};
  border-radius: 4px;
  align-items: center;
  font-size: 14px;
  display: flex;
  align-items: center;
  padding: 0 13px;
  margin-bottom: ${props => (props.isShowShiftBanner ? 23 : 0)}px;
  background-color: ${blueBg};
  opacity: ${props => (props.isShowShiftBanner ? 1 : 0)};
  height: ${props => (props.isShowShiftBanner ? 50 : 0)}px;
  transition: 0.3s;
  p {
    font-weight: 400;
    color: ${black};
  }
`;

const BannerButton = styled.button<{ isAirBlue: boolean; fontSize: number }>`
  border: none;
  background: none;
  color: ${props => (props.isAirBlue ? airblue : textLinkColor)};
  font-weight: 600;
  font-size: ${props => props.fontSize}px;
  cursor: pointer;
`;

const CloseButton = styled.button`
  border: none;
  background: none;
  margin-left: auto;
  cursor: pointer;
`;

const TopPane = styled.div`
  display: flex;
  align-items: center;
`;

const Panel = styled.div<{ width: number }>`
  position: relative;
  width: ${props => props.width}px;
  height: 56px;
  padding: 3px 16px 0;
  :nth-child(2) {
    border-left: solid 1px ${uploadBorderColor};
    border-right: solid 1px ${uploadBorderColor};
  }
`;

const StyledTooltip = styled(Tooltip)`
  position: absolute;
  top: 4px;
  right: -20px;
  display: flex;
  justify-content: center;
`;

const LinkText = styled.a`
  text-decoration: underline;
  color: ${white};
`;

const StyledOpenLink = styled(OpenLink)`
  margin-left: 4px;
`;

const TopPaneTitle = styled.p`
  position: relative;
  margin-right: 16px;
  :not(:first-child) {
    margin-left: 44px;
  }
`;

const TopPaneData = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
`;

const TopPaneGoalData = styled.p`
  font-size: 12px;
  line-height: 1;
  position: absolute;
  right: 9px;
  bottom: 8px;
  display: flex;
  div {
    display: flex;
    p {
      margin: 0 8px 0 -7px;
    }
    a {
      color: ${textLinkColor};
    }
  }
`;

const Calendar = styled.div`
  position: relative;
  width: 776px;
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
`;

const DaysCell = styled.div`
  width: calc(776px / 7);
  font-size: 12px;
  text-align: center;
  border-bottom: solid 1px ${uploadBorderColor};
`;

const Day = styled.p<{ active: boolean }>`
  text-align: left;
  line-height: 1;
  ${props =>
    props.active &&
    `
    font-weight: 600;
    color: ${airblue};
  `}
`;

const Sales = styled.p`
  margin: -6px 0 3px;
`;

const Cell = styled(DaysCell)<{ selected?: boolean; active?: boolean }>`
  min-height: 64px;
  padding: 6px 10px 12px;
  font-size: 16px;
  border-left: solid 1px ${uploadBorderColor};
  :nth-child(7n) {
    border-right: solid 1px ${uploadBorderColor};
  }
  ${props =>
    props.active &&
    `
  ${props.selected ? 'background-color: #F0FAFF;' : ''}
  ${props.selected != null ? 'cursor: pointer;' : ''}
  `}
`;

const DescriptionWrapper = styled.div`
  margin-top: 17px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const ShowPopUpButton = styled.p`
  display: flex;
  align-items: center;
  border: none;
  background: none;
  button {
    cursor: pointer;
    border: none;
    background: none;
  }
  span {
    color: ${textLinkColor};
    font-size: 12px;
  }
`;

const DescriptionText = styled.div`
  display: flex;
  align-items: center;
  button {
    background: none;
    border: none;
    color: ${textLinkColor};
    cursor: pointer;
    font-size: 12px;
    font-weight: 400;
  }
`;

const Description = styled.p`
  font-size: 12px;
  color: ${black};
`;

const PopupWrapper = styled.div`
  position: absolute;
  left: 16px;
  bottom: 34px;
`;

const Coloring = styled.p<{ isRed: boolean }>`
  ${props => props.isRed && `color: ${red};`};
`;

const CostRate = styled(Coloring)`
  line-height: 25px;
  margin-top: -10px;
`;

const IconWrapper = styled.div`
  display: flex;
  width: 58px;
  margin: 0 auto;
  justify-content: space-evenly;
`;

const ErrorText = styled.div`
  text-align: center;
  font-size: 16px;
  margin: 24px 0;
`;

const _genAppealModalSubmitLog = () => {
  return genGaLog(
    'labor_cost_analysis',
    `labor_cost_analysis_link_${CROSSSELL_PRODUCT_TYPE.sft}_modal`,
    'submit',
    {},
    { type: [CROSSSELL_PRODUCT_TYPE.sft] },
    'click'
  );
};

const _genOpenTooltipLinkLog = () => {
  return genGaLog(
    'labor_cost_analysis',
    'labor_cost_analysis_tooltip_link',
    'open_sft',
    {},
    { type: [CROSSSELL_PRODUCT_TYPE.sft] },
    'click'
  );
};

const _genShowCalendarPopupLog = () => {
  return genGaLog('labor_cost_analysis', 'labor_cost_analysis_link', 'open', {}, {}, 'click');
};

const _genShowCalendarDetailLinkLog = () => {
  return genGaLog('labor_cost_analysis', 'labor_cost_analysis_calendar_detail_link', 'open', {}, {}, 'click');
};

const _genAppealModalClickCloseLog = () => {
  return genGaLog(
    'labor_cost_analysis',
    `labor_cost_analysis_link_${CROSSSELL_PRODUCT_TYPE.sft}_modal`,
    'close',
    {},
    { type: [CROSSSELL_PRODUCT_TYPE.sft] },
    'click'
  );
};

const _genShowSalesInputMethodModalLog = () => {
  return genGaLog('labor_cost_analysis', 'labor_cost_analysis_link', 'open', {}, {}, 'click');
};

const _genOpenShiftCrossUseBannerLinkLog = () => {
  return genGaLog(
    'labor_cost_analysis',
    'labor_cost_analysis_link_crossuse_sft_panel',
    'open',
    {},
    { type: [CROSSSELL_PRODUCT_TYPE.sft] },
    'click'
  );
};

const _genShiftCrossUseBannerCloseLog = () => {
  return genGaLog(
    'labor_cost_analysis',
    'labor_cost_analysis_link_crossuse_sft_panel',
    'close',
    {},
    {},
    'click'
  );
};
