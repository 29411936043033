import * as React from 'react';
import SelectBox from '../../../../components/common/atoms/SelectBox';
import { mclDayjs } from '../../../../helpers/mclDate';
import { buildPeriodStringArray } from '../../../../helpers/mclDateHelper';
type Props = {
  readonly onChange: (a: string) => void;
  readonly placeholder?: string;
  readonly isSelected?: boolean;
};

const Period = (props: Props) => {
  const now = mclDayjs();
  const months = 25; // 2年分 + 当月

  const periodStringArray = buildPeriodStringArray(now, months);

  const { onChange, placeholder, isSelected } = props;
  return (
    <React.Fragment>
      <SelectBox.normal
        options={periodStringArray.map(periodString => ({ key: periodString, value: periodString }))}
        onChange={e => onChange(e.value)}
        size="small"
        balloonSize="small"
        placeholder={
          placeholder != null
            ? { key: placeholder, value: placeholder }
            : { key: periodStringArray[0], value: periodStringArray[0] }
        }
        isSelected={isSelected != null ? isSelected : false}
        testId={'span_select_box'}
        id="data_output_date_select"
      />
    </React.Fragment>
  );
};

export default Period;
