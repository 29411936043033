/**
 * 日別詳細のCSVダウンロードモーダル
 */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Action, Dispatch } from 'redux';
import styled from 'styled-components';
import Step from '../../../../../components/common/atoms/Step';
import Small from '../../../../../components/common/atoms/Text/Small';
import SelectBox from '../../../../../components/common/atoms/SelectBox/normal';
import AirModal from '../../../../../components/common/molecules/Airkit/AirModal';
import AirCheckbox from '../../../../../components/common/molecules/Airkit/AirCheckbox';
import ActivityIndicator from '../../../../../components/common/ActivityIndicatorStatic';
import { airblue, airblueHover, lightgray, attentionRed } from '../../../../../constants/colors';
import { BatchProcessedDate } from '../../../../../typedef/BatchProcessedDate';
import { StoreList } from '../../../../../typedef/api/AllIndex';
import { State as ReduxState } from '../../../../../modules';
import { StoresData } from '../../../../../modules/user';
import { track } from '../../../../../modules/logging';
import { actions as commonUiActions } from '../../../../../modules/uiConfig';
import { actions } from '../../../../../modules/storeIndices/downloadStoreIndices';
import { genGaLog } from '../../../../../gaLogger';
import { LocalYearMonthObj, formatter, mclDayjs, parser } from '../../../../../helpers/mclDate';
import { displayYearMonthList } from '../../../../../helpers/mclDateHelper';

type ThroughProps = {
  readonly stores: ReadonlyArray<StoresData & StoreList>;
  readonly batchProcessedDate: BatchProcessedDate;
  readonly closeModal: () => void;
  readonly tracker: typeof track;
  readonly defaultDate?: string | null;
  readonly defaultStore?: StoresData;
};

type StateProps = {
  readonly akrCodes: ReadonlyArray<string>;
  readonly dateFrom?: LocalYearMonthObj | null;
  readonly dateTo?: LocalYearMonthObj | null;
  readonly errorText?: string;
  readonly downloading: boolean;
  readonly isOverflowError: boolean;
};

type DispatchProps = {
  readonly changeSelectedAkrCodes: typeof actions.changeSelectedAkrCodes;
  readonly changeDateFrom: typeof actions.changeDateFrom;
  readonly changeDateTo: typeof actions.changeDateTo;
  readonly startDownloadStoreIndices: typeof actions.startDownloadStoreIndices;
  readonly resetState: typeof actions.resetState;
  readonly showCommonDialog: typeof commonUiActions.showCommonDialog;
  readonly hideCommonDialog: typeof commonUiActions.hideCommonDialog;
};

type Props = ThroughProps & StateProps & DispatchProps;

class DailyDetailDownloadModal extends PureComponent<Props> {
  componentDidMount() {
    const { defaultDate, defaultStore } = this.props;
    const { changeSelectedAkrCodes, changeDateFrom, changeDateTo } = this.props;
    const date = mclDayjs(defaultDate, formatter.mapiYearMonth);
    if (defaultDate != null && date.isValid()) {
      changeDateFrom(date.toLocalDateObj());
      changeDateTo(date.toLocalDateObj());
    }
    if (defaultStore != null) {
      changeSelectedAkrCodes([defaultStore.akrCode]);
    }
  }

  componentWillUnmount() {
    this.props.resetState();
  }

  createDateOptions = (): string[] => {
    const { batchProcessedDate } = this.props;
    const objectArray = displayYearMonthList(batchProcessedDate);
    const yearOptions = Array.from(
      new Set(
        Array.from(objectArray)
          .map(yearMonth =>
            parser.fromYearMonthObject(yearMonth).format(formatter.mapiDefaultYearMonthNotFixed)
          )
          .reverse()
      ).values()
    );
    return yearOptions;
  };

  /*
   * エラーメッセージがあるか否か
   * ある場合はエラーメッセージを返す
   */
  existErrorMessage = (): React.ReactElement | undefined => {
    const { dateFrom, dateTo, batchProcessedDate } = this.props;
    const batchYearMonth = parser.fromDateObject(batchProcessedDate);
    // 前後が入れ変わっていないか
    const isDateFromDateToReversed = !(
      dateFrom != null ? parser.fromYearMonthObject(dateFrom) : batchYearMonth
    ).isSameOrBefore(dateTo != null ? parser.fromYearMonthObject(dateTo) : batchYearMonth, 'month');
    return isDateFromDateToReversed ? (
      <ErrorText>終了月は、開始月以降の月を選択してください。</ErrorText>
    ) : undefined;
  };

  _handleAllSelect = () => {
    const { stores, changeSelectedAkrCodes } = this.props;
    changeSelectedAkrCodes(
      stores.map(store => {
        return store.akrCode;
      })
    );
    this.props.tracker(_genClickAllSelectStore());
  };

  _handleAllUnSelect = () => {
    this.props.changeSelectedAkrCodes([]);
    this.props.tracker(_genClickAllDeselectStore());
  };

  _handleClickStoreList = (akrCode: string) => {
    const { akrCodes, changeSelectedAkrCodes } = this.props;
    if (akrCodes.includes(akrCode)) {
      changeSelectedAkrCodes(akrCodes.filter(akr => akr !== akrCode));
    } else {
      changeSelectedAkrCodes(akrCodes.concat([akrCode]));
    }
    this.props.tracker(_genChangeStoreList());
  };

  showErrorDialog = () => {
    const { showCommonDialog, hideCommonDialog, errorText } = this.props;
    showCommonDialog({
      title: 'ダウンロードに失敗しました',
      message:
        errorText != null
          ? errorText
          : `一度にダウンロードできるデータは最大3285行です。

      ・1店舗なら9年分（365日×9年=3285行）
      ・1ヶ月分なら105店舗（31日×105店舗=3255行)
      まで同時にダウンロードできます。
      `,
      actions: [
        {
          text: 'OK',
          onClick: () => {
            hideCommonDialog();
          },
          primary: true,
        },
      ],
    });
  };

  componentDidUpdate(prevProps: Props) {
    // 通信終了時
    if (
      prevProps.downloading &&
      !this.props.downloading &&
      (this.props.isOverflowError || this.props.errorText != null)
    ) {
      this.showErrorDialog();
    }
  }

  render() {
    const { stores, closeModal, akrCodes, dateFrom, dateTo, downloading } = this.props;
    const { changeDateFrom, changeDateTo, startDownloadStoreIndices } = this.props;
    const dateOptions = this.createDateOptions();
    const errorMessage = this.existErrorMessage();
    return (
      <AirModal
        appearance="large"
        title="CSVダウンロード"
        onClose={closeModal}
        onSubmit={() => {
          startDownloadStoreIndices();
          this.props.tracker(_genClickCsvDownloadButton(akrCodes.length, dateFrom, dateTo));
        }}
        buttonText="ダウンロードする"
        disabled={errorMessage != null || akrCodes.length === 0}
      >
        {downloading && <ActivityIndicator downloading={downloading} isBgWhite={true} />}
        <Wrapper>
          <Small>
            日別成績画面に表示されている項目・並び順をそのままCSV形式で出力します。
            <br />
            ※項目の設定は、成績画面右上の「設定」＞「表の項目を編集」から行ってください。
          </Small>
          <ContentWrapper>
            <StepWrapper width="60">
              <Step title="出力する店舗">
                <React.Fragment>
                  <AllSelectItemArea>
                    <AllSelectItem onClick={this._handleAllSelect}>すべて選択</AllSelectItem>
                    <AllSelectItem onClick={this._handleAllUnSelect}>すべて解除</AllSelectItem>
                  </AllSelectItemArea>
                  <ListWrapper>
                    {stores
                      .map(store => {
                        return [store.akrCode, store.storeName];
                      })
                      .map(store => (
                        <ListItem
                          key={store[0]}
                          isSelected={akrCodes.includes(store[0])}
                          onClick={() => this._handleClickStoreList(store[0])}
                        >
                          <AirCheckbox
                            isChecked={akrCodes.includes(store[0])}
                            onClick={e => e.stopPropagation()}
                            label={store[1]}
                          />
                        </ListItem>
                      ))}
                  </ListWrapper>
                </React.Fragment>
              </Step>
            </StepWrapper>
            <StepWrapper width="40">
              <Step title="期間">
                <React.Fragment>
                  <SelectDateWrapper>
                    {dateFrom != null && (
                      <SelectBoxWrapper>
                        <SelectBox
                          balloonSize="small"
                          size="auto"
                          height={200}
                          options={dateOptions.map((option, idx) => ({ key: idx.toString(), value: option }))}
                          placeholder={{
                            key: parser
                              .fromYearMonthObject(dateFrom)
                              .format(formatter.mapiDefaultYearMonthNotFixed),
                            value: parser
                              .fromYearMonthObject(dateFrom)
                              .format(formatter.mapiDefaultYearMonthNotFixed),
                          }}
                          onChange={date => {
                            const mclDayjsDate = mclDayjs(date.value, formatter.mapiDefaultYearMonthNotFixed);
                            changeDateFrom(
                              mclDayjsDate.isValid() ? mclDayjsDate.toLocalYearMonthObj() : undefined
                            );
                            this.props.tracker(_genChangeDateFrom());
                          }}
                        />
                      </SelectBoxWrapper>
                    )}

                    <ToWrapper>
                      <To>〜</To>
                    </ToWrapper>
                    {dateTo != null && (
                      <SelectBoxWrapper>
                        <SelectBox
                          balloonSize="small"
                          size="auto"
                          height={200}
                          options={dateOptions.map((option, idx) => ({ key: idx.toString(), value: option }))}
                          placeholder={{
                            key: parser
                              .fromYearMonthObject(dateTo)
                              .format(formatter.mapiDefaultYearMonthNotFixed),
                            value: parser
                              .fromYearMonthObject(dateTo)
                              .format(formatter.mapiDefaultYearMonthNotFixed),
                          }}
                          onChange={date => {
                            const mclDayjsDate = mclDayjs(date.value, formatter.mapiDefaultYearMonthNotFixed);
                            changeDateTo(
                              mclDayjsDate.isValid() ? mclDayjsDate.toLocalYearMonthObj() : undefined
                            );
                            this.props.tracker(_genChangeDateTo());
                          }}
                        />
                      </SelectBoxWrapper>
                    )}
                  </SelectDateWrapper>
                  {errorMessage != null && errorMessage}
                </React.Fragment>
              </Step>
            </StepWrapper>
          </ContentWrapper>
        </Wrapper>
      </AirModal>
    );
  }
}

const mapStateToProps = (state: ReduxState): StateProps => {
  return {
    akrCodes: state.storeIndices.download.akrCodes,
    dateFrom: state.storeIndices.download.dateFrom,
    dateTo: state.storeIndices.download.dateTo,
    errorText: state.storeIndices.download.errorText,
    downloading: state.storeIndices.download.downloading,
    isOverflowError: state.storeIndices.download.isOverflowError,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => ({
  ...bindActionCreators(
    {
      changeSelectedAkrCodes: actions.changeSelectedAkrCodes,
      changeDateFrom: actions.changeDateFrom,
      changeDateTo: actions.changeDateTo,
      startDownloadStoreIndices: actions.startDownloadStoreIndices,
      resetState: actions.resetState,
      showCommonDialog: commonUiActions.showCommonDialog,
      hideCommonDialog: commonUiActions.hideCommonDialog,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(DailyDetailDownloadModal);

const _genChangeStoreList = () => {
  return genGaLog('store_indices_csv_download_modal', 'store_list', 'change_store_list', {}, {}, 'click');
};

const _genClickAllSelectStore = () => {
  return genGaLog(
    'store_indices_csv_download_modal',
    'store_list',
    'click_all_select_store',
    {},
    {},
    'click'
  );
};

const _genClickAllDeselectStore = () => {
  return genGaLog(
    'store_indices_csv_download_modal',
    'store_list',
    'click_all_deselect_store',
    {},
    {},
    'click'
  );
};

const _genChangeDateFrom = () => {
  return genGaLog(
    'store_indices_csv_download_modal',
    'period_select_box',
    'change_start_period',
    {},
    {},
    'click'
  );
};

const _genChangeDateTo = () => {
  return genGaLog(
    'store_indices_csv_download_modal',
    'period_select_box',
    'change_end_period',
    {},
    {},
    'click'
  );
};

const _genClickCsvDownloadButton = (
  storeNum: number,
  dateFrom?: LocalYearMonthObj | null,
  dateTo?: LocalYearMonthObj | null
) => {
  const dateFromDayjs = dateFrom != null ? parser.fromYearMonthObject(dateFrom) : null;
  const dateToDayjs = dateTo != null ? parser.fromYearMonthObject(dateTo) : null;
  return genGaLog(
    'store_indices_csv_download_modal',
    'download_button',
    'click_csv_download_button',
    {},
    {
      date_from:
        dateFromDayjs != null && dateFromDayjs.isValid()
          ? dateFromDayjs.format(formatter.mapiDefaultYearMonthNotFixed)
          : undefined,
      date_to:
        dateToDayjs != null && dateToDayjs.isValid()
          ? dateToDayjs.format(formatter.mapiDefaultYearMonthNotFixed)
          : undefined,
      store_num: storeNum,
    },
    'click'
  );
};

const Wrapper = styled.div`
  padding: 20px 24px;
  min-height: 600px;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-around;
`;

const StepWrapper = styled.div<{ width: string }>`
  width: calc(${props => props.width}% - 20px);
`;

const SelectDateWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const ToWrapper = styled.div`
  margin: 14px 0;
`;

const To = styled.div`
  margin: 0 12px;
  width: 16px;
`;

const AllSelectItem = styled.p`
  color: ${airblue};
  cursor: pointer;
  display: inline-block;
  :not(:first-child) {
    margin-left: 10px;
  }
`;

const ListWrapper = styled.div`
  border: solid 1px ${lightgray};
  max-height: 400px;
  overflow-y: scroll;
`;

const ListItem = styled.div<{ isSelected?: boolean }>`
  width: 100%;
  min-height: 40px;
  cursor: pointer;
  border: solid 1px ${lightgray};
  ${props => props.isSelected && `background-color: ${airblueHover}`};
  &:hover {
    background-color: ${airblueHover};
  }
  font-size: 14px;
  word-break: break-all;
  padding: 12px;
  display: flex;
  align-items: center;
`;

const AllSelectItemArea = styled(ListItem)`
  min-height: 28px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 0 12px;
  font-size: 10px;
  border: none;
  cursor: default;
  background-color: initial;
  &:hover {
    background-color: initial;
  }
`;

const SelectBoxWrapper = styled.div`
  width: calc((100% - 40px) / 2);
`;

const ErrorText = styled.p`
  color: ${attentionRed};
  margin-top: 4px;
`;
