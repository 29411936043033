import * as React from 'react';
import styled from 'styled-components';
import { Checkbox } from '@air-kit/air-kit';
import BalloonModal from '../../../components/common/BalloonModal';
import { eqn } from '../../../helpers/util';
import { airblue } from '../../../constants/colors';

type Props = {
  options: Map<string, string>;
  hiddenOptions: Map<string, string>;
  selectedDisplayItems: Set<string>;
  selectedHiddenItems: Set<string>;
  includesUndefinedCategory: boolean;
  onDisplayChange: (categories: Set<string>, undefinedCategory: boolean) => void;
  onHiddenChange: (categories: Set<string>, undefinedCategory: boolean) => void;
  onUndefinedCategoryChange: (a: boolean) => void;
  onClick?: () => void;
};

type State = {
  disabled: boolean;
  required: boolean;
  placeholderText: string | null;
};

class SelectCategory extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      disabled: false,
      required: false,
      placeholderText: null,
    };
  }

  componentDidMount(): void {
    const { options, hiddenOptions, selectedDisplayItems, selectedHiddenItems, includesUndefinedCategory } =
      this.props;
    this.setState({
      placeholderText: this._setPlaceHolderText(
        new Map([...options, ...hiddenOptions]),
        new Set([...selectedDisplayItems, ...selectedHiddenItems]),
        includesUndefinedCategory
      ),
    });
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    const { options, hiddenOptions, selectedDisplayItems, selectedHiddenItems, includesUndefinedCategory } =
      this.props;
    // カテゴリーが変更されたタイミングでカテゴリーのプレースホルダーテキストを変更
    if (
      prevProps.options !== options ||
      prevProps.selectedDisplayItems !== selectedDisplayItems ||
      prevProps.includesUndefinedCategory !== includesUndefinedCategory
    ) {
      this.setState({
        placeholderText: this._setPlaceHolderText(
          new Map([...options, ...hiddenOptions]),
          new Set([...selectedDisplayItems, ...selectedHiddenItems]),
          includesUndefinedCategory
        ),
      });
    }
  }
  _handleClickSelector = (
    e: React.SyntheticEvent<HTMLElement>,
    selectedItem: string,
    selectedItems: Set<string>,
    onChange: (a: Set<string>, b: boolean) => void
  ) => {
    e.stopPropagation(); // select boxを押して閉じるときoutsideclickと衝突するため

    if (selectedItems.has(selectedItem)) {
      const tempSet = new Set(selectedItems);
      if (tempSet.delete(selectedItem)) onChange(tempSet, this.props.includesUndefinedCategory);
    } else {
      const tempSet = new Set(selectedItems);
      if (tempSet.add(selectedItem)) onChange(tempSet, this.props.includesUndefinedCategory);
    }
  };

  _handleAllSelect = (
    e: React.SyntheticEvent<HTMLElement>,
    onChange: (a: Set<string>, b: boolean) => void,
    options: Map<string, string>,
    withUndefinedCategory?: boolean
  ) => {
    e.stopPropagation(); // select boxを押して閉じるときoutsideclickと衝突するため

    onChange(new Set(options.keys()), withUndefinedCategory ? true : this.props.includesUndefinedCategory);
  };

  _handleAllUnSelect = (
    e: React.SyntheticEvent<HTMLElement>,
    onChange: (a: Set<string>, b: boolean) => void,
    withUndefinedCategory?: boolean
  ) => {
    e.stopPropagation(); // select boxを押して閉じるときoutsideclickと衝突するため

    onChange(new Set(), withUndefinedCategory ? false : this.props.includesUndefinedCategory);
  };

  _setPlaceHolderText = (
    options: Map<string, string>,
    selectedItems: Set<string>,
    includesUndefinedCategory: boolean
  ) => {
    if (eqn(options.size, 0) && !includesUndefinedCategory) {
      this.setState({
        disabled: true,
        required: false,
      });
      return 'カテゴリーがありません';
    }

    if ((selectedItems && selectedItems.size > 0) || includesUndefinedCategory) {
      this.setState({
        disabled: false,
        required: false,
      });

      if (selectedItems.size === options.size && includesUndefinedCategory) {
        return 'すべて';
      } else {
        const ary = Array.from(selectedItems).map(key => options.get(key));
        includesUndefinedCategory && ary.push('カテゴリー未設定');
        return ary.join('、');
      }
    } else {
      this.setState({
        disabled: false,
        required: true,
      });
      return '選択してください';
    }
  };

  render() {
    const {
      options,
      hiddenOptions,
      selectedDisplayItems,
      selectedHiddenItems,
      includesUndefinedCategory,
      onDisplayChange,
      onHiddenChange,
      onUndefinedCategoryChange,
      onClick,
    } = this.props;
    const { placeholderText, disabled, required } = this.state;
    return (
      placeholderText != null && (
        <BalloonModal
          placeholderText={placeholderText}
          disabled={disabled}
          required={required}
          onClick={onClick}
        >
          <Container>
            <AllSelectItemArea>
              <AllSelectItem onClick={e => this._handleAllSelect(e, onDisplayChange, options, true)}>
                すべて選択
              </AllSelectItem>
              <AllSelectItem onClick={e => this._handleAllUnSelect(e, onDisplayChange, true)}>
                すべて解除
              </AllSelectItem>
            </AllSelectItemArea>
            <ListWrapper>
              {Array.from(options).map(option => (
                <React.Fragment key={option[0]}>
                  <ListItem
                    onClick={e =>
                      this._handleClickSelector(e, option[0], selectedDisplayItems, onDisplayChange)
                    }
                  >
                    <Checkbox
                      isChecked={selectedDisplayItems.has(option[0])}
                      onClick={e => e.stopPropagation()}
                    />
                    {option[1]}
                  </ListItem>
                </React.Fragment>
              ))}
            </ListWrapper>
            <ListItem
              onClick={e => {
                e.stopPropagation();
                onUndefinedCategoryChange(!includesUndefinedCategory);
              }}
            >
              <Checkbox isChecked={includesUndefinedCategory} onClick={e => e.stopPropagation()} />
              カテゴリー未設定
            </ListItem>

            <HiddenCategory>
              <HiddenCategoryTitle>非表示のカテゴリー</HiddenCategoryTitle>
              <AllSelectItemArea>
                <AllSelectItem onClick={e => this._handleAllSelect(e, onHiddenChange, hiddenOptions)}>
                  すべて選択
                </AllSelectItem>
                <AllSelectItem onClick={e => this._handleAllUnSelect(e, onHiddenChange)}>
                  すべて解除
                </AllSelectItem>
              </AllSelectItemArea>
            </HiddenCategory>
            <ListWrapper>
              {Array.from(hiddenOptions).map(option => (
                <React.Fragment key={option[0]}>
                  <ListItem
                    onClick={e =>
                      this._handleClickSelector(e, option[0], selectedHiddenItems, onHiddenChange)
                    }
                  >
                    <Checkbox
                      isChecked={selectedHiddenItems.has(option[0])}
                      onClick={e => e.stopPropagation()}
                    />
                    {option[1]}
                  </ListItem>
                </React.Fragment>
              ))}
            </ListWrapper>
          </Container>
        </BalloonModal>
      )
    );
  }
}

const Container = styled.div`
  padding: 24px;
  max-height: 370px;
  overflow: auto;
`;

const ListWrapper = styled.div`
  display: grid;
  grid-template-columns: 136px 136px 136px;
`;

const ListItem = styled.div`
  min-height: 40px;
  cursor: pointer;
  &:hover {
    background-color: #e1f0f8;
  }
  font-size: 14px;
  word-break: break-all;
  padding: 12px;
  display: flex;
  align-items: center;
`;

const AllSelectItemArea = styled(ListItem)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 10px;
  cursor: default;
  background-color: initial;
  &:hover {
    background-color: initial;
  }
`;

const HiddenCategory = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const HiddenCategoryTitle = styled.p`
  font-size: 14px;
  display: flex;
  align-items: center;
`;

const AllSelectItem = styled.p`
  color: ${airblue};
  cursor: pointer;
  display: inline-block;
  :not(:first-child) {
    margin-left: 10px;
  }
`;

export default SelectCategory;
