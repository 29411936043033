import React from 'react';
import { Waypoint } from 'react-waypoint';
import styled from 'styled-components';
import CheckIcon from '../../../../icons/checkBlack.svg';
import AirCard from '../../../../icons/airCardProductPanel.png';
import AirCardLogo from '../../../../icons/airCardLogo.svg';
import AirShift from '../../../../icons/airShiftProductPanel.png';
import AirShiftLogo from '../../../../icons/airShiftLogo.svg';
import Oes from '../../../../icons/oesProductPanel.png';
import OesLogo from '../../../../icons/oesLogo.svg';
import Rb from '../../../../icons/rbProductPanel.png';
import RbLogo from '../../../../icons/rbLogo.svg';
import AirRegiLogo from '../../../../icons/airRegiLogo.svg';
import AirRegi from '../../../../icons/airRegiProductPanel.png';
import AirCash from '../../../../icons/AirCashSample.png';
import AirCashLogo from '../../../../icons/AirCashLogo.svg';
import Mpa from '../../../../icons/MpaSample.png';
import MpaLogo from '../../../../icons/MpaLogo.svg';
import AirPay from '../../../../icons/PaySample.png';
import AirPayLogo from '../../../../icons/airPayLogo.svg';
import Invoice from '../../../../icons/InvoiceSample.png';
import InvoiceLogo from '../../../../icons/AirInvoiceLogo.svg';

import {
  airBoxShadow,
  airBoxShadowGray,
  airblue,
  black,
  buttonHoverColor,
  uploadBorderColor,
  verylightgray,
  white,
} from '../../../../constants/colors';
import { PRODUCT_ITEM, getLogParam } from '../ProductListConstants';
import { History } from 'history';
import { StoresData } from '../../../../modules/user';
import { genGaLog } from '../../../../gaLogger';
import { track } from '../../../../modules/logging';

type Props = {
  productName: keyof typeof PRODUCT_ITEM;
  openModal: () => void;
  history: History;
  stores: ReadonlyArray<StoresData>;
  readonly track: typeof track;
};

const NotLinkedPanel: React.FC<Props> = props => {
  const { productName, openModal, history, stores, track } = props;
  const isAirCard = productName === 'card';
  // Airカード利用判定
  const isUseCard = isAirCard && stores.some(store => store.isAirCardUse);

  return (
    <Waypoint
      onEnter={() => {
        track(_genNotLinkedPanelLog(getLogParam(productName), 'impression', 'impression'));
      }}
    >
      <PanelWrapper isAirCard={isAirCard}>
        {isAirCard && <RecommendWrapper>おすすめ</RecommendWrapper>}
        <LabelList>
          {productName !== 'cash' && <LabelWrapper>Airメイトデータ連携</LabelWrapper>}
          <LabelWrapper>
            {productName === 'oes' || productName === 'rb' ? '飲食店向け' : '全業種向け'}
          </LabelWrapper>
        </LabelList>
        <ContentWrapper>
          <CheckList>
            {PRODUCT_ITEM[productName].checkText.map(text => {
              return (
                <CheckWrapper key={text}>
                  <CheckIcon />
                  <CheckText>{text}</CheckText>
                </CheckWrapper>
              );
            })}
          </CheckList>
          <ImageWrapper productName={productName}>
            <StyledImg src={productImg(productName)} alt={productName} />
          </ImageWrapper>
          {PRODUCT_ITEM[productName].leadText.map(text => {
            return <Lead key={text}>{text}</Lead>;
          })}
          <LogoWrapper productName={productName}>{productLogo(productName)}</LogoWrapper>
          <DetailButton
            isUseCard={isUseCard}
            type="button"
            onClick={() => {
              // カード未連携かつ利用中の場合はカード連携設定画面へ遷移
              if (isAirCard && isUseCard) {
                history.push('/card_setting/');
              } else {
                openModal();
              }
              track(_genNotLinkedPanelLog(getLogParam(productName), 'open', 'click'));
            }}
          >
            <ButtonText>{isUseCard ? '連携設定をする' : PRODUCT_ITEM[productName].buttonText}</ButtonText>
          </DetailButton>
        </ContentWrapper>
      </PanelWrapper>
    </Waypoint>
  );
};

const productImg = (productName: keyof typeof PRODUCT_ITEM) => {
  switch (productName) {
    case 'card':
      return AirCard;
    case 'shift':
      return AirShift;
    case 'oes':
      return Oes;
    case 'rb':
      return Rb;
    case 'regi':
      return AirRegi;
    case 'cash':
      return AirCash;
    case 'pay':
      return AirPay;
    case 'mpa':
      return Mpa;
    case 'invoice':
      return Invoice;
    default:
      return null;
  }
};

const productLogo = (productName: keyof typeof PRODUCT_ITEM) => {
  switch (productName) {
    case 'card':
      return <AirCardLogo />;
    case 'shift':
      return <AirShiftLogo />;
    case 'oes':
      return <OesLogo />;
    case 'rb':
      return <RbLogo />;
    case 'regi':
      return <AirRegiLogo />;
    case 'cash':
      return <AirCashLogo />;
    case 'pay':
      return <AirPayLogo />;
    case 'mpa':
      return <MpaLogo />;
    case 'invoice':
      return <InvoiceLogo />;
    default:
      return null;
  }
};

export default NotLinkedPanel;

const _genNotLinkedPanelLog = (productName: string, func: string, event: string) => {
  return genGaLog(
    'product_idea_list',
    'not_linked_area_crossuse_panel',
    func,
    {},
    { type: [productName] },
    event
  );
};

const PanelWrapper = styled.div<{ isAirCard: boolean }>`
  width: 380px;
  height: 381px;
  border-radius: 8px;
  padding: 16px;
  border: ${props => (props.isAirCard ? `2px solid ${airblue}` : `1px solid ${uploadBorderColor}`)};
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: ${white};
  margin: 16px 16px 0 0;
`;

const RecommendWrapper = styled.div`
  position: absolute;
  width: 64px;
  height: 28px;
  background: ${airblue};
  border-radius: 4px 4px 0px 0px;
  font-size: 12px;
  font-weight: 600;
  color: ${white};
  text-align: center;
  padding-top: 2px;
  top: -23px;
  left: -2px;
  z-index: -1;
`;

const LabelList = styled.div`
  display: flex;
  margin-bottom: 12px;
`;

const LabelWrapper = styled.div`
  display: inline;
  border: 1px solid ${uploadBorderColor};
  border-radius: 2px;
  font-size: 10px;
  padding: 2px 4px 1px;
  color: ${black};
  margin-right: 8px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const CheckList = styled.div`
  display: flex;
  flex-direction: column;
`;

const CheckText = styled.div`
  font-weight: 600;
  font-size: 16px;
  margin-left: 8px;
  font-family: 'Hiragino Kaku Gothic Pro', sans-serif;
`;

const CheckWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`;

const ImageWrapper = styled.div<{ productName: keyof typeof PRODUCT_ITEM }>`
  margin: ${props => (props.productName === 'mpa' ? '24px 0 -6px' : '4px 0 14px')};
  height: 112px;
  width: ${props => {
    switch (props.productName) {
      case 'card':
        return '180px';
      case 'shift':
        return '200px';
      case 'oes':
        return '138px';
      case 'rb':
        return '162px';
      case 'regi':
        return '172px';
      case 'pay':
        return '181px';
      case 'cash':
        return '182px';
      case 'mpa':
        return '169px';
      case 'invoice':
        return '125px';
    }
  }};
`;

const Lead = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${airblue};
  font-family: 'Hiragino Kaku Gothic Pro', sans-serif;
`;

const LogoWrapper = styled.div<{ productName: keyof typeof PRODUCT_ITEM }>`
  margin: ${props => {
    switch (props.productName) {
      case 'card':
        return '4px 0 12px';
      case 'shift':
        return '4px 0 12px';
      case 'oes':
        return '4px 0 12px';
      case 'rb':
        return '4px 0 12px';
      case 'regi':
        return '4px 0 12px';
      case 'pay':
        return '4px 0 12px';
      case 'cash':
        return '4px 0 12px';
      case 'mpa':
        return '4px 0 12px';
      case 'invoice':
        return '4px 0 12px';
    }
  }};
  height: 28px;
  width: ${props => {
    switch (props.productName) {
      case 'card':
        return '148px';
      case 'shift':
        return '152px';
      case 'oes':
        return '215px';
      case 'rb':
        return '87px';
      case 'regi':
        return '148px';
      case 'pay':
        return '132px';
      case 'cash':
        return '146px';
      case 'pay':
        return '122px';
      case 'mpa':
        return '165px';
      case 'invoice':
        return '179px';
    }
  }};
`;

const DetailButton = styled.button<{ isUseCard: boolean }>`
  border: ${props => (props.isUseCard ? `1px solid ${airBoxShadowGray}` : 'none')};
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  font-size: 14px;
  height: 40px;
  width: 208px;
  margin: auto 0;
  padding: 13px 15px;
  white-space: nowrap;
  background: ${props => (props.isUseCard ? `${verylightgray}` : `${airblue}`)};
  box-shadow: ${props =>
    props.isUseCard ? `inset 0px -2px 0px ${airBoxShadowGray}` : `inset 0 -2px 0 0 ${airBoxShadow}`};
  color: ${props => (props.isUseCard ? `${black}` : `${white}`)};
  cursor: pointer;
  ${props =>
    !props.isUseCard &&
    `&:hover {
    background-color: ${buttonHoverColor};
  }`};
`;

const StyledImg = styled.img`
  width: 100%;
  height: 100%;
`;

const ButtonText = styled.span`
  text-align: center;
  width: 100%;
`;
