import * as React from 'react';
import styled from 'styled-components';
import Text from '../../../../../components/common/atoms/Text';
import { TextArea } from '@air-kit/air-kit';
import { bgGray, uploadBorderColor } from '../../../../../constants/colors';
import { Field } from 'formik';
type Props = {
  className?: string;
  noteTemplate: string;
  handleChange: (a: {
    target: {
      value: string;
    };
  }) => void;
  startEdit: (a: void) => void;
};

const FreeCommentSettingForm = (props: Props) => {
  const { noteTemplate, className, handleChange, startEdit } = props;
  return (
    <Wrapper className={className} id="customize_daily_report_lookback_comment_format">
      <TitleRow>
        <Text.TableHeader>営業振り返りコメントフォーマット</Text.TableHeader>
      </TitleRow>
      <TextRow>
        <Text.ExSmall>店舗に記入してほしい振り返りコメントのフォーマットを設定できます。</Text.ExSmall>
      </TextRow>
      <Field name="daily_report_format">
        {() => (
          // @ts-ignore toolTipPlacementなしだと型エラー
          <TextArea
            name="noteTemplate"
            placeholder="日報を入力"
            value={noteTemplate}
            onChange={e => {
              e.target.value = e.target.value.slice(0, 750);
              handleChange(e);
              startEdit();
            }}
          />
        )}
      </Field>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  padding: 24px;
  border-top: 1px solid ${uploadBorderColor};
  background: ${bgGray};
`;
const TitleRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;
const TextRow = styled.div`
  margin-bottom: 16px;
`;
export default FreeCommentSettingForm;
