/**
 * 画面名：全店舗一覧
 */
import * as React from 'react';
import { bindActionCreators, Dispatch, Action } from 'redux';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Logger } from '../../../typedef/logger';

import SelectBox from '../../../components/common/atoms/SelectBox/index';
import Table from '../../../components/common/atoms/Table/index';
import EditItemButton from '../../../components/common/atoms/EditItemButton';
import RangeCalendar from '../../../components/common/molecules/Calendar/RangeCalendar';
import { Row } from '../../../components/common/Grid';
import FaqLink from '../../../components/common/FaqLink';
import { actions as allIndexActions } from '../../../modules/allIndex';
import {
  selectStore,
  changeSelectStore,
  resetSelectedStoreFlag,
  startFetchExsistOfSalesDailyAutoSettingChange,
  startPostReadDailySalesAutoSettingChange,
  resetPostReadDailySalesAutoSettingChange,
} from '../../../modules/stores';
import { multiApiState, selectBoxState } from '../../../selectors/allIndexSelectors';
import Templates from '../../../components/common/templates';
import Text from '../../../components/common/atoms/Text';
import ConfigModal from './ConfigModal';
import LatestDateLabel from '../../../components/common/molecules/LastUpdatedDateLabel';
import {
  clickSidenavItemTracking,
  clickStoreItemAllIndexTracking,
  clickSortAllIndexTracking,
} from '../../../modules/tracking';
import { track } from '../../../modules/logging';
import { airblue, textLinkColor } from '../../../constants/colors';
import { assertNever, isBatchFailed, isLaborCostNoAuthority } from '../../../helpers/util';
import { ActivityIndicator } from '../../../components/common';
import ApiError from '../../../components/common/templates/ApiError';
import { genGaLog } from '../../../gaLogger';
import { State as ReduxState } from '../../../modules';
import { MydConfig } from '../../../modules/allIndex';
import { StoresData, UserData } from '../../../modules/user';
import { StoreList, Summary, AllIndexSummaryItem } from '../../../typedef/api/AllIndex';
import { RouteComponentProps } from 'react-router-dom';
import { TDataRow, TStoreRows, generateHeaderData, generateFormatedStoreData } from './formatTableData';
import { API_STATE_COMPLETED, API_STATE_INITIAL, ApiState } from '../../../typedef/api/Utility';
import { BatchProcessedDate } from '../../../typedef/BatchProcessedDate';
import * as AkrCode from '../../../typedef/AkrCode';
import TablePtn, {
  allIndexHeaderName,
  itemDescriptions,
  TOOLTIP_WITH_LINK,
  itemBind,
  MONTHLY_SALES_PREDICTED,
} from './AllIndexConstants';
import { addStoreFaq, handyFaq } from '../../../constants/faqUrls';
import { getCookie } from '../../../helpers/cookieHelper';
import { assignedStoresSelector } from '../../../selectors/userDataSelector';
import { LocalDateObj, MclDayjs, formatter, mclDayjs, parser } from '../../../helpers/mclDate';
import { isSelectedThisMonth } from '../../../helpers/mclDateHelper';
import { StaticContext } from 'react-router';
import MonthlySalesPredictedModal from './MonthlySalesPredictedModal';
import * as Onboarding from '../../../helpers/onboardingHelper';
import { CLOSE_TUTORIAL_ALLINDEX } from '../../../constants/onboarding';
import OpenLinkBlue from '../../../icons/openLinkBlue.svg';
import { AirRegiUseDetectionModal } from '../../../components/common/AirRegiUseDetectionModal';
import { AirPayLinkageModal } from '../../../components/common/AirPayLinkageModal';
import Arrow from '../../../icons/arrow_blue_right.svg';
import { ExistOfDailySalesAutoSettingChange } from '../../../typedef/api/StoreIndices';
import { dailySalesStoreDataSelector } from '../../../selectors/dailySalesSelector';
import { ConfigDailySalesList } from '../../../typedef/api/DailySales';
import { actions } from '../../../modules/dailySales';
import { Waypoint } from 'react-waypoint';
import { FROM, FROM_VALUE } from '../../../constants/requestParameter';

type DispatchProps = {
  readonly track: typeof track;
  readonly selectStore: typeof selectStore;
  readonly clickSidenavItemTracking: typeof clickSidenavItemTracking;
  readonly clickStoreItemAllIndexTracking: typeof clickStoreItemAllIndexTracking;
  readonly clickSortAllIndexTracking: typeof clickSortAllIndexTracking;
  readonly toggleModal: typeof allIndexActions.toggleModal;
  readonly toggleDialog: typeof allIndexActions.toggleDialog;
  readonly fetchConfig: typeof allIndexActions.startFetchCustomizeItem;
  readonly changeSelectDate: typeof allIndexActions.changeSelectDate;
  readonly changeSelectStore: typeof changeSelectStore;
  readonly startFetchCustomRangeStoreData: typeof allIndexActions.startFetchCustomRangeStoreData;
  readonly resetSelectedStoreFlag: typeof resetSelectedStoreFlag;
  readonly startPostConfig: typeof allIndexActions.startPostConfig;
  readonly startFetchExsistOfSalesDailyAutoSettingChange: typeof startFetchExsistOfSalesDailyAutoSettingChange;
  readonly fetchDailySales: typeof actions.fetchDailySales;
  readonly startPostReadDailySalesAutoSettingChange: typeof startPostReadDailySalesAutoSettingChange;
  readonly resetPostReadDailySalesAutoSettingChange: typeof resetPostReadDailySalesAutoSettingChange;
  readonly initialize: typeof allIndexActions.initialize;
};
type StateProps = {
  readonly selectedStores: Set<string>;
  readonly multiApiState: ApiState<{
    mtdCol: MydConfig;
    visibleStores: ReadonlyArray<StoresData & StoreList>;
    summary: Summary | null;
  }>;
  readonly isOpenConfigModal: boolean;
  readonly isOpenDialog: boolean;
  readonly isEditing: boolean;
  readonly isChangeSelectedStores: boolean;
  readonly selectedDate: { dateFrom: LocalDateObj; dateTo: LocalDateObj } | null;
  readonly selectboxData: ApiState<{ batchDate: BatchProcessedDate; stores: ReadonlyArray<StoresData> }>;
  readonly lastUpdateDate?: string | null;
  readonly batchFinishTime: string | null | undefined;
  readonly stores: ReadonlyArray<StoresData>;
  readonly laborCostViewScopeType: 'all' | 'manager';
  readonly userData: UserData | null;
  readonly exsistOfSalesDailyAutoSettingChange: ApiState<ExistOfDailySalesAutoSettingChange>;
  readonly dailySalesStoreDataState: ApiState<ReadonlyArray<StoresData & ConfigDailySalesList> | undefined>;
  readonly postReadDailySalesAutoSettingChange: ApiState<void>;
  readonly initialized: boolean;
};
type Props = Readonly<RouteComponentProps<{}> & StateProps & DispatchProps>;
type State = {
  now: MclDayjs;
  isOpenMonthlySalesPredictedModal: boolean;
  isShowMonthlySalesPredicted: boolean;
  isShowAirPayLinkageModal: boolean;
  isExsistOfSalesDailyAutoSettingChangeModal: boolean;
  reqParamFromValue: string | null;
};
type TClickedCell = {
  readonly data: TDataRow;
  readonly id: string;
  readonly label?: React.ReactNode;
};
type TRowData = {
  readonly id: string;
  readonly data: TClickedCell;
};

class AllIndex extends React.Component<Props, State> {
  readonly _textPtn: typeof TablePtn.unitPtn;
  readonly _colorRule: typeof TablePtn.colorPtn;
  _headerItems: ReadonlyArray<any>;

  constructor(props: Props) {
    super(props);
    this._headerItems = [];
    this._textPtn = TablePtn.unitPtn;
    this._colorRule = TablePtn.colorPtn;
    this.state = {
      now: mclDayjs(),
      // 売上予測モーダル表示フラグ
      isOpenMonthlySalesPredictedModal: false,
      // 売上予測表示フラグ（true:表示, false:非表示(利用ガイドの場合)）
      isShowMonthlySalesPredicted: true,
      // レジ
      isShowAirPayLinkageModal: false,
      isExsistOfSalesDailyAutoSettingChangeModal: true,
      // 流入元情報(null: パラメータなしの通常)
      reqParamFromValue: null,
    };
  }

  componentDidMount() {
    const { startFetchExsistOfSalesDailyAutoSettingChange, fetchDailySales, initialize } = this.props;
    // 流入元を識別するためのパラメータを取得
    const url: URLSearchParams = new URLSearchParams(window.location.search);
    const fromParam: string | null = url.get(FROM);
    this.setState({ reqParamFromValue: fromParam });

    // Deeplink経由でない場合は追加処理はないため初期化完了
    if (fromParam !== FROM_VALUE.ADJ_MONTHLY_SALES_PREDICTED) {
      initialize();
    }
    const log = _genComponentDidMountLog();
    const { track, fetchConfig } = this.props;
    track(log);
    fetchConfig();
    this.props.startFetchCustomRangeStoreData();
    startFetchExsistOfSalesDailyAutoSettingChange();
    fetchDailySales();
  }

  componentDidUpdate(
    prevProps: Readonly<
      Readonly<RouteComponentProps<{}, StaticContext, unknown> & StateProps & DispatchProps>
    >
  ): void {
    const {
      multiApiState,
      exsistOfSalesDailyAutoSettingChange,
      userData,
      startPostReadDailySalesAutoSettingChange,
      postReadDailySalesAutoSettingChange,
    } = this.props;
    if (prevProps.multiApiState.type !== multiApiState.type && multiApiState.type === API_STATE_COMPLETED) {
      if (
        !this.props.initialized &&
        this.state.reqParamFromValue === FROM_VALUE.ADJ_MONTHLY_SALES_PREDICTED
      ) {
        // 売上予測の広報メール内のdeeplinkから流入した場合はモーダルは表示せず、売上予測を表示する、売上予測をTrueとして登録
        // ※パラメータがある場合は過去に売上予測をOFFにしていても表示させる
        this.setState({ isOpenMonthlySalesPredictedModal: false, isShowMonthlySalesPredicted: true });

        if (
          multiApiState.payload.mtdCol.find(config => config.item === MONTHLY_SALES_PREDICTED)?.isDefault ||
          (!multiApiState.payload.mtdCol.find(config => config.item === MONTHLY_SALES_PREDICTED)?.isDefault &&
            !multiApiState.payload.mtdCol.find(config => config.item === MONTHLY_SALES_PREDICTED)?.visible)
        ) {
          // 初回または売上予測表示設定がOFFの時POSTする
          const summariesItem = multiApiState.payload.mtdCol.map(conf => {
            return {
              item: conf.item, // 項目Key(= Column Name)(e.g. sales, lastYearMonthlySales)
              visible: conf.item === MONTHLY_SALES_PREDICTED ? true : conf.visible,
            };
          });
          this.props.startPostConfig({ summariesItem: summariesItem, isIncludeInitialized: true });
        } else {
          // POST不要の場合は初期化完了とする
          this.props.initialize();
        }
      } else if (
        multiApiState.payload.mtdCol.find(config => config.item === MONTHLY_SALES_PREDICTED)?.isDefault
      ) {
        // モーダルが一度も表示されたことがない場合
        if (Onboarding.isGoalInProgress('airmate-listcheck')) {
          // 利用ガイドによる遷移の場合はオンスタと被る為、モーダルは表示させず売上予測自体も表示しない
          this.setState({ isShowMonthlySalesPredicted: false });
        } else {
          // 通常の遷移の場合に売上予測モーダル/売上予測を表示
          this.setState({ isOpenMonthlySalesPredictedModal: true, isShowMonthlySalesPredicted: true });
        }
        this.props.initialize();
      }
    }
    // //PLF閲覧可能店舗で利用検知有りの場合、レジ利用検知モーダルは表示有無に関わらず既読処理を行う
    if (
      userData != null &&
      exsistOfSalesDailyAutoSettingChange.type === API_STATE_COMPLETED &&
      exsistOfSalesDailyAutoSettingChange.payload.storeList.some(item => item.isRegiChange) &&
      postReadDailySalesAutoSettingChange.type === API_STATE_INITIAL
    ) {
      const akrCodes = userData.stores.map(store => {
        return { akrCode: store.akrCode };
      });
      startPostReadDailySalesAutoSettingChange({ storeList: akrCodes });
    }
  }

  componentWillUnmount() {
    const { resetPostReadDailySalesAutoSettingChange } = this.props;
    resetPostReadDailySalesAutoSettingChange();
  }

  _handleClickConfigModalOpener = () => {
    const { toggleModal, isOpenConfigModal } = this.props;
    if (!isOpenConfigModal) {
      const log = genGaLog('all_index', 'all_index', 'open_customize_modal', {}, {}, 'click');
      toggleModal(log);
    }
  };

  _handleClickStore = (store: TRowData, sortBy?: string) => {
    const { selectStore, clickStoreItemAllIndexTracking, history } = this.props;
    const { id } = store;
    const akrCode = id;
    const log = _genHandleClickStoreLog(akrCode);

    selectStore(AkrCode.of(akrCode), log);
    //　成績表画面の店舗選択状態をlocalStorageに保存する
    localStorage.setItem('selectedAkrcode', akrCode);
    clickStoreItemAllIndexTracking(sortBy);
    history.push('store/indices');
  };

  _handleClickHeaderItem = (header: string) => {
    const headerClickLog = _genHandleClickTableHeaderItemLog(header);

    this.props.track(headerClickLog);
  };

  _handleCloseButton = () => {
    const { toggleModal, toggleDialog, isEditing, isOpenDialog } = this.props;

    if (isEditing && !isOpenDialog) {
      toggleDialog();
    } else {
      const log: Logger = genGaLog(
        'all_index_customize_this_month',
        'header',
        'close_modal',
        {},
        {},
        'click'
      );
      toggleModal(log);
    }
  };

  selectStores = (store: Set<string>) => {
    this.props.changeSelectStore(store);
  };

  onClick = () => {
    this.props.resetSelectedStoreFlag();
    this.props.track(_genChangeStoreSelectLog());
  };

  onClose = () => {
    const { selectedStores, startFetchCustomRangeStoreData, isChangeSelectedStores, resetSelectedStoreFlag } =
      this.props;
    if (selectedStores.size !== 0 && isChangeSelectedStores) {
      startFetchCustomRangeStoreData();
    }
    resetSelectedStoreFlag();
  };

  onClickCalendar = () => {
    this.props.track(_genOpenCalendarLog());
  };

  onChangeRangeCalendar = (range: 'this_month' | 'last_month' | 'yesterday') => {
    this.props.track(_genSelectCalendarLog(range));
  };

  onMoveCalendar = (nav: 'ago' | 'later') => {
    this.props.track(_genMoveCalendarLog(nav));
  };

  onChangeFocusedRange = (date: string) => {
    this.props.track(_genSelectDateLog(date));
  };

  render() {
    const {
      selectboxData,
      multiApiState,
      selectedStores,
      selectedDate,
      lastUpdateDate,
      changeSelectDate,
      track,
      batchFinishTime,
      laborCostViewScopeType,
      userData,
      startPostConfig,
      exsistOfSalesDailyAutoSettingChange,
      dailySalesStoreDataState,
      initialized,
    } = this.props;
    // 初期化未完了時はローディング
    if (!initialized) {
      return (
        <Templates.Center>
          <ActivityIndicator />
        </Templates.Center>
      );
    }
    switch (selectboxData.type) {
      case 'API_STATE_INITIAL':
      case 'API_STATE_STARTED':
        return (
          <Templates.Center>
            <ActivityIndicator />
          </Templates.Center>
        );
      case 'API_STATE_COMPLETED':
        const { stores, batchDate } = selectboxData.payload;
        const selectThisMonth = isSelectedThisMonth(selectedDate, batchDate);
        const isMonthlySalesPredictedDefault =
          multiApiState.type === API_STATE_COMPLETED &&
          multiApiState.payload.mtdCol.find(config => config.item === MONTHLY_SALES_PREDICTED)?.isDefault;
        const singleStoreNum = 1;
        // 全店舗の検知状態のフラグ確認(表示店舗設定で表示している店舗の1店舗でもレジ連携検知状態だったらtrue)
        const isExsistOfSalesDailyAutoSettingChange =
          exsistOfSalesDailyAutoSettingChange.type === API_STATE_COMPLETED &&
          exsistOfSalesDailyAutoSettingChange.payload.storeList.some(
            item => item.isRegiChange && stores.find(store => store.akrCode === item.akrCode)
          );
        // 表示対象全店舗のペイ連携状態の確認(1店舗でもペイ連携状態だったらtrue)
        const isAirPayLinkage =
          dailySalesStoreDataState.type === API_STATE_COMPLETED &&
          dailySalesStoreDataState.payload != null &&
          multiApiState.type === API_STATE_COMPLETED &&
          multiApiState.payload != null &&
          dailySalesStoreDataState.payload.some(
            item =>
              item.dataImportType === 'cashless' &&
              multiApiState.payload.visibleStores.find(visibleStore => visibleStore.akrCode === item.akrCode)
          );

        return (
          <React.Fragment>
            <BouncePreventer>
              <Wrapper>
                <TitleWrapper>
                  <TitleWrapper>
                    <Text.PageTitle>全店舗一覧</Text.PageTitle>
                    {lastUpdateDate != null && (
                      <LatestDateLabel dateTime={lastUpdateDate} onClick={() => track(_genTooltipFaqLog())} />
                    )}
                  </TitleWrapper>
                  <FaqLink
                    faqTitle="全店舗一覧の使い方"
                    faqLink="https://faq.mate.airregi.jp/hc/ja/articles/360011392374"
                    pageName="all_index"
                    track={track}
                  />
                </TitleWrapper>
                <StyledRow>
                  <Row>
                    <StyledSelectbox
                      required={true}
                      options={
                        new Map(
                          stores.map(store => {
                            return [store.akrCode, store.storeName];
                          })
                        )
                      }
                      allSelectMessage={'全店舗'}
                      onChange={this.selectStores}
                      onClose={this.onClose}
                      onClick={this.onClick}
                      selectedItems={selectedStores}
                    />
                    <RangeCalendar
                      startDate={
                        selectedDate
                          ? parser.fromDateObject(selectedDate.dateFrom)
                          : parser.fromDateObject(batchDate).startOf('month')
                      }
                      endDate={
                        selectedDate
                          ? parser.fromDateObject(selectedDate.dateTo)
                          : parser.fromDateObject(batchDate)
                      }
                      batchDate={batchDate}
                      selectDate={(start, end) => {
                        if (selectedStores.size !== 0) {
                          changeSelectDate({
                            dateFrom: start.toLocalDateObj(),
                            dateTo: end.toLocalDateObj(),
                          });
                          const log = _genHandleSelectRangeLog(
                            start.format(formatter.mapiDate),
                            end.format(formatter.mapiDate)
                          );
                          track(log);
                        }
                      }}
                      comment={'※「今日」はリアルタイム＞全店舗タブで確認できます。'}
                      openBalloon={this.onClickCalendar}
                      changeRangeButton={this.onChangeRangeCalendar}
                      clickMonthNav={this.onMoveCalendar}
                      changeFocusedRange={this.onChangeFocusedRange}
                      id="all_index_date_select"
                    />
                  </Row>
                  <EditItemButton
                    onClick={this._handleClickConfigModalOpener}
                    disabled={multiApiState.type === 'API_STATE_FAILED'}
                    id="all_index_edit_button"
                  />
                </StyledRow>
                {isAirPayLinkage && (
                  <Waypoint onEnter={() => track(_genShowLinkingToAirpayLabel())}>
                    <LinkArea
                      onClick={() => {
                        this.setState({ isShowAirPayLinkageModal: true });
                        track(_genClickLinkingToAirpayLabel());
                      }}
                    >
                      <div>
                        <AirPayLinkageLabel legend={true}>Airペイから連携中</AirPayLinkageLabel>
                        <p>＝ Airペイの決済金額を売上として表示しています。 </p>
                        <strong>
                          詳しく見る
                          <StyledArrow />
                        </strong>
                      </div>
                    </LinkArea>
                  </Waypoint>
                )}
                <TableContent
                  multiApiState={multiApiState}
                  selectThisMonth={selectThisMonth}
                  isOpenConfigModal={this.props.isOpenConfigModal}
                  headerItems={this._headerItems}
                  handleClickStore={this._handleClickStore}
                  handleClickHeaderItem={this._handleClickHeaderItem}
                  handleCloseButton={this._handleCloseButton}
                  isBatchFinish={batchFinishTime != null && !isBatchFailed(batchFinishTime, this.state.now)}
                  laborCostViewScopeType={laborCostViewScopeType}
                  userData={userData}
                  tracker={track}
                  isShowMonthlySalesPredicted={this.state.isShowMonthlySalesPredicted}
                  isOpenMonthlySalesPredictedModal={this.state.isOpenMonthlySalesPredictedModal}
                  startPostConfig={startPostConfig}
                  closeModal={() => {
                    this.setState({ isOpenMonthlySalesPredictedModal: false });
                  }}
                  dailySalesStoreDataState={dailySalesStoreDataState}
                  isExsistOfSalesDailyAutoSettingChange={isExsistOfSalesDailyAutoSettingChange}
                />
                {/* 表示店舗が1店舗の場合に店舗追加のFAQ表示 */}
                {stores.length === singleStoreNum && (
                  <AddStoreFaqWrapper>
                    <AddStoreFaqLink
                      href={addStoreFaq}
                      target="_blank"
                      onClick={() => {
                        track(_genAddStoreFaqLog());
                      }}
                    >
                      表示されない店舗がある場合
                      <StyledOpenLinkBlue />
                    </AddStoreFaqLink>
                  </AddStoreFaqWrapper>
                )}
                {this.state.isShowAirPayLinkageModal && (
                  <AirPayLinkageModal
                    onClick={() => {
                      this.setState({ isShowAirPayLinkageModal: false });
                      track(_genCloseAirPayLinkageModalLog());
                    }}
                    tracker={track}
                    from={'all_index'}
                  />
                )}
                {isExsistOfSalesDailyAutoSettingChange &&
                  this.state.isExsistOfSalesDailyAutoSettingChangeModal && (
                    // レジ利用検知モーダル
                    <AirRegiUseDetectionModal
                      onClick={() => {
                        this.setState({ isExsistOfSalesDailyAutoSettingChangeModal: false });
                        track(_genCloseDailySalesAutoSettingChangeModal());
                      }}
                      tracker={track}
                      from={'all_index'}
                    />
                  )}
              </Wrapper>
              {/* オンスタ関数から発火させる為の空要素 */}
              <CloseTutorialAllindexButton
                id={CLOSE_TUTORIAL_ALLINDEX}
                onClick={() => {
                  // 売上予測設定されていない場合、売上予測モーダルを表示
                  if (isMonthlySalesPredictedDefault) {
                    this.setState({
                      isShowMonthlySalesPredicted: true,
                      isOpenMonthlySalesPredictedModal: true,
                    });
                  }
                }}
              />
            </BouncePreventer>
          </React.Fragment>
        );

      case 'API_STATE_FAILED':
        return (
          <Templates.Center>
            <ApiError />
          </Templates.Center>
        );
      default:
        return assertNever(selectboxData);
    }
  }
}

const generateDescriptionText = (paramName: string): React.ReactElement => {
  return (
    <div>
      {TOOLTIP_WITH_LINK[paramName].annotation != null ? (
        TOOLTIP_WITH_LINK[paramName].annotation.map(annotation => (
          <React.Fragment key={annotation}>
            {annotation}
            <br />
          </React.Fragment>
        ))
      ) : (
        <React.Fragment />
      )}
      <div>
        詳細は
        <LinkText target="_blank" href={handyFaq}>
          こちら
        </LinkText>
        からご確認ください。
      </div>
    </div>
  );
};

const TableContent = ({
  multiApiState,
  selectThisMonth,
  isOpenConfigModal,
  headerItems,
  handleClickStore,
  handleClickHeaderItem,
  handleCloseButton,
  isBatchFinish,
  laborCostViewScopeType,
  userData,
  tracker,
  isShowMonthlySalesPredicted,
  isOpenMonthlySalesPredictedModal,
  startPostConfig,
  closeModal,
  dailySalesStoreDataState,
  isExsistOfSalesDailyAutoSettingChange,
}: {
  multiApiState: ApiState<{
    mtdCol: MydConfig;
    visibleStores: ReadonlyArray<StoresData & StoreList>;
    summary: Summary | null;
  }>;
  selectThisMonth: boolean;
  isOpenConfigModal: boolean;
  headerItems: ReadonlyArray<any>;
  handleClickStore: (store: TRowData, sortBy?: string) => void;
  handleClickHeaderItem: (header: string) => void;
  handleCloseButton: () => void;
  isBatchFinish: boolean;
  laborCostViewScopeType: 'all' | 'manager';
  userData: UserData | null;
  tracker: typeof track;
  isShowMonthlySalesPredicted: boolean;
  isOpenMonthlySalesPredictedModal: boolean;
  startPostConfig: typeof allIndexActions.startPostConfig;
  closeModal: () => void;
  dailySalesStoreDataState: ApiState<ReadonlyArray<StoresData & ConfigDailySalesList> | undefined>;
  isExsistOfSalesDailyAutoSettingChange: boolean;
}) => {
  switch (multiApiState.type) {
    case 'API_STATE_INITIAL':
    case 'API_STATE_STARTED':
      return (
        <Templates.Center>
          <ActivityIndicator />
        </Templates.Center>
      );
    case 'API_STATE_COMPLETED':
      const { mtdCol, summary, visibleStores } = multiApiState.payload;
      const configItems = isShowMonthlySalesPredicted
        ? mtdCol
        : // 利用ガイドで遷移された場合、売上予測は表示させない為configのリストから売上予測を消す
          mtdCol.filter(mtd => mtd.item !== MONTHLY_SALES_PREDICTED);
      let validStoreData: TStoreRows = [];
      const isVisibleColumn = (col: AllIndexSummaryItem): boolean => {
        return Object.keys(allIndexHeaderName).includes(col.item) && col.visible;
      };
      headerItems = isLaborCostNoAuthority(laborCostViewScopeType, userData?.plfGrant)
        ? //閲覧権限が無い場合はshiftEstimateLaborCost(シフト概算人件費)を除外する
          generateHeaderData(
            configItems.filter(col => isVisibleColumn(col)).map(col => col.item),
            selectThisMonth,
            { viewName: 'all_index', feature: 'table_header' }
          ).filter(item => item.name !== 'shiftEstimateLaborCost')
        : generateHeaderData(
            configItems.filter(col => isVisibleColumn(col)).map(col => col.item),
            selectThisMonth,
            { viewName: 'all_index', feature: 'table_header' }
          );
      // 店舗データに売上取込設定の取込タイプをマージ
      const mergedVisibleStores = visibleStores.map(item => {
        if (dailySalesStoreDataState.type === API_STATE_COMPLETED) {
          const updateDailySalesStoreDataState =
            dailySalesStoreDataState.payload != null &&
            dailySalesStoreDataState.payload.find(
              dailySalesStoreData => dailySalesStoreData.akrCode === item.akrCode
            );
          return updateDailySalesStoreDataState
            ? { ...item, dataImportType: updateDailySalesStoreDataState.dataImportType }
            : item;
        } else {
          return item;
        }
      });
      // @ts-ignore
      const storeData: ReadonlyArray<ReadonlyArray<number | null>> = _generateStoreDataArrayFromHashArray(
        // @ts-ignore
        summary ? [summary, ...mergedVisibleStores] : mergedVisibleStores,
        headerItems
      );
      validStoreData = generateFormatedStoreData(storeData, headerItems);

      const descriptionItems = {
        ...itemDescriptions.sub,
        firstDrinkAverageServingTime: generateDescriptionText('firstDrinkAverageServingTime'),
        firstFoodAverageServingTime: generateDescriptionText('firstFoodAverageServingTime'),
        drinkLateServeCountAverage: generateDescriptionText('drinkLateServeCountAverage'),
        foodLateServeCountAverage: generateDescriptionText('foodLateServeCountAverage'),
        lateServeRate: generateDescriptionText('lateServeRate'),
        lateServeVisitorNum: generateDescriptionText('lateServeVisitorNum'),
        alertTimeDelayItemCount: generateDescriptionText('alertTimeDelayItemCount'),
      };

      const descriptions = { ...itemDescriptions, sub: descriptionItems };
      // 売上予測モーダル用のリクエストパラメータを生成する
      const getMonthlySalesPredictedModalConfig = (isVisible: boolean) => {
        return configItems.map(conf => {
          return {
            item: conf.item, // 項目Key(= Column Name)(e.g. sales, lastYearMonthlySales)
            main: descriptions.main[conf.item],
            sub: descriptions.sub[conf.item],
            visible: conf.item === MONTHLY_SALES_PREDICTED ? isVisible : conf.visible,
          };
        });
      };
      return (
        <React.Fragment>
          <Table.StickyScrollableSummary
            rowName="店舗名"
            rowLabels={['合計', ...mergedVisibleStores].map((store: any, idx: number) => {
              if (idx === 0) {
                return <Text.Default>{store}</Text.Default>;
              } else {
                return (
                  <React.Fragment>
                    <Text.Default color={textLinkColor}>{store.storeName}</Text.Default>
                    <br />
                    {store.dataImportType === 'cashless' && (
                      <AirPayLinkageLabel>Airペイから連携中</AirPayLinkageLabel>
                    )}
                  </React.Fragment>
                );
              }
            })}
            sortKey="sales"
            ids={['', ...mergedVisibleStores.map(store => store.akrCode)]} // idsは店舗のakrCodeの配列. ただし、法人合計行は空文字列にしている.
            headerItems={headerItems}
            rows={validStoreData}
            onClickRow={(row: TRowData) => handleClickStore(row)}
            onClickHeaderItem={handleClickHeaderItem}
            isSortable={true}
            tableWrapperId="all_index_table_wrapper"
            tableId="all_index_table"
            isBatchFinish={isBatchFinish}
          />
          {isOpenConfigModal && (
            <ConfigModal
              onClose={handleCloseButton}
              config={configItems}
              descriptions={{ ...itemDescriptions, sub: descriptionItems }}
              track={tracker}
              // 売上予測のデータがない場合はnull
              hasMonthlySalesPredicted={summary?.monthlySalesPredicted != null}
            />
          )}
          {!isExsistOfSalesDailyAutoSettingChange && isOpenMonthlySalesPredictedModal && (
            // 売上予測モーダル
            <MonthlySalesPredictedModal
              onClickShow={() => {
                closeModal();
                startPostConfig({ summariesItem: getMonthlySalesPredictedModalConfig(true) });
              }}
              onClickNotShow={() => {
                closeModal();
                startPostConfig({ summariesItem: getMonthlySalesPredictedModalConfig(false) });
              }}
            />
          )}
        </React.Fragment>
      );

    case 'API_STATE_FAILED':
      return (
        <Templates.Center>
          <ApiError />
        </Templates.Center>
      );
    default:
      return assertNever(multiApiState);
  }
};

const _generateStoreDataArrayFromHashArray = <T extends any>(
  hashArr: ReadonlyArray<{
    [x: string]: T;
  }>,
  colNames: ReadonlyArray<{
    readonly name: string;
  }>
): ReadonlyArray<ReadonlyArray<T>> => {
  return hashArr.map(hash => {
    return colNames.map(col => {
      const bindedKey = itemBind[col.name] !== undefined ? itemBind[col.name] : col.name;
      return hash[bindedKey];
    });
  });
};

const mapStateToProps = (state: ReduxState): StateProps => {
  return {
    multiApiState: multiApiState(state),
    selectedStores: state.stores.selectedStores,
    isOpenConfigModal: state.allIndex.isOpenModal,
    isOpenDialog: state.allIndex.isOpenDialog,
    isEditing: state.allIndex.isEditing,
    selectedDate: state.allIndex.selectedDate,
    selectboxData: selectBoxState(state),
    isChangeSelectedStores: state.stores.isChangeSelectedStores,
    lastUpdateDate: state.uiConfig.batchProcessLastFinishDatetime,
    batchFinishTime: state.uiConfig.batchProcessLastFinishDatetime,
    stores: assignedStoresSelector(state),
    laborCostViewScopeType: state.uiConfig.laborCostViewScopeType,
    userData: state.user.data,
    exsistOfSalesDailyAutoSettingChange: state.stores.ExistOfDailySalesAutoSettingChange,
    dailySalesStoreDataState: dailySalesStoreDataSelector(state),
    postReadDailySalesAutoSettingChange: state.stores.postReadDailySalesAutoSettingChange,
    initialized: state.allIndex.initialized,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
  return {
    track: bindActionCreators(track, dispatch),
    selectStore: bindActionCreators(selectStore, dispatch),
    clickSidenavItemTracking: bindActionCreators(clickSidenavItemTracking, dispatch),
    clickStoreItemAllIndexTracking: bindActionCreators(clickStoreItemAllIndexTracking, dispatch),
    clickSortAllIndexTracking: bindActionCreators(clickSortAllIndexTracking, dispatch),
    toggleModal: bindActionCreators(allIndexActions.toggleModal, dispatch),
    toggleDialog: bindActionCreators(allIndexActions.toggleDialog, dispatch),
    fetchConfig: bindActionCreators(allIndexActions.startFetchCustomizeItem, dispatch),
    changeSelectDate: bindActionCreators(allIndexActions.changeSelectDate, dispatch),
    changeSelectStore: bindActionCreators(changeSelectStore, dispatch),
    resetSelectedStoreFlag: bindActionCreators(resetSelectedStoreFlag, dispatch),
    startFetchCustomRangeStoreData: bindActionCreators(
      allIndexActions.startFetchCustomRangeStoreData,
      dispatch
    ),
    startPostConfig: bindActionCreators(allIndexActions.startPostConfig, dispatch),
    startFetchExsistOfSalesDailyAutoSettingChange: bindActionCreators(
      startFetchExsistOfSalesDailyAutoSettingChange,
      dispatch
    ),
    fetchDailySales: bindActionCreators(actions.fetchDailySales, dispatch),
    startPostReadDailySalesAutoSettingChange: bindActionCreators(
      startPostReadDailySalesAutoSettingChange,
      dispatch
    ),
    resetPostReadDailySalesAutoSettingChange: bindActionCreators(
      resetPostReadDailySalesAutoSettingChange,
      dispatch
    ),
    initialize: bindActionCreators(allIndexActions.initialize, dispatch),
  };
};

const Wrapper = styled.div`
  padding: 16px 24px;
  height: 100%;
  width: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledRow = styled(Row)`
  padding-bottom: 24px;
  padding-top: 16px;
  justify-content: space-between;
`;

const BouncePreventer = styled.div`
  height: 100%;
  min-height: 632px;
`;

const StyledSelectbox = styled(SelectBox.multiple)`
  margin-right: 16px;
  width: 240px;
`;

const LinkText = styled.a`
  color: ${airblue};
`;

const CloseTutorialAllindexButton = styled.div`
  visibility: hidden;
`;

const AddStoreFaqWrapper = styled.div`
  margin-top: 14px;
  text-align: right;
`;

const AddStoreFaqLink = styled.a`
  color: ${airblue};
`;

const StyledOpenLinkBlue = styled(OpenLinkBlue)`
  margin-left: 4px;
`;

const AirPayLinkageLabel = styled.div<{ legend?: boolean }>`
  width: 118px;
  display: flex;
  gap: 6px;
  color: ${airblue};
  font-size: 12px;
  border: 1px solid ${airblue};
  padding: 2px 8px;
  border-radius: 4px;
  margin-top: ${props => !props.legend && '8px'};
  align-items: baseline;
  margin-right: 6px;
  white-space: nowrap;
`;

const LinkArea = styled.a`
  cursor: pointer;
  display: inline-block;
  margin-bottom: 16px;
  > div {
    display: flex;
    align-items: baseline;
    > p {
      font-size: 12px;
    }
    > strong {
      color: ${textLinkColor};
      font-size: 12px;
    }
  }
`;

const StyledArrow = styled(Arrow)`
  margin-left: 2px;
`;

export default connect(mapStateToProps, mapDispatchToProps)(AllIndex);

const _genChangeStoreSelectLog = () => {
  return genGaLog('all_index', 'all_index', 'open_change_stores_select_box', {}, {}, 'click');
};

const _genHandleClickStoreLog = akrCode => {
  return genGaLog('all_index', 'all_index', 'transition_store_summary', {}, { akr_code: akrCode }, 'click');
};

const _genComponentDidMountLog = () => {
  const cookieData = getCookie('influxData');
  let vos: string | undefined;
  let lid: string | undefined;
  let viaPromoFlg: string | undefined;
  if (cookieData != null) {
    const cookieDataJson = JSON.parse(cookieData);
    vos = cookieDataJson.vos;
    lid = cookieDataJson.lid;
    viaPromoFlg = cookieDataJson.via_promo_flg;
  }

  return genGaLog('all_index', 'all_index', 'on_load', {}, {}, 'load', undefined, vos, lid, viaPromoFlg);
};

const _genHandleSelectRangeLog = (startDate: string, endDate: string) => {
  return genGaLog(
    'all_index',
    'change_date_range',
    'execute_change_date_range',
    { start_date: startDate, end_date: endDate },
    {},
    'click'
  );
};

const _genHandleClickTableHeaderItemLog = (selectedItem: string) => {
  return genGaLog('all_index', 'all_index', 'sort_table', {}, { item: selectedItem, order: 'desc' }, 'click');
};

const _genOpenCalendarLog = () => {
  return genGaLog('all_index', 'all_index', 'open_change_date_range_select_box', {}, {}, 'click');
};

const _genSelectCalendarLog = (selectRange: 'this_month' | 'last_month' | 'yesterday') => {
  return genGaLog('all_index', 'change_date_range', `select_${selectRange}`, {}, {}, 'click');
};

const _genMoveCalendarLog = (nav: 'ago' | 'later') => {
  return genGaLog('all_index', 'change_date_range', `change_calendar_one_month_${nav}`, {}, {}, 'click');
};

const _genSelectDateLog = (date: string) => {
  return genGaLog(
    'all_index',
    'change_date_range',
    'select_days_on_calendar',
    { clicked_date: date },
    {},
    'click'
  );
};

const _genTooltipFaqLog = () => {
  return genGaLog('all_index', 'all_index', 'open_tooltip_lastUpdatedDateLabel_faq', {}, {}, 'click');
};

const _genAddStoreFaqLog = () => {
  return genGaLog('all_index', 'all_index_textlink', 'click', {}, {}, 'click');
};

const _genShowLinkingToAirpayLabel = () => {
  return genGaLog(
    'all_index',
    'all_index_linkingtoairpay',
    'impression',
    {},
    { type: '[apy]' },
    'impression'
  );
};

const _genClickLinkingToAirpayLabel = () => {
  return genGaLog('all_index', 'all_index_linkingtoairpay', 'open', {}, { type: '[apy]' }, 'click');
};

const _genCloseAirPayLinkageModalLog = () => {
  return genGaLog('all_index', 'all_index_linkingtoairpay_modal', 'close', {}, { type: '[apy]' }, 'click');
};

const _genCloseDailySalesAutoSettingChangeModal = () => {
  return genGaLog('all_index', 'all_index_argusedetected_modal', 'close', {}, {}, 'click');
};
