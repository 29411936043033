import { ShiftImportSettingForm, ShiftImportSettingRequest } from '../typedef/api/ShiftImportSetting';
import { apiState, ApiState, ErrorType } from '../typedef/api/Utility';

export const INITIAL_FETCH_IMPORT_SETTING = 'shiftImportSetting/INITIAL_FETCH_IMPORT_SETTING';
export const FETCH_IMPORT_SETTING_START = 'shiftImportSetting/FETCH_IMPORT_SETTING_START';
export const FETCH_IMPORT_SETTING_SUCCESS = 'shiftImportSetting/FETCH_IMPORT_SETTING_SUCCESS';
export const FETCH_IMPORT_SETTING_FAIL = 'shiftImportSetting/FETCH_IMPORT_SETTING_FAIL';
export const POST_IMPORT_SETTING_START = 'shiftImportSetting/POST_IMPORT_SETTING_START';
export const POST_IMPORT_SETTING_SUCCESS = 'shiftImportSetting/POST_IMPORT_SETTING_SUCCESS';
export const POST_IMPORT_SETTING_FAIL = 'shiftImportSetting/POST_IMPORT_SETTING_FAIL';

export const types = {
  INITIAL_FETCH_IMPORT_SETTING,
  FETCH_IMPORT_SETTING_START,
  FETCH_IMPORT_SETTING_SUCCESS,
  FETCH_IMPORT_SETTING_FAIL,
  POST_IMPORT_SETTING_START,
  POST_IMPORT_SETTING_SUCCESS,
  POST_IMPORT_SETTING_FAIL,
};

export type InitialFetchShiftImportSettingAction = {
  readonly type: typeof INITIAL_FETCH_IMPORT_SETTING;
};

export type FetchShiftImportSettingAction = {
  readonly type: typeof FETCH_IMPORT_SETTING_START;
  readonly payload: string;
};

export type FetchShiftImportSettingSuccessAction = {
  readonly type: typeof FETCH_IMPORT_SETTING_SUCCESS;
  readonly payload: ShiftImportSettingForm;
};

export type FetchShiftImportSettingFailAction = {
  readonly type: typeof FETCH_IMPORT_SETTING_FAIL;
  readonly payload: ErrorType;
};

export type PostShiftImportSettingAction = {
  readonly type: typeof POST_IMPORT_SETTING_START;
  readonly payload: ShiftImportSettingRequest;
};

export type PostShiftImportSettingSuccessAction = {
  readonly type: typeof POST_IMPORT_SETTING_SUCCESS;
};

export type PostShiftImportSettingFailAction = {
  readonly type: typeof POST_IMPORT_SETTING_FAIL;
  readonly payload: ErrorType;
};

export type Action =
  | InitialFetchShiftImportSettingAction
  | FetchShiftImportSettingAction
  | FetchShiftImportSettingSuccessAction
  | FetchShiftImportSettingFailAction
  | PostShiftImportSettingAction
  | PostShiftImportSettingSuccessAction
  | PostShiftImportSettingFailAction;

export const initialFetchShiftImportSetting = (): InitialFetchShiftImportSettingAction => {
  return {
    type: INITIAL_FETCH_IMPORT_SETTING,
  };
};

export const fetchShiftImportSetting = (akrCode: string): FetchShiftImportSettingAction => {
  return {
    type: FETCH_IMPORT_SETTING_START,
    payload: akrCode,
  };
};
export const fetchShiftImportSettingSuccess = (
  data: ShiftImportSettingForm
): FetchShiftImportSettingSuccessAction => {
  return {
    type: FETCH_IMPORT_SETTING_SUCCESS,
    payload: data,
  };
};
export const fetchShiftImportSettingFail = (err: ErrorType): FetchShiftImportSettingFailAction => {
  return {
    type: FETCH_IMPORT_SETTING_FAIL,
    payload: err,
  };
};

export const postShiftImportSetting = (req: ShiftImportSettingRequest): PostShiftImportSettingAction => {
  return {
    type: POST_IMPORT_SETTING_START,
    payload: req,
  };
};
export const postShiftImportSettingSuccess = (): PostShiftImportSettingSuccessAction => {
  return {
    type: POST_IMPORT_SETTING_SUCCESS,
  };
};
export const postShiftImportSettingFail = (err: ErrorType): PostShiftImportSettingFailAction => {
  return {
    type: POST_IMPORT_SETTING_FAIL,
    payload: err,
  };
};

export const actions = {
  initialFetchShiftImportSetting,
  fetchShiftImportSetting,
  fetchShiftImportSettingSuccess,
  fetchShiftImportSettingFail,
  postShiftImportSetting,
  postShiftImportSettingSuccess,
  postShiftImportSettingFail,
};

export type State = {
  shiftImportSettingFormState: ApiState<ShiftImportSettingForm>;
  postImportSettingFormState: ApiState<void>;
};

export const initialState: State = {
  shiftImportSettingFormState: apiState.initial(),
  postImportSettingFormState: apiState.initial(),
};

const reducer = (state: State = initialState, action?: Action): State => {
  if (action == null) {
    return state;
  }
  switch (action.type) {
    case FETCH_IMPORT_SETTING_START:
      return { ...state, shiftImportSettingFormState: apiState.started() };
    case FETCH_IMPORT_SETTING_SUCCESS:
      return { ...state, shiftImportSettingFormState: apiState.completed(action.payload) };
    case FETCH_IMPORT_SETTING_FAIL:
      return { ...state, shiftImportSettingFormState: apiState.failed(action.payload) };
    case POST_IMPORT_SETTING_START:
      return { ...state, postImportSettingFormState: apiState.started() };
    case POST_IMPORT_SETTING_SUCCESS:
      return { ...state, postImportSettingFormState: apiState.completed(undefined) };
    case POST_IMPORT_SETTING_FAIL:
      return { ...state, postImportSettingFormState: apiState.failed(action.payload) };

    default:
      return state;
  }
};

export default reducer;
