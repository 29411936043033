import * as React from 'react';
import styled from 'styled-components';
import { gray, lightgray } from '../../../../constants/colors';
import SelectBox from '../../../../components/common/atoms/SelectBox';
import Step from '../../../../components/common/atoms/Step';
import AirRadioButton from '../../../../components/common/molecules/Airkit/AirRadioButton';
import { BasicSetting } from '../../../../typedef/api/BasicSetting';
import { formatter, mclDayjs } from '../../../../helpers/mclDate';

const options = ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'];

const CommonAllStoreDisplaySetting = ({
  data,
  setFieldValue,
}: {
  data: BasicSetting | undefined;
  setFieldValue: (field: string, value: any) => void;
}) => {
  return data != null ? (
    <Step title="全店舗共通の表示設定">
      <Table id="basic_setting_all_store_display_table">
        <Th>
          <Text>税表示</Text>
        </Th>
        <Td>
          <RadioButtonWrapper>
            <Text>
              <StyledAirRadioButton
                name="includingTax"
                label="税込み"
                value={data.basicSetting.isTaxIncluded.toString()}
                checked={data.basicSetting.isTaxIncluded.toString() === 'true'}
                onChange={() =>
                  setFieldValue('commonAllStoreDisplaySettingData.basicSetting.isTaxIncluded', true)
                }
              />
            </Text>
            <Text>
              <StyledAirRadioButton
                name="excludingTax"
                label="税抜き"
                value={data.basicSetting.isTaxIncluded.toString()}
                checked={data.basicSetting.isTaxIncluded.toString() === 'false'}
                onChange={() =>
                  setFieldValue('commonAllStoreDisplaySettingData.basicSetting.isTaxIncluded', false)
                }
              />
            </Text>
          </RadioButtonWrapper>
          <Description>
            {data.basicSetting.isTaxIncluded ? (
              <div>
                売上や客単価などの表示方法を設定します。
                <br />
                <br />
                「税込み」設定の場合、日報などのコストも税込み金額で入力してください。
              </div>
            ) : (
              <div>
                売上や客単価などの表示方法を設定します。
                <br />
                <br />
                増税前後での売上比較を正しく行いたい場合は、「税抜き」で表示します。
                <br />
                「税抜き」を選択した場合、日報などのコストも税抜き金額で入力してください。
                <br />
                <br />
                ※Airレジから連携される金額は「税込み」のため、表示金額にずれが発生します。
                <br />
                ※変更前に手入力した値の自動修正は行われないので、消費税額を加味してご自身で修正してください。
              </div>
            )}
          </Description>
        </Td>
        <Th>
          <Text>年度開始月</Text>
        </Th>
        <Td>
          <SelectMonthWrapper>
            <Text>
              <SelectBox.normal
                options={options.map((option, idx) => ({ key: idx.toString(), value: option }))}
                placeholder={
                  data.basicSetting.startMonth != null
                    ? {
                        key: data.basicSetting.startMonth.toString(),
                        value: `${data.basicSetting.startMonth}月`,
                      }
                    : { key: '未設定', value: '未設定' }
                }
                onChange={value => {
                  const startMonth = mclDayjs(value.value, formatter.month).pureMonth();
                  if (startMonth != null) {
                    setFieldValue('commonAllStoreDisplaySettingData.basicSetting.startMonth', startMonth);
                  }
                }}
              />
            </Text>
          </SelectMonthWrapper>
          <Description>会計年度の開始月を設定してください。</Description>
        </Td>
      </Table>
    </Step>
  ) : (
    <React.Fragment />
  );
};

export default CommonAllStoreDisplaySetting;

const Table = styled.div`
  display: grid;
  grid-template-columns: 150px 1fr;
  margin-bottom: 60px;
`;
const Th = styled.div`
  min-height: 73px;
  width: 150px;
  font-size: 14px;
  font-weight: 600;
  background: ${gray};
  border: 1px solid ${lightgray};
  display: flex;
  justify-content: center;
`;

const Td = styled.div`
  border: 1px solid ${lightgray};
  display: grid;
  grid-template-columns: 263px 1fr;
  padding: 10px 0;
`;

const Text = styled.div`
  display: flex;
  align-items: center;
`;

const RadioButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: 24px;
`;

const SelectMonthWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 24px;
`;

const Description = styled.div`
  margin: auto 0;
`;

const StyledAirRadioButton = styled(AirRadioButton)`
  margin-right: 24px;
`;
