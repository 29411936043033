import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Checkbox } from '@air-kit/air-kit';
import ClickOutside from 'react-click-outside';
import Descend from '../../../../icons/Descend.svg';
import Text from '../../../../components/common/atoms/Text';
import Zindex from '../../../../constants/z-index';
import { black, uploadBorderColor, crimsonRed, hoverAndSelectedColor } from '../../../../constants/colors';
import { CardInfo } from '../../../../typedef/api/CardCostList/CardInfo';

type Props = {
  readonly className?: string;
  readonly selectedItems: Set<string>;
  readonly onChange: (a: Set<string>) => void;
  readonly onClick?: () => void;
  readonly onClose?: () => void;
  readonly height?: number;
  readonly disabled?: boolean;
  readonly required?: boolean;
  readonly hasConfirmWhenChange?: boolean;
  readonly isBalloonPositionRight?: boolean;
  readonly cardInfoList: Array<CardInfo & { storeName?: string }>;
};

type State = {
  isOpen: boolean;
  changeFlag: boolean;
};

class CardStoreSelectBox extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: false,
      changeFlag: false,
    };
  }

  componentWillUnmount() {
    this.setState({
      isOpen: false,
      changeFlag: false,
    });
  }

  _handleClickSelector(e: React.SyntheticEvent<HTMLElement>, key: string) {
    e.stopPropagation(); // select boxを押して閉じるときoutsideclickと衝突するため

    this.setState({ ...this.state, changeFlag: true });
    if (this.props.selectedItems.has(key)) {
      const tempSet = new Set(this.props.selectedItems);
      if (tempSet.delete(key)) this.props.onChange(tempSet);
    } else {
      const tempSet = new Set(this.props.selectedItems);
      if (tempSet.add(key)) this.props.onChange(tempSet);
    }
  }

  _handleToggleSelectBox = (e: React.SyntheticEvent<HTMLElement>, onClick?: Function | null) => {
    e.stopPropagation(); // select boxを押して閉じるときoutsideclickと衝突するため
    if (
      this.state.isOpen &&
      this.props.onClose &&
      (!this.props.hasConfirmWhenChange || this.state.changeFlag)
    ) {
      this.props.onClose();
    }

    onClick && onClick();
    this.setState({ isOpen: !this.state.isOpen, changeFlag: false });
    e.preventDefault();
  };

  _setPlaceHolderText = () => {
    const { selectedItems, cardInfoList } = this.props;

    if (selectedItems != null && selectedItems.size > 0) {
      if (selectedItems.size === cardInfoList.length) {
        return 'すべてのカード';
      } else {
        const tempList: Array<string> = [];
        Array.from(selectedItems).forEach(key => {
          cardInfoList.forEach(cardInfo => {
            if (cardInfo.cardKey === key) {
              tempList.push('下4桁 ' + cardInfo.personCardNo);
            }
          });
        });
        const cardName = tempList.join('、');
        return cardName;
      }
    } else {
      return 'カードを選択してください';
    }
  };

  _renderListItem = () => {
    const { height, cardInfoList, selectedItems, isBalloonPositionRight } = this.props;

    return (
      <ClickOutside onClickOutside={e => this._handleToggleSelectBox(e)}>
        <Balloon isBalloonPositionRight={isBalloonPositionRight}>
          <List height={height}>
            {cardInfoList.map(cardInfo => (
              <React.Fragment key={cardInfo.cardKey}>
                <ListItem
                  isSelected={selectedItems.has(cardInfo.cardKey)}
                  onClick={e => this._handleClickSelector(e, cardInfo.cardKey)}
                >
                  <StyledCheckbox
                    isChecked={selectedItems.has(cardInfo.cardKey)}
                    onClick={e => e.stopPropagation()}
                  />
                  <CardNoLabel>下4桁 {cardInfo.personCardNo}</CardNoLabel>
                  <NameLabel>{cardInfo.kanjiName}</NameLabel>
                  {cardInfo.storeName != null && (
                    <React.Fragment>
                      <Parentheses>(</Parentheses>
                      <StoreNameLabel>{cardInfo.storeName}</StoreNameLabel>
                      <Parentheses>)</Parentheses>
                    </React.Fragment>
                  )}
                </ListItem>
              </React.Fragment>
            ))}
          </List>
          <BoxPointer isBalloonPositionRight={isBalloonPositionRight} />
        </Balloon>
      </ClickOutside>
    );
  };

  render() {
    const { className, onClick, disabled, selectedItems, required } = this.props;
    return (
      // 親にflexがきてもいいようにdivで囲っておく
      <Container>
        <FormContainer
          onClick={e => this._handleToggleSelectBox(e, onClick)}
          className={className}
          error={(!selectedItems || selectedItems.size === 0) && required}
        >
          <CustomText disable={disabled}>{this._setPlaceHolderText()}</CustomText>
          <Descend />
        </FormContainer>
        {this.state.isOpen && this._renderListItem()}
      </Container>
    );
  }
}

const Container = styled.div`
  position: relative;
`;

const FormContainer = styled.div<{ error?: boolean }>`
  overflow: hidden;
  width: 320px;
  height: 44px;
  border-radius: 4px;
  border: solid 1px;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-color: ${uploadBorderColor};
  cursor: pointer;
  ${props => (props.error ? `border-color: ${crimsonRed};` : '')}
`;

const List = styled.div<{ height?: number }>`
  width: 460px;
  background-color: white;
  position: absolute;
  top: 5px;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  max-height: 400px;
  overflow-y: scroll;
  height: ${props => props.height}px;
  padding-top: 6px;
`;

const ListItem = styled.div<{ isSelected?: boolean }>`
  min-height: 40px;
  padding: 12px;
  background-color: ${props => props.isSelected && hoverAndSelectedColor};
  :not(:first-child) {
    margin-top: 1px;
  }

  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: ${hoverAndSelectedColor};
  }
  word-break: break-all;
`;

const Balloon = styled.div<{ isBalloonPositionRight?: boolean }>`
  position: absolute;
  z-index: ${Zindex.selectBox};
  ${props => (props.isBalloonPositionRight ? 'right: 280px;' : '')}
`;

const BoxPointer = styled.div<{ isBalloonPositionRight?: boolean }>`
  background-color: white;
  ${props => (props.isBalloonPositionRight ? 'right: -260px;' : 'left: 10px;')}
  width: 10px;
  height: 10px;
  position: absolute;
  transform: rotate(45deg);
  z-index: ${Zindex.selectBox};
  box-shadow: -1px -1px 1px 0px rgba(0, 0, 0, 0.05);
`;

const CustomText = styled(Text.Default)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  width: calc(100% - 12px);
`;

const StyledCheckbox = styled(Checkbox)`
  min-width: 26px;
`;

const CardNoLabel = styled.div`
  font-size: 14px;
  color: ${black};
  min-width: 80px;
`;

const NameLabel = styled.div`
  font-size: 14px;
  color: ${black};
  margin: 0 20px;
  display: -webkit-box;
  min-width: 80px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
`;

const StoreNameLabel = styled.div`
  font-size: 12px;
  color: ${black};
  display: -webkit-box;
  min-width: 150px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
`;

const Parentheses = styled.div`
  font-size: 12px;
  color: ${black};
`;

export default CardStoreSelectBox;
