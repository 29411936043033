import { createSelector } from 'reselect';
import { StoresData } from '../modules/user';
import { TransferInfoSummary, GopTransferInfoSummary } from '../typedef/api/Payment/PaymentSummary';
import { API_STATE_COMPLETED } from '../typedef/api/Utility';
import { _InputSelector, _OutputSelector } from '../typedef/selector';
import { joinStoreByAkrcode } from '../helpers/util';
import { BatchProcessedDate } from '../typedef/BatchProcessedDate';
import { assignedStoresSelector } from './userDataSelector';
import { Stores } from '../typedef/api/Payment/PaymentMaster';
import { getYearMonthFromPastToNextMonth } from '../helpers/mclDateHelper';

export const storesWithDataSelector: _InputSelector<ReadonlyArray<StoresData>> = state => {
  return state.user.data != null ? state.user.data.stores : [];
};

const transferInfoSummarySelector: _InputSelector<
  ReadonlyArray<TransferInfoSummary | GopTransferInfoSummary>
> = state => {
  const { paymentSummaryState } = state.payment.payment;

  return paymentSummaryState.type === API_STATE_COMPLETED
    ? [
        ...paymentSummaryState.payload.transferInfoSummary.transferInfoList,
        ...paymentSummaryState.payload.transferInfoSummary.gopTransferInfoList,
      ]
    : [];
};

// paymentMasterで取得した店舗リストの中の最も過去の入金月を返す
export const minMonthSelector: _InputSelector<string | null | undefined> = state => {
  const { paymentMasterState } = state.payment.payment;
  if (paymentMasterState.type === API_STATE_COMPLETED) {
    let assignedMasterStores: Array<Stores> = [];
    const assignedStoresAkrcode = assignedStoresSelector(state).map(store => store.akrCode);
    // masterのデータを表示店舗設定がONの店舗で絞り込み
    paymentMasterState.payload.stores.forEach(store => {
      for (let i = 0; i < assignedStoresAkrcode.length; i++) {
        if (assignedStoresAkrcode[i] === store.akrCode) {
          assignedMasterStores.push(store);
          break;
        }
      }
    });

    if (state.payment.download.mode === 'transferData') {
      // 最小入金年月を返す
      return assignedMasterStores
        .map(store => store.minTransferMonth)
        .filter(month => month != null)
        .sort(function (a, b) {
          return a != null && b != null && a > b ? 1 : -1;
        })[0];
    } else {
      // 最小決済年月を返す
      return assignedMasterStores
        .map(store => store.minPaymentMonth)
        .filter(month => month != null)
        .sort(function (a, b) {
          return a != null && b != null && a > b ? 1 : -1;
        })[0];
    }
  } else {
    return null;
  }
};

const batchProcessedDateSelector: _InputSelector<BatchProcessedDate> = state => {
  return state.uiConfig.batchProcessedDate;
};

export const storesWithTransferInfoSummary: _OutputSelector<
  ReadonlyArray<(TransferInfoSummary | GopTransferInfoSummary) & StoresData>
> = createSelector(storesWithDataSelector, transferInfoSummarySelector, (userStores, transferInfoList) => {
  return joinStoreByAkrcode(userStores, transferInfoList);
});

//過去月から来月までの配列を返す
export const transferYearMonthList: _OutputSelector<ReadonlyArray<string>> = createSelector(
  minMonthSelector,
  batchProcessedDateSelector,
  getYearMonthFromPastToNextMonth
);
