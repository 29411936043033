// 訴求共通コンポーネントの1番目のヘッダー(○○とは)以下のコンテンツ部分

import React, { ComponentProps } from 'react';
import styled from 'styled-components';
import {
  airblue,
  black,
  blueBg,
  disabledTextColor,
  orangeBorder,
  lightOrange,
  uploadBorderColor,
  textLinkColor,
} from '../../../../constants/colors';
import LogoAirShift from '../../../../icons/LogoAirShift.svg';
import LogoOes from '../../../../icons/LogoAirOrder.svg';
import LogoRb from '../../../../icons/rbLogo.svg';
import LogoRegi from '../../../../icons/LogoAirRegi.svg';
import ShiftProduct from '../../../../icons/shift_product.png';
import OesProduct from '../../../../icons/oesProduct.png';
import OesStoreImage from '../../../../icons/oesStoreImage.svg';
import RbProduct from '../../../../icons/rbProduct.png';
import RegiProduct from '../../../../icons/regiProduct.png';
import LogoCard from '../../../../icons/LogoAirCard.svg';
import AirCardSample from '../../../../icons/AirCardSample.png';
import LogoCash from '../../../../icons/AirCashLogo.svg';
import AirCashSample from '../../../../icons/AirCashSample.png';
import AirPay from '../../../../icons/PaySample.png';
import AirPayLogo from '../../../../icons/airPayLogo.svg';
import Mpa from '../../../../icons/MpaSample.png';
import MpaLogo from '../../../../icons/MpaLogo.svg';
import Invoice from '../../../../icons/InvoiceSample.png';
import InvoiceLogo from '../../../../icons/AirInvoiceLogo.svg';
import { AppealModal } from '../AppealModal';
import { PRODUCT_TYPE } from '../../../../constants/appealModal';
import AppStoreIcon from '../../../../icons/AppStore.svg';
import {
  STORE_CALENDAR_INDICES_REGI_MODAL_BTN_LID_PARAMETER,
  STORE_CALENDAR_INDICES_LINKAGE_PAYQR_REGI_MODAL_APP_ADJUST_PARAMETER,
  STORE_CALENDAR_INDICES_REGI_MODAL_APP_ADJUST_PARAMETER,
} from '../../../../constants/externalLinkParameter';
import { dataImportSettingFaq, faqTop, pastDataImportFaq } from '../../../../constants/faqUrls';
import QrCode from '../../../../icons/QRCode.png';
import RegiQrCode from '../../../../icons/RegiQrCode.png';
import LinkagePayQrQrCode from '../../../../icons/LinkagePayQRQRCode.png';
import OpenLinkIcon from '../../../../icons/openLinkBlue.svg';
import { airRegiTop, appAdjust, noParamAppAdjust } from '../../../../constants/externalLink';
import { DAYOFWEEKHOURLY_MODAL_BTN_LID_PARAMETER } from '../../../../constants/externalLinkParameter';
import { genGaLog } from '../../../../gaLogger';

type Props = ComponentProps<typeof AppealModal>;

export const ProductDescriptionContent = (
  props: Pick<Props, 'productType' | 'tracker' | 'isRegiUse' | 'akrCode' | 'from'>
) => {
  const { productType, tracker, isRegiUse, akrCode, from } = props;
  switch (productType) {
    case PRODUCT_TYPE.MONTHLY_LOOKBACK_LABOR_COST_ANALYSIS_MODAL_SHIFT:
    case PRODUCT_TYPE.DAILYREPORT_INPUT_MODAL_SHIFT:
    case PRODUCT_TYPE.PRODUCTLIST_SHIFT:
    case PRODUCT_TYPE.STORE_CALENDAR_INDICES_SHIFT:
    case PRODUCT_TYPE.STORE_CALENDAR_INDICES_SHIFT_SUMMARY:
    case PRODUCT_TYPE.LABORCOSTANALYSIS_SHIFT:
    case PRODUCT_TYPE.DAILYREPORT_SHIFT:
    case PRODUCT_TYPE.REALTIME_SHIFT:
    case PRODUCT_TYPE.STORE_MONTHLY_INDICES_SHIFT:
    case PRODUCT_TYPE.STORE_INDICES_SHIFT:
    case PRODUCT_TYPE.DAILYDETAILPAST_SHIFT:
    case PRODUCT_TYPE.DAILYDETAILFUTURE_SHIFT:
      return (
        <React.Fragment>
          <ProductDescriptionContentWrapper>
            <ProductLogoSection widthSize={50}>
              <h4>やりとりも作成もラクになるシフト管理サービス</h4>
              <LogoWrapper>
                <LogoAirShift width={325} height={60} />
              </LogoWrapper>
              <OfferPriceWrapper fontSize={16}>
                <OfferPrice>提供価格：</OfferPrice>スタッフ1人あたり月額330円（税込）
              </OfferPriceWrapper>
              <Balloon>
                <h4>
                  利用開始月・翌月は<span>0</span>円でお試し
                </h4>
                <p>※お試し期間終了後、自動で課金されることはございません。</p>
              </Balloon>
            </ProductLogoSection>
            <ProductMockImageSection widthSize={50}>
              <img src={ShiftProduct} alt={'Airシフトのモック'} width={305} />
            </ProductMockImageSection>
          </ProductDescriptionContentWrapper>
          <ProductDescriptionList>
            <ProductDescriptionListWrapper>
              <ListHeaderWrapper>
                <ListHeader>シフト管理</ListHeader>
                <Label type="paid">有料</Label>
              </ListHeaderWrapper>
              <ul>
                <li>スタッフの希望はシフト表に自動反映</li>
                <li>急な出勤依頼や変更調整もチャットでやりとりが可能</li>
              </ul>
            </ProductDescriptionListWrapper>
            <ProductDescriptionListWrapper>
              <ListHeaderWrapper>
                <ListHeader>勤怠管理</ListHeader>
                <Label type="free">無料</Label>
              </ListHeaderWrapper>
              <ul>
                <li>タイムカード機能で出勤・退勤・休憩時刻を打刻</li>
                <li>労働時間の集計が可能</li>
              </ul>
            </ProductDescriptionListWrapper>
          </ProductDescriptionList>
        </React.Fragment>
      );
    case PRODUCT_TYPE.DAILYREPORT_CARD:
    case PRODUCT_TYPE.PRODUCTLIST_CARD:
    case PRODUCT_TYPE.STORE_CALENDAR_INDICES_CARD:
    case PRODUCT_TYPE.STORE_CALENDAR_INDICES_CARD_SUMMARY:
      return (
        <React.Fragment>
          <ProductDescriptionContentWrapper>
            <ProductLogoSection widthSize={50}>
              <h4>
                業界No.1<sup>※1</sup>の還元率1.5%<sup>※2</sup>
                <br />
                経費管理がラクでポイントもたまるビジネスカード
              </h4>
              <LogoWrapper>
                <LogoCard width={315} height={60} />
              </LogoWrapper>
              <OfferPriceWrapper fontSize={14}>
                <OfferPrice>提供価格：</OfferPrice>年会費 本会員5,500円（税込）、
                <br />
                使用者3,300円（税込）
              </OfferPriceWrapper>
              <Balloon>
                <h4>
                  新規入会特典<sup>※3</sup>：初年度年会費実質<span>0</span>円
                </h4>
                <p>詳しい特典内容はお申し込み時にご確認ください</p>
              </Balloon>
            </ProductLogoSection>
            <ProductMockImageSection widthSize={50}>
              <img src={AirCardSample} alt={'Airカードのサンプル'} width={319} />
            </ProductMockImageSection>
          </ProductDescriptionContentWrapper>
          <ProductAnnotations>
            <ul>
              <li>
                <div>※1</div>
                <div className="note">
                  調査内容：日本国内の法人組織または個人事業主とクレジットカード発行に関する契約の締結がされた上で、当該法人や従業員、個人事業主に対して発行するクレジットカード(招待制のものを除く)の、一般ショッピングにおけるポイント特典の還元率を調査
                  /
                  調査対象：主要なまとめサイト(6種)にてポイント特典の還元率が確認できた289種類のクレジットカード
                  / 調査期間：2023年12月1日〜2023年12月8日 / 調査機関による調査
                </div>
              </li>
              <li>
                <div>※2</div>
                <div className="note">
                  光熱費など還元率が異なる場合や、一部ポイント加算対象外となる場合があります。
                </div>
              </li>
              <li>
                <div>※3</div>
                <div className="note">「新規入会特典」は継続して実施する場合があります。</div>
              </li>
            </ul>
          </ProductAnnotations>
        </React.Fragment>
      );
    case PRODUCT_TYPE.PRODUCTLIST_OES:
    case PRODUCT_TYPE.STORE_CALENDAR_INDICES_OES:
    case PRODUCT_TYPE.LABORCOSTANALYSIS_OES:
    case PRODUCT_TYPE.REALTIME_OES:
    case PRODUCT_TYPE.STORE_MONTHLY_INDICES_OES:
    case PRODUCT_TYPE.STORE_INDICES_OES:
    case PRODUCT_TYPE.DAILYDETAILPAST_OES:
      return (
        <React.Fragment>
          <ProductDescriptionContentWrapper marginHorizontal={52}>
            <ProductLogoSection widthSize={54} marginTop={34} fontSize={18} lineHeightSize={1.8}>
              <h4>
                注文・調理・配膳をカンタンにする
                <br />
                オーダーエントリーシステム
              </h4>
              <LogoWrapper>
                <LogoOes width={427} height={56} />
              </LogoWrapper>
            </ProductLogoSection>
            <ProductMockImageSection widthSize={46}>
              <img src={OesProduct} alt={'oesのモック'} width={242} />
            </ProductMockImageSection>
          </ProductDescriptionContentWrapper>
          <OesImageWrapper>
            <OesStoreImage />
          </OesImageWrapper>
          <ProductDescriptionList>
            <ProductDescriptionListWrapper widthSize={32}>
              <ListHeaderWrapper>
                <ListHeader>スムーズな会計連携</ListHeader>
              </ListHeaderWrapper>
              <ul>
                <li>「Airレジ」と連携できるので、注文を打ち直す手間が不要</li>
                <li>軽減税率の計算など、会計時の操作もカンタン</li>
              </ul>
            </ProductDescriptionListWrapper>
            <ProductDescriptionListWrapper widthSize={30}>
              <ListHeaderWrapper>
                <ListHeader>お店にあった注文スタイル</ListHeader>
              </ListHeaderWrapper>
              <ul>
                <li>
                  スタッフが注文を取る「ハンディ」、お客様がスマホで注文する「セルフオーダー」を選択可能
                </li>
              </ul>
            </ProductDescriptionListWrapper>
            <ProductDescriptionListWrapper widthSize={30}>
              <ListHeaderWrapper>
                <ListHeader>キッチンモニターで調理・配膳</ListHeader>
              </ListHeaderWrapper>
              <ul>
                <li>キッチンモニターが調理配膳の遅れを可視化し、提供遅れを防止</li>
              </ul>
            </ProductDescriptionListWrapper>
          </ProductDescriptionList>
        </React.Fragment>
      );
    case PRODUCT_TYPE.PRODUCTLIST_RB:
    case PRODUCT_TYPE.STORE_CALENDAR_INDICES_RB:
    case PRODUCT_TYPE.REALTIME_RB:
    case PRODUCT_TYPE.DAILYDETAILPAST_RB:
    case PRODUCT_TYPE.DAILYDETAILFUTURE_RB:
      return (
        <React.Fragment>
          <ProductDescriptionContentWrapper>
            <ProductLogoSection widthSize={50} fontSize={18}>
              <h4>無料ですぐはじめられる予約台帳アプリ</h4>
              <LogoWrapper>
                <LogoRb width={230} height={74} />
              </LogoWrapper>
              <OfferPriceWrapper fontSize={14}>
                <OfferPrice>提供価格：</OfferPrice>基本利用料 無料
              </OfferPriceWrapper>
            </ProductLogoSection>
            <ProductMockImageSection widthSize={50}>
              <img src={RbProduct} alt={'rbのモック'} width={268} />
            </ProductMockImageSection>
          </ProductDescriptionContentWrapper>
          <ProductDescriptionList>
            <ProductDescriptionListWrapper widthSize={30}>
              <ListHeaderWrapper>
                <ListHeader>予約管理</ListHeader>
              </ListHeaderWrapper>
              <ul>
                <li>直感的な操作で予約の登録・変更が可能</li>
                <li>グルメサイト予約は自動的に反映</li>
              </ul>
            </ProductDescriptionListWrapper>
            <ProductDescriptionListWrapper widthSize={32}>
              <ListHeaderWrapper>
                <ListHeader>テーブル管理</ListHeader>
              </ListHeaderWrapper>
              <ul>
                <li>席の稼働状況を一目で把握</li>
                <li>次の予約や空席の状況がぱっとわかるので、席への案内がスムーズに</li>
              </ul>
            </ProductDescriptionListWrapper>
            <ProductDescriptionListWrapper widthSize={27}>
              <ListHeaderWrapper>
                <ListHeader>顧客管理</ListHeader>
              </ListHeaderWrapper>
              <ul>
                <li>店長の頭の中やノートにしかなかった情報を、お店の全員で共有</li>
              </ul>
            </ProductDescriptionListWrapper>
          </ProductDescriptionList>
        </React.Fragment>
      );
    case PRODUCT_TYPE.MENU_ANALYSIS_REGI:
    case PRODUCT_TYPE.PRODUCTLIST_REGI:
    case PRODUCT_TYPE.REALTIME_REGI:
    case PRODUCT_TYPE.DAILYDETAILPAST_REGI:
    case PRODUCT_TYPE.DAILYREPORT_EMPTYSTATE_REGI:
    case PRODUCT_TYPE.MONTHLYLOOKBACK_EMPTYSTATE_REGI:
      return (
        <React.Fragment>
          <ProductDescriptionContentWrapper>
            <ProductLogoSection widthSize={50} fontSize={18}>
              <h4>0円でカンタンに使えるPOSレジアプリ</h4>
              <LogoWrapper>
                <LogoRegi width={280} height={60} />
              </LogoWrapper>
              <OfferPriceWrapper fontSize={14}>
                <OfferPrice>提供価格：</OfferPrice>初期0円、月額0円
              </OfferPriceWrapper>
            </ProductLogoSection>
            <ProductMockImageSection widthSize={50}>
              <img src={RegiProduct} alt={'Airレジのモック'} width={286} />
            </ProductMockImageSection>
          </ProductDescriptionContentWrapper>
          <ProductDescriptionList>
            <ProductDescriptionListWrapper widthSize={30}>
              <ListHeaderWrapper>
                <ListHeader>会計</ListHeader>
              </ListHeaderWrapper>
              <ul>
                <li>誰でもカンタンに使える操作性</li>
                <li>「Airペイ」を一緒に使うと、金額再入力の手間がなく、よりスムーズに会計</li>
              </ul>
            </ProductDescriptionListWrapper>
            <ProductDescriptionListWrapper widthSize={32}>
              <ListHeaderWrapper>
                <ListHeader>他サービス連携</ListHeader>
              </ListHeaderWrapper>
              <ul>
                <li>会計ソフトとの連携で、日々の売上データを自動連携</li>
              </ul>
              <ul>
                <li>予約管理サービスなど会計ソフト以外にも様々なサービスと連携可能</li>
              </ul>
            </ProductDescriptionListWrapper>
            <ProductDescriptionListWrapper widthSize={29}>
              <ListHeaderWrapper>
                <ListHeader>業種に合わせた設定</ListHeader>
              </ListHeaderWrapper>
              <ul>
                <li>バーコードでの会計、在庫管理や顧客管理機能など、お店の運用に合わせて柔軟な設定が可能</li>
              </ul>
            </ProductDescriptionListWrapper>
          </ProductDescriptionList>
        </React.Fragment>
      );
    case PRODUCT_TYPE.DAYOFWEEKHOURLY_REGI_NOT_USE:
      return (
        <React.Fragment>
          <ProductDescriptionContentWrapper>
            <ProductLogoSection widthSize={50} fontSize={18}>
              <h4>0円でカンタンに使えるPOSレジアプリ</h4>
              <LogoWrapper>
                <LogoRegi width={280} height={60} />
              </LogoWrapper>
              <OfferPriceWrapper fontSize={14}>
                <OfferPrice>提供価格：</OfferPrice>初期0円、月額0円
              </OfferPriceWrapper>
              <StyledImg src={RegiProduct} alt="Airレジのモック" />
            </ProductLogoSection>
            <ProductMockImageSection widthSize={50}>
              <RightWrapper>
                <QrCodeWrapper>
                  <QrTitle>Airレジのアプリをダウンロード</QrTitle>
                  <QrLead>iPhoneまたはiPadでご利用いただけます。</QrLead>
                  <img src={QrCode} alt="AirレジアプリのQRコード" />
                  <AppStoreLink href={appAdjust} target="_blank">
                    <AppStoreIcon />
                  </AppStoreLink>
                </QrCodeWrapper>
                <QrTextLink
                  href={`${airRegiTop}?lid=${DAYOFWEEKHOURLY_MODAL_BTN_LID_PARAMETER}`}
                  target="_blank"
                  onClick={() => {
                    tracker != null &&
                      isRegiUse != null &&
                      tracker(_genDayofweekHourlyModalSubmitLog(isRegiUse));
                  }}
                >
                  Airレジの管理画面はこちら
                  <StyledOpenLinkIcon />
                </QrTextLink>
              </RightWrapper>
            </ProductMockImageSection>
          </ProductDescriptionContentWrapper>
          <ProductDescriptionList>
            <ProductDescriptionListWrapper widthSize={30}>
              <ListHeaderWrapper>
                <ListHeader>会計</ListHeader>
              </ListHeaderWrapper>
              <ul>
                <li>誰でもカンタンに使える操作性</li>
                <li>「Airペイ」を一緒に使うと、金額再入力の手間がなく、よりスムーズに会計</li>
              </ul>
            </ProductDescriptionListWrapper>
            <ProductDescriptionListWrapper widthSize={32}>
              <ListHeaderWrapper>
                <ListHeader>他サービス連携</ListHeader>
              </ListHeaderWrapper>
              <ul>
                <li>会計ソフトとの連携で、日々の売上データを自動連携</li>
              </ul>
              <ul>
                <li>予約管理サービスなど会計ソフト以外にも様々なサービスと連携可能</li>
              </ul>
            </ProductDescriptionListWrapper>
            <ProductDescriptionListWrapper widthSize={29}>
              <ListHeaderWrapper>
                <ListHeader>業種に合わせた設定</ListHeader>
              </ListHeaderWrapper>
              <ul>
                <li>バーコードでの会計、在庫管理や顧客管理機能など、お店の運用に合わせて柔軟な設定が可能</li>
              </ul>
            </ProductDescriptionListWrapper>
          </ProductDescriptionList>
        </React.Fragment>
      );
    case PRODUCT_TYPE.DAYOFWEEKHOURLY_REGI_USE:
      return (
        <React.Fragment>
          <ProductDescriptionContentWrapper>
            <ProductLogoSection widthSize={50} fontSize={18}>
              <LogoWrapper>
                <LogoRegi width={280} height={60} />
              </LogoWrapper>
              <StyledImg src={RegiProduct} alt="Airレジのモック" />
            </ProductLogoSection>
            <ProductMockImageSection widthSize={50}>
              <RightWrapper>
                <QrCodeWrapper>
                  <QrTitle>Airレジのアプリをダウンロード</QrTitle>
                  <QrLead>iPhoneまたはiPadでご利用いただけます。</QrLead>
                  <img src={QrCode} alt="AirレジアプリのQRコード" />
                  <AppStoreLink href={appAdjust} target="_blank">
                    <AppStoreIcon />
                  </AppStoreLink>
                </QrCodeWrapper>
                <QrTextLink
                  href={`${airRegiTop}?lid=${DAYOFWEEKHOURLY_MODAL_BTN_LID_PARAMETER}`}
                  target="_blank"
                  onClick={() => {
                    tracker != null &&
                      isRegiUse != null &&
                      tracker(_genDayofweekHourlyModalSubmitLog(isRegiUse));
                  }}
                >
                  Airレジの管理画面はこちら
                  <StyledOpenLinkIcon />
                </QrTextLink>
              </RightWrapper>
            </ProductMockImageSection>
          </ProductDescriptionContentWrapper>
          <ProductDescriptionList>
            <ProductDescriptionListWrapper widthSize={30}>
              <ListHeaderWrapper>
                <ListHeader>会計</ListHeader>
              </ListHeaderWrapper>
              <ul>
                <li>誰でもカンタンに使える操作性</li>
                <li>「Airペイ」を一緒に使うと、金額再入力の手間がなく、よりスムーズに会計</li>
              </ul>
            </ProductDescriptionListWrapper>
            <ProductDescriptionListWrapper widthSize={32}>
              <ListHeaderWrapper>
                <ListHeader>他サービス連携</ListHeader>
              </ListHeaderWrapper>
              <ul>
                <li>会計ソフトとの連携で、日々の売上データを自動連携</li>
              </ul>
              <ul>
                <li>予約管理サービスなど会計ソフト以外にも様々なサービスと連携可能</li>
              </ul>
            </ProductDescriptionListWrapper>
            <ProductDescriptionListWrapper widthSize={29}>
              <ListHeaderWrapper>
                <ListHeader>業種に合わせた設定</ListHeader>
              </ListHeaderWrapper>
              <ul>
                <li>バーコードでの会計、在庫管理や顧客管理機能など、お店の運用に合わせて柔軟な設定が可能</li>
              </ul>
            </ProductDescriptionListWrapper>
          </ProductDescriptionList>
        </React.Fragment>
      );
    case PRODUCT_TYPE.STORE_CALENDAR_INDICES_REGI:
    case PRODUCT_TYPE.STORE_CALENDAR_INDICES_REGI_PAYQR:
      return (
        <React.Fragment>
          <ProductDescriptionContentWrapper marginTop={32}>
            <ProductLogoSection widthSize={50} fontSize={18}>
              <h4>0円でカンタンに使えるPOSレジアプリ</h4>
              <LogoWrapper>
                <LogoRegi width={280} height={60} />
              </LogoWrapper>
              <OfferPriceWrapper fontSize={14}>
                <OfferPrice>提供価格：</OfferPrice>初期0円、月額0円
              </OfferPriceWrapper>
              <StyledImg src={RegiProduct} alt="Airレジのモック" />
            </ProductLogoSection>
            <ProductMockImageSection widthSize={50}>
              <RightWrapper>
                <QrCodeWrapper>
                  <QrTitle>Airレジのアプリをダウンロード</QrTitle>
                  <QrLead>
                    {productType === PRODUCT_TYPE.STORE_CALENDAR_INDICES_REGI_PAYQR
                      ? 'iPadまたはiPhoneでご利用いただけます。'
                      : 'iPhoneまたはiPadでご利用いただけます。'}
                  </QrLead>
                  <img
                    src={
                      productType === PRODUCT_TYPE.STORE_CALENDAR_INDICES_REGI_PAYQR
                        ? LinkagePayQrQrCode
                        : RegiQrCode
                    }
                    alt="AirレジアプリのQRコード"
                    width="100"
                  />
                  <AppStoreLink
                    href={`${noParamAppAdjust}${
                      productType === PRODUCT_TYPE.STORE_CALENDAR_INDICES_REGI_PAYQR
                        ? STORE_CALENDAR_INDICES_LINKAGE_PAYQR_REGI_MODAL_APP_ADJUST_PARAMETER
                        : STORE_CALENDAR_INDICES_REGI_MODAL_APP_ADJUST_PARAMETER
                    }`}
                    target="_blank"
                    onClick={() => {
                      tracker != null &&
                        isRegiUse != null &&
                        tracker(_genStoreCalendarRegiModalLog(isRegiUse));
                    }}
                  >
                    <AppStoreIcon />
                  </AppStoreLink>
                </QrCodeWrapper>
                <QrTextLink
                  href={`${airRegiTop}?lid=${STORE_CALENDAR_INDICES_REGI_MODAL_BTN_LID_PARAMETER}`}
                  target="_blank"
                  onClick={() => {
                    if (productType === PRODUCT_TYPE.STORE_CALENDAR_INDICES_REGI_PAYQR) {
                      tracker != null && tracker(_genClickAirRegiLinkLog(from, akrCode));
                    } else {
                      tracker != null &&
                        isRegiUse != null &&
                        tracker(_genStoreCalendarRegiModalLog(isRegiUse));
                    }
                  }}
                >
                  Airレジの管理画面はこちら
                  <StyledOpenLinkIcon />
                </QrTextLink>
              </RightWrapper>
            </ProductMockImageSection>
          </ProductDescriptionContentWrapper>
          <FaqLinkWrapper>
            <FaqText>
              {productType === PRODUCT_TYPE.STORE_CALENDAR_INDICES_REGI_PAYQR ? (
                <React.Fragment>
                  Airレジを利用しない場合は、日報やCSVで売上を入力することもできます。
                  <br />
                  詳しくはヘルプをご覧ください。
                </React.Fragment>
              ) : (
                'Airレジを利用していない場合の売上入力方法は、ヘルプをご覧ください。'
              )}
            </FaqText>
            <LinkWrapper>
              {productType === PRODUCT_TYPE.STORE_CALENDAR_INDICES_REGI_PAYQR ? (
                <FaqLink
                  href={dataImportSettingFaq}
                  target="_blank"
                  onClick={() => {
                    if (productType === PRODUCT_TYPE.STORE_CALENDAR_INDICES_REGI_PAYQR) {
                      tracker != null && tracker(_genClickDataImportSettingLinkLog(from, akrCode));
                    } else {
                      tracker != null &&
                        isRegiUse != null &&
                        tracker(_genStoreCalendarRegiModalLog(isRegiUse));
                    }
                  }}
                  productType={productType}
                >
                  売上取込設定の方法
                  <StyledOpenLinkIcon />
                </FaqLink>
              ) : (
                <React.Fragment>
                  <FaqLink
                    href={faqTop}
                    target="_blank"
                    onClick={() => {
                      tracker != null &&
                        isRegiUse != null &&
                        tracker(_genStoreCalendarRegiModalLog(isRegiUse));
                    }}
                  >
                    売上実績を日報から手入力する
                    <StyledOpenLinkIcon />
                  </FaqLink>
                  <FaqLink
                    href={pastDataImportFaq}
                    target="_blank"
                    onClick={() => {
                      tracker != null &&
                        isRegiUse != null &&
                        tracker(_genStoreCalendarRegiModalLog(isRegiUse));
                    }}
                  >
                    売上データをCSVで一括取込する
                    <StyledOpenLinkIcon />
                  </FaqLink>
                </React.Fragment>
              )}
            </LinkWrapper>
          </FaqLinkWrapper>
        </React.Fragment>
      );
    case PRODUCT_TYPE.STORE_CALENDAR_INDICES_CASH:
    case PRODUCT_TYPE.PRODUCTLIST_CASH:
      return (
        <React.Fragment>
          <ProductDescriptionContentWrapper>
            <ProductLogoSection widthSize={50} fontSize={18}>
              <h4>
                将来の売上を今のお金にかえる
                <br />
                資金調達サービス
              </h4>
              <LogoWrapper>
                <LogoCash width={315} height={60} />
              </LogoWrapper>
              <Balloon>
                <h4>
                  利用金額<span>1~500</span>万円
                </h4>
                <p>Airペイ利用状況などに応じて条件は随時見直されます</p>
              </Balloon>
            </ProductLogoSection>
            <ProductMockImageSection widthSize={50}>
              <img src={AirCashSample} alt={'Airキャッシュのサンプル'} width={325} />
            </ProductMockImageSection>
          </ProductDescriptionContentWrapper>
          <ProductDescriptionList>
            <ProductDescriptionListWrapper>
              <ListHeaderWrapper>
                <ListHeader>Airペイの将来の売上を予測したうえで資金提供</ListHeader>
              </ListHeaderWrapper>
              <ul>
                <li>「借入」ではなく、将来の売上の早期現金化</li>
                <li>支払いも売上に応じた定率引落しだから安心</li>
              </ul>
            </ProductDescriptionListWrapper>
            <ProductDescriptionSpan />
            <ProductDescriptionListWrapper>
              <ListHeaderWrapper>
                <ListHeader>カンタン2タップで申込み完了</ListHeader>
              </ListHeaderWrapper>
              <ul>
                <li>面倒な審査や書類提出は不要。2タップで申込み完了</li>
                <li>申込みから最短翌日の入金</li>
              </ul>
            </ProductDescriptionListWrapper>
          </ProductDescriptionList>
        </React.Fragment>
      );
    case PRODUCT_TYPE.PRODUCTLIST_PAY:
      return (
        <React.Fragment>
          <ProductDescriptionContentWrapper>
            <ProductLogoSection widthSize={50} fontSize={18} marginTop={20}>
              <h4>
                カード・電マネ・QR・ポイントも使える
                <br />
                お店の決済サービス
              </h4>
              <LogoWrapper>
                <AirPayLogo width={261} height={60} />
              </LogoWrapper>
              <OfferPriceWrapper fontSize={16}>
                <OfferPrice>提供価格：</OfferPrice>月額固定費 0円
              </OfferPriceWrapper>
            </ProductLogoSection>
            <ProductMockImageSection widthSize={50}>
              <img src={AirPay} alt={'AirPayのサンプル'} width={326} />
            </ProductMockImageSection>
          </ProductDescriptionContentWrapper>
          <ProductDescriptionList>
            <ProductDescriptionListWrapper widthSize={30}>
              <ListHeaderWrapper>
                <ListHeader>業界最安水準の手数料</ListHeader>
              </ListHeaderWrapper>
              <ul>
                <li>決済手数料率はお店の規模や業種に問わず、どの決済方法も業界最安水準の3.24%</li>
              </ul>
            </ProductDescriptionListWrapper>
            <ProductDescriptionListWrapper widthSize={32}>
              <ListHeaderWrapper>
                <ListHeader>さまざまな決済に対応</ListHeader>
              </ListHeaderWrapper>
              <ul>
                <li>
                  主要クレジットカード決済、交通系電子マネーのほかiD、QUICPay、Apple
                  Pay、UnionPay（銀聯）での決済に対応
                </li>
              </ul>
            </ProductDescriptionListWrapper>
            <ProductDescriptionListWrapper widthSize={32}>
              <ListHeaderWrapper>
                <ListHeader>振込手数料・固定費が無料</ListHeader>
              </ListHeaderWrapper>
              <ul>
                <li>振込手数料は全ての銀行で0円、月額の固定費0円</li>
                <li>
                  新しい銀行口座の開設も不要
                  <br />
                  <Annotation> ※ゆうちょ銀行は利用不可</Annotation>
                </li>
              </ul>
            </ProductDescriptionListWrapper>
          </ProductDescriptionList>
        </React.Fragment>
      );
    case PRODUCT_TYPE.PRODUCTLIST_MPA:
      return (
        <React.Fragment>
          <ProductDescriptionContentWrapper>
            <ProductLogoSection widthSize={50} fontSize={18} marginTop={36}>
              <h4>QR決済の導入なら</h4>
              <LogoWrapper>
                <MpaLogo width={353} height={60} />
              </LogoWrapper>
              <OfferPriceWrapper fontSize={16}>
                <OfferPrice>提供価格：</OfferPrice>月額固定費 0円
              </OfferPriceWrapper>
            </ProductLogoSection>
            <ProductMockImageSection widthSize={50}>
              <img src={Mpa} alt={'Airペイ QRのサンプル'} width={302} />
            </ProductMockImageSection>
          </ProductDescriptionContentWrapper>
          <ProductDescriptionList>
            <ProductDescriptionListWrapper widthSize={30}>
              <ListHeaderWrapper>
                <ListHeader>QR決済をこれ一つで対応</ListHeader>
              </ListHeaderWrapper>
              <ul>
                <li>
                  国内サービスのd払いやPayPayをはじめ、中国最大級の決済サービス、Alipay+、WeChat Pay等に対応
                </li>
              </ul>
            </ProductDescriptionListWrapper>
            <ProductDescriptionListWrapper widthSize={32}>
              <ListHeaderWrapper>
                <ListHeader>導入費用・月額固定費 0円</ListHeader>
              </ListHeaderWrapper>
              <ul>
                <li>
                  かかる費用は利用額に応じた決済手数料 0.99%または2.95%（税抜）のみ
                  <br />
                  <Annotation>
                    ※税込価格は、COIN+（1.08%）、他QR決済（3.24%）となります（小数点以下第2位まで表記）
                  </Annotation>
                </li>
              </ul>
            </ProductDescriptionListWrapper>
            <ProductDescriptionListWrapper widthSize={32}>
              <ListHeaderWrapper>
                <ListHeader>特別な機材は不要</ListHeader>
              </ListHeaderWrapper>
              <ul>
                <li>お手元のiPad または iPhoneで使用可能</li>
              </ul>
            </ProductDescriptionListWrapper>
          </ProductDescriptionList>
        </React.Fragment>
      );
    case PRODUCT_TYPE.PRODUCTLIST_INVOICE:
      return (
        <React.Fragment>
          <ProductDescriptionContentWrapper>
            <ProductLogoSection widthSize={50} marginTop={16}>
              <h4>振込も保管もラクになる請求書支払アプリ</h4>
              <LogoWrapper>
                <InvoiceLogo width={356} height={56} />
              </LogoWrapper>
              <OfferPriceWrapper fontSize={16}>
                <OfferPrice>提供価格：</OfferPrice>1店舗あたり月額 5,500円（税込）
              </OfferPriceWrapper>
              <Balloon>
                <h4>
                  利用開始月・翌月は<span>0</span>円でお試し
                </h4>
                <p>※お試し期間終了後、自動で課金されることはございません。</p>
              </Balloon>
            </ProductLogoSection>
            <ProductMockImageSection widthSize={50}>
              <img src={Invoice} alt={'Airインボイスのサンプル'} width={223} />
            </ProductMockImageSection>
          </ProductDescriptionContentWrapper>
          <ProductDescriptionList>
            <ProductDescriptionListWrapper>
              <ListHeaderWrapper>
                <ListHeader>請求書の振込手数料 一律143円</ListHeader>
              </ListHeaderWrapper>
              <ul>
                <li>いずれの銀行宛の振り込みでも手数料143円</li>
                <li>手数料を気にして銀行口座を使い分ける必要なし</li>
              </ul>
            </ProductDescriptionListWrapper>
            <ProductDescriptionListWrapper>
              <ListHeaderWrapper>
                <ListHeader>手作業を大幅削減</ListHeader>
              </ListHeaderWrapper>
              <ul>
                <li>請求書の写真を撮るだけで金額や振込先が自動でデータ化</li>
                <li>複数の請求書をまとめて振り込める</li>
                <li>【全事業者対応必須】電子帳簿保存法にも対応できる</li>
              </ul>
            </ProductDescriptionListWrapper>
          </ProductDescriptionList>
        </React.Fragment>
      );
    default:
      return null;
  }
};

const _genDayofweekHourlyModalSubmitLog = (isRegiUse: boolean) => {
  return genGaLog(
    'dayofweek_hourly',
    'dayofweek_hourly_panel_arg_modal',
    'submit',
    {},
    { type: ['arg'], cu_status: isRegiUse ? 'use' : 'not_use' },
    'click'
  );
};

const ProductDescriptionContentWrapper = styled.div<{ marginTop?: number; marginHorizontal?: number }>`
  display: flex;
  margin: ${props => (props.marginTop != null ? `${props.marginTop}px` : '40px')}
    ${props => (props.marginHorizontal != null ? `${props.marginHorizontal}px` : '32px')} 0;
`;

const ProductLogoSection = styled.div<{
  widthSize: number;
  fontSize?: number;
  marginTop?: number;
  lineHeightSize?: number;
}>`
  display: flex;
  flex-direction: column;
  margin-top: ${props => `${props.marginTop}px`};
  width: ${props => `${props.widthSize}%`};
  > h4 {
    font-size: ${props => (props.fontSize != null ? `${props.fontSize}px` : '16px')};
    font-weight: 600;
    text-align: center;
    color: ${airblue};
    ${props => props.lineHeightSize != null && `line-height: ${props.lineHeightSize};`}
    font-family: 'Hiragino Kaku Gothic Pro', sans-serif;
  }
  ${props => props.marginTop != null && `margin-top: ${props.marginTop}px;`}
`;

const LogoWrapper = styled.div`
  text-align: center;
  margin-top: 12px;
`;

const OfferPriceWrapper = styled.p<{ fontSize: number }>`
  font-size: ${props => `${props.fontSize}px`};
  text-align: center;
  margin-top: 10px;
  line-height: 1.5;
  > div {
    font-size: 12px;
    margin-top: 2px;
  }
`;

const OfferPrice = styled.span`
  color: ${disabledTextColor};
`;

const ProductMockImageSection = styled.div<{ widthSize: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => `${props.widthSize}%`};
`;

const Balloon = styled.div`
  width: 340px;
  position: relative;
  display: inline-block;
  background: ${blueBg};
  border: solid 1px ${airblue};
  border-radius: 4px;
  margin: 16px auto 0;
  padding: 6px 0 12px;
  > h4 {
    font-size: 16px;
    font-weight: 600;
    color: ${airblue};
    text-align: center;
    > span {
      font-size: 28px;
    }
    > sup {
      font-size: 10px;
    }
  }
  > p {
    font-size: 11px;
    color: ${black};
    text-align: center;
  }
  :before {
    content: '';
    position: absolute;
    top: -22px;
    left: 50%;
    margin-left: -15px;
    z-index: 4;
    border: 12px solid transparent;
    border-bottom: 12px solid ${blueBg};
  }
  :after {
    content: '';
    position: absolute;
    top: -21px;
    left: 50%;
    margin-left: -13px;
    border: 10px solid transparent;
    border-bottom: 10px solid ${airblue};
    z-index: 2;
  }
`;

const ProductDescriptionList = styled.div`
  display: flex;
  margin: 40px 32px 0;
  justify-content: space-between;
`;

const ProductDescriptionListWrapper = styled.div<{ widthSize?: number }>`
  width: ${props => (props.widthSize != null ? `${props.widthSize}%` : '50%')};
  > ul {
    margin-top: ${props => (props.widthSize != null ? '-6px' : '16px')};
    ${props => (props.widthSize != null ? 'margin-left: 22px' : 'list-style-position: inside')};
    > li {
      font-size: 14px;
      padding-left: 6px;
      margin-top: 12px;
      font-family: 'Hiragino Kaku Gothic Pro', sans-serif;
    }
  }
`;

const ListHeaderWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
`;

const ListHeader = styled.h4`
  font-size: 16px;
  font-weight: 600;
  font-family: 'Hiragino Kaku Gothic Pro', sans-serif;
`;

const Label = styled.span<{ type: string }>`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  border-radius: 2px;
  height: 15px;
  padding: 2px 4px;
  margin-top: 3px;
  ${props =>
    props.type === 'paid'
      ? `
      color: ${airblue};
      border: 1px solid ${airblue};
      background-color: ${blueBg};
      `
      : `
      color: ${orangeBorder};
      border: 1px solid ${orangeBorder};
      background-color: ${lightOrange}
      `}
`;

const ProductAnnotations = styled.div`
  display: flex;
  margin: 32px 32px 0;
  > ul {
    display: table;
    list-style: none;
    font-size: 11px;
    color: ${black};
    > li {
      > div {
        display: table-cell;
      }
      > div.note {
        padding-left: 11px;
      }
    }
  }
`;

const OesImageWrapper = styled.div`
  margin: 34px 0 -24px;
  text-align: center;
`;

const RightWrapper = styled.div`
  padding-right: 36px;
  display: flex;
  flex-direction: column;
  align-items: end;
`;

const QrCodeWrapper = styled.div`
  width: 320px;
  height: 284px;
  border: 1px solid ${uploadBorderColor};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 15px;
`;

const QrTitle = styled.div`
  color: ${black};
  font-size: 16px;
  font-weight: 600;
`;

const QrLead = styled.div`
  color: ${black};
  font-size: 12px;
  margin-bottom: 27px;
`;

const QrTextLink = styled.a`
  color: ${textLinkColor};
  font-size: 16px;
  margin-top: 14px;
  cursor: pointer;
`;

const StyledImg = styled.img`
  margin: 22px auto 0;
  width: 276px;
`;

const StyledOpenLinkIcon = styled(OpenLinkIcon)`
  margin-left: 6px;
`;

const AppStoreLink = styled.a`
  margin-top: 20px;
`;

const FaqLinkWrapper = styled.div`
  border-top: solid 1px ${uploadBorderColor};
  width: 760px;
  margin: 42px auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 28px;
`;

const FaqText = styled.div`
  color: ${black};
  font-size: 14px;
  text-align: center;
`;

const LinkWrapper = styled.div`
  display: flex;
  margin-top: 14px;
`;

const FaqLink = styled.a<{ productType?: keyof typeof PRODUCT_TYPE }>`
  color: ${textLinkColor};
  font-size: 14px;
  :first-child {
    ${props => props.productType !== PRODUCT_TYPE.STORE_CALENDAR_INDICES_REGI_PAYQR && 'margin-right: 32px;'}
  }
`;

const ProductDescriptionSpan = styled.span`
  width: 72px;
`;

const Annotation = styled.span`
  font-size: 11px;
`;

const _genStoreCalendarRegiModalLog = (isRegiUse: boolean, akrCode?: string) => {
  return genGaLog(
    'store_indices',
    'store_indices_argpopup_modal',
    'submit',
    {},
    {
      type: ['arg'],
      cu_status: isRegiUse ? 'use' : 'not_use',
    },
    'click',
    akrCode
  );
};

const _genClickAirRegiLinkLog = (from?: 'calendar' | 'daily', akrCode?: string) => {
  return genGaLog(
    'store_indices',
    'store_indices_argappeal_modal_arglink',
    'submit',
    {},
    { type: '[apy]', akr_code: akrCode, selected_indices_type: from },
    'click'
  );
};

const _genClickDataImportSettingLinkLog = (from?: 'calendar' | 'daily', akrCode?: string) => {
  return genGaLog(
    'store_indices',
    'store_indices_argappeal_modal',
    'submit',
    {},
    { type: '[apy]', akr_code: akrCode, selected_indices_type: from },
    'click'
  );
};
