import axios, { AxiosResponse } from 'axios';
import { getAPIURL } from '../helpers/stringHelper';
import { MapiResponse, ApiPromise, ErrorType, PostResponse } from '../typedef/api/Utility';
import { ShiftImportSettingForm, ShiftImportSettingRequest } from '../typedef/api/ShiftImportSetting';

const ShiftImportSettingAPI = {
  fetchShiftImportSetting: (akrCode: string): ApiPromise<ShiftImportSettingForm> =>
    axios
      .get(getAPIURL('core/v1/stores/config/shift_import_setting'), { params: { akrCode } })
      .then((res: AxiosResponse<MapiResponse<ShiftImportSettingForm>>) => ({
        payload: res.data.result,
      }))
      .catch((error: ErrorType) => ({
        error,
      })),

  postShiftImportSetting: (req: ShiftImportSettingRequest): ApiPromise<PostResponse> =>
    axios
      .post(getAPIURL('core/v1/stores/config/shift_import_setting'), req)
      .then((res: AxiosResponse<MapiResponse<PostResponse>>) => ({
        payload: res.data.result,
      }))
      .catch((error: ErrorType) => ({
        error,
      })),
};

export default ShiftImportSettingAPI;
