import { fork, call, put, takeLatest, select, all, take } from 'redux-saga/effects';
import { ApiCallEffectResult, ApiState, PostResponse } from '../typedef/api/Utility';
import InvoiceCostListAPI from '../services/invoiceCostListAPI';
import {
  types,
  actions as invoiceCostListAction,
  StartFetchBillingInfoAction,
  StartPostCostCategoryTypeSettingAction,
} from '../modules/invoiceCostList/invoiceCostList';
import { UploadYearMonthResponse } from '../typedef/api/InvoiceCostList/InvoiceCostList';
import { BillingInfoResponse, BillingInfoList, StorageObj } from '../typedef/api/InvoiceCostList/BillingInfo';
import { State as RootState } from '../modules';
import { actions as invoiceCostListUiActions } from '../modules/invoiceCostList/ui';
import { StoresData } from '../modules/user';
import { SELECTED_INVOICE_TYPE_KEY_NAME } from '../constants/LocalStorage';
import { BatchProcessedDate } from '../typedef/BatchProcessedDate';
import { TableProperties } from '../typedef/api/InvoiceCostList/TableProperties';
import { actions as uiConfigActions } from '../modules/uiConfig';
import { assignedStoresSelector } from '../selectors/userDataSelector';
import { formatter, parser } from '../helpers/mclDate';
import { getYearMonthKeyAndValue } from '../helpers/mclDateHelper';

export function* initialFetchInvoiceCostListSaga() {
  while (true) {
    yield all([
      take(types.SUCCESS_FETCH_UPLOAD_YEAR_MONTH_LIST),
      take(types.SUCCESS_FETCH_BILLING_INFO),
      take(types.INITIAL_FETCH),
    ]);

    const assignedStores: ReadonlyArray<StoresData> = yield select((state: RootState) =>
      assignedStoresSelector(state)
    );

    const uploadYearMonthInvoiceListResponse: ApiState<UploadYearMonthResponse> = yield select(
      (state: RootState) => state.invoiceCostList.invoiceCostList.uploadYearMonthList
    );

    const billingInfoInvoiceListResponse: ApiState<BillingInfoResponse> = yield select(
      (state: RootState) => state.invoiceCostList.invoiceCostList.billingInfo
    );

    const clientUserId = yield select((state: RootState) => state.user.data?.clientUserId);

    if (
      uploadYearMonthInvoiceListResponse.type === 'API_STATE_COMPLETED' &&
      billingInfoInvoiceListResponse.type === 'API_STATE_COMPLETED'
    ) {
      const uploadYearMonthInvoiceList = uploadYearMonthInvoiceListResponse.payload;
      const yearMonthList = uploadYearMonthInvoiceList.selectItemInfo.uploadYearMonthList;
      yield put(invoiceCostListUiActions.setYearMonthList(getYearMonthKeyAndValue(yearMonthList)));
      const billingInfoList: Array<BillingInfoList & { storeName?: string }> = [];
      const billingInfoInvoiceList = billingInfoInvoiceListResponse.payload;
      billingInfoInvoiceList.invoiceInfo.invoiceInfoList.forEach(billingInfo => {
        let storeName: string | undefined;
        if (billingInfo.akrCode != null) {
          //インボイス情報のakrCodeから店舗名を取得して設定する
          assignedStores.forEach(store => {
            if (store.akrCode === billingInfo.akrCode) {
              storeName = store.storeName;
            }
          });
        }
        billingInfoList.push({ ...billingInfo, storeName: storeName });
      });
      yield put(invoiceCostListUiActions.setInvoiceInfoList(billingInfoList));

      //検索日付が設定されていない場合にバッチ日付を検索日に設定する
      let selectDate: string = yield select((state: RootState) => state.invoiceCostList.ui.selectDate);
      if (selectDate == null) {
        const batchDate: BatchProcessedDate = yield select(
          (state: RootState) => state.uiConfig.batchProcessedDate
        );
        selectDate = parser.fromDateObject(batchDate).format(formatter.mapiYearMonth);
        yield put(invoiceCostListUiActions.selectDate(selectDate));
      }

      //ローカルストレージから選択中の店舗一覧情報を取得する
      let localStorageAkrCodes = new Set<string>();
      const json = localStorage.getItem(SELECTED_INVOICE_TYPE_KEY_NAME);
      if (
        json != null &&
        clientUserId != null &&
        JSON.parse(json).some(item => item.clientUserId === clientUserId)
      ) {
        const array: StorageObj = JSON.parse(json).find(item => item.clientUserId === clientUserId);
        localStorageAkrCodes = new Set(array.selectedStores);
      }

      const selectBoxStores = assignedStores.map(store => {
        return { storeName: store.storeName, akrCode: store.akrCode };
      });

      //店舗一覧に「店舗未選択」を追加する
      const selectBoxStoreAkrCodes = selectBoxStores.map(store => store.akrCode);
      const addedSelectBoxStores = [{ storeName: '店舗未選択', akrCode: '店舗未選択' }, ...selectBoxStores];
      yield put(invoiceCostListUiActions.setSelectBoxStores(addedSelectBoxStores));

      //選択中の店舗一覧をセットする(選択中店舗がローカルストレージが保存されていればそちらをセットする)
      const addedAkrCodes = ['店舗未選択', ...selectBoxStoreAkrCodes];
      const hasLocalStorage = localStorageAkrCodes.size > 0;
      hasLocalStorage
        ? yield put(invoiceCostListUiActions.setAkrCode(localStorageAkrCodes))
        : yield put(invoiceCostListUiActions.setAkrCode(new Set(addedAkrCodes)));
    }
  }
}

function* uploadYearMonthListSaga() {
  yield takeLatest(types.START_FETCH_UPLOAD_YEAR_MONTH_LIST, fetchUploadYearMonthList);
}

export function* fetchUploadYearMonthList() {
  const { payload, error }: ApiCallEffectResult<UploadYearMonthResponse> = yield call(
    InvoiceCostListAPI.fetchUploadYearMonthList
  );

  if (error) {
    yield put(invoiceCostListAction.failFetchUploadYearMonth(error));
  } else if (payload) {
    yield put(invoiceCostListAction.successFetchUploadYearMonth(payload));
  }
}

function* billingInfoSaga() {
  yield takeLatest(types.START_FETCH_BILLING_INFO, (action: StartFetchBillingInfoAction) =>
    fetchBillingInfo(action)
  );
}

export function* fetchBillingInfo(action: StartFetchBillingInfoAction) {
  const { payload, error }: ApiCallEffectResult<BillingInfoResponse> = yield call(
    InvoiceCostListAPI.fetchBillingInfo,
    action.payload
  );

  if (error) {
    yield put(invoiceCostListAction.failFetchBillingInfo(error));
  } else if (payload) {
    yield put(invoiceCostListAction.successFetchBillingInfo(payload));
  }
}

function* postCostCategoryTypeSettingSaga() {
  yield takeLatest(
    types.START_POST_COST_CATEGORY_TYPE_SETTING,
    (action: StartPostCostCategoryTypeSettingAction) => postCostCategoryTypeSetting(action)
  );
}

export function* postCostCategoryTypeSetting(action: StartPostCostCategoryTypeSettingAction) {
  const { payload, error }: ApiCallEffectResult<PostResponse> = yield call(
    InvoiceCostListAPI.postCostCategoryTypeSetting,
    action.payload
  );

  if (error) {
    yield put(
      uiConfigActions.showCommonDialog({
        title: '保存に失敗しました',
        message:
          'サーバーで問題が発生しました。お手数ですが、しばらく経ってから再度お試しください。\n\n何度も発生する場合は、ヘルプからお問い合わせください。',
      })
    );

    yield put(invoiceCostListAction.failPostCostCategoryTypeSetting(error));
  } else if (payload) {
    //保存後、一括変更、絞り込みフィルターはリセットを行う
    yield put(invoiceCostListUiActions.resetBulkChangeState());
    const tableProperties: TableProperties = yield select(
      (state: RootState) => state.invoiceCostList.invoiceCostList.tableProperties
    );
    yield put(
      invoiceCostListAction.setTableProperties({
        ...tableProperties,
        uncategorizedFilter: false,
        aggregateFilter: false,
      })
    );

    yield put(invoiceCostListAction.successPostCostCategoryTypeSetting());
    const selectDate: string = yield select((state: RootState) => state.invoiceCostList.ui.selectDate);
    const akrCodes: Set<string> = yield select((state: RootState) => state.invoiceCostList.ui.akrCodes);
    const excludedStoreUnspecifiedakrCodes = Array.from(akrCodes).filter(akrCode => akrCode !== '店舗未選択');
    const isStoreUnspecifiedInvoiceIncluded = Array.from(akrCodes).some(akrCode => akrCode === '店舗未選択');
    yield put(
      invoiceCostListAction.startFetchBillingInfo({
        uploadedYearMonth: selectDate,
        akrCodes:
          excludedStoreUnspecifiedakrCodes.length !== 0 ? Array.from(excludedStoreUnspecifiedakrCodes) : null,
        isStoreUnspecifiedInvoiceIncluded: isStoreUnspecifiedInvoiceIncluded,
      })
    );
    yield put(uiConfigActions.showCommonToast('保存しました'));
  }
}

export default function* invoiceCostListSaga() {
  yield fork(initialFetchInvoiceCostListSaga);
  yield fork(billingInfoSaga);
  yield fork(uploadYearMonthListSaga);
  yield fork(postCostCategoryTypeSettingSaga);
}
