import * as React from 'react';
import styled from 'styled-components';
import { ModalPortal } from '../../../components/common/molecules/ModalPortal';
import { State as ReduxState } from '../../../modules';
import { actions as csvAction } from '../../../modules/allMenu/csvDownload';
import {
  airlightblue,
  uploadBorderColor,
  airverylightblue,
  white,
  textLinkColor,
  lightgray,
} from '../../../constants/colors';
import InfoIcon from '../../../icons/info.svg';
import OpenLinkBlue from '../../../icons/openLinkBlue.svg';
import DownloadIcon from '../../../icons/download.svg';
import { connect } from 'react-redux';
import { track } from '../../../modules/logging';
import { genGaLog } from '../../../gaLogger';
import { bindActionCreators, Dispatch, Action } from 'redux';
import { settingPredictTagFaq } from '../../../constants/faqUrls';

type State = {
  isModalOpen: boolean;
};

type DispatchProps = {
  readonly logger: typeof track;
  readonly startMenuDownload: typeof csvAction.startMenuDownload;
};

type StateProps = {
  readonly csvLoadStatus: 'idle' | 'loading' | 'loaded' | 'error';
  readonly tableLength: number;
  readonly includesUndefinedCategory: boolean;
  readonly selectedLunchDinner: Set<string>;
  readonly includesFreeMenu: boolean;
};

type Props = Readonly<{} & DispatchProps & StateProps>;

class MenuTableTopArea extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isModalOpen: false,
    };
  }

  _renderModal = () => {
    if (this.state.isModalOpen) {
      return (
        <ModalPortal
          title="ABC分析表の見かた"
          onClose={() =>
            this.setState({
              isModalOpen: false,
            })
          }
          isOutsideClickClose={true}
          constantWidth={682}
        >
          <ModalWrapper>
            <ModalTitle>ABC分析とは</ModalTitle>
            <ModalText>
              商品の出数・売上・粗利構成比を大きいものから順に足していき、70％に達するまでの商品群をAランク、70〜90％をBランク、90％〜をCランクとします。
            </ModalText>
            <ModalText>
              ABC分析によって商品の現状を認識し、Aランク商品（売れ筋商品）を大きく目立たせたり、Cランク商品（死筋商品）は思い切ってラインナップから外すなど、グループ分けされた商品ごとに異なる販売戦略をたてれば、売上を改善することができるようになります。
            </ModalText>
            <ModalUl>
              <ModalLi>各構成比のタイトル行をクリックすると、その構成比順に並び替えができます。</ModalLi>
              <ModalLi>
                同じ商品名でも、分析タググループやカテゴリーが異なる場合は別の行に分けて表示されます。
              </ModalLi>
              <PredictLi />
              <ModalLi>
                粗利は、Airレジで設定された原価を元に算出しています。設定していない場合、原価¥0として計算されます。
              </ModalLi>
            </ModalUl>
          </ModalWrapper>
        </ModalPortal>
      );
    }

    return null;
  };

  _handleCsvDownload = () => {
    const { selectedLunchDinner, includesUndefinedCategory, includesFreeMenu, logger } = this.props;
    logger(
      _genClickCsvDownloadedLog(
        {
          lunchDinner: Array.from(selectedLunchDinner),
          includesUndefinedCategory,
          includesFreeMenu,
        },
        'download_csv'
      )
    );
    this.props.startMenuDownload();
  };

  render() {
    return (
      <React.Fragment>
        <TableTop>
          <TableTopLeft>
            <Infomation
              onClick={() => {
                this.setState({
                  isModalOpen: true,
                });
                this.props.logger(_genOpenModalMountLog());
              }}
              id="menu_analysis_how_to_see_link"
            >
              <InfoWrapper>
                <InfoIcon />
                <InfoTitle>ABC分析表の見かた</InfoTitle>
              </InfoWrapper>
            </Infomation>
            <ABCPanelWrapper>
              <ABCPanel abc="A" />
              <ABCPanel abc="B" />
              <ABCPanel abc="C" />
            </ABCPanelWrapper>
          </TableTopLeft>
          <CsvDownload
            onClick={this._handleCsvDownload}
            disabled={this.props.tableLength === 0 || this.props.csvLoadStatus === 'loading'}
          />
        </TableTop>
        {this._renderModal()}
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
  return {
    ...bindActionCreators(
      {
        logger: track,
        startMenuDownload: csvAction.startMenuDownload,
      },
      dispatch
    ),
  };
};

const mapStateToProps = (state: ReduxState): StateProps => {
  return {
    csvLoadStatus: state.allMenu.csvDownload.loadStatus.type,
    tableLength: state.allMenu.search.tableProperties.tableLength,
    includesUndefinedCategory: state.allMenu.form.includesUndefinedCategory,
    selectedLunchDinner: state.allMenu.form.selectedLunchDinner,
    includesFreeMenu: state.allMenu.form.includesFreeMenu,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuTableTopArea);

const _genOpenModalMountLog = () => {
  return genGaLog('menu_analysis', 'all_menu_form', 'open_how_to_abc_analysis', {}, {}, 'click');
};

const _genClickCsvDownloadedLog = (params: object, func: string) => {
  return genGaLog('menu_analysis', 'all_menu_form', func, params, {}, 'click');
};

type ABCType = 'A' | 'B' | 'C';

const ABCPanel = ({ abc }: { abc: ABCType }) => {
  return <ABCPanelContent abc={abc}>{`${abc}ランク商品`}</ABCPanelContent>;
};

const CsvDownload = ({ onClick, disabled }: { onClick: () => void; disabled?: boolean }) => {
  if (disabled === true) {
    return (
      <CsvDownloadDisabledWrapper id="menu_analysis_csv_download_button">
        <CsvDownloadIcon />
        <CsvDownloadText>CSVのダウンロード</CsvDownloadText>
      </CsvDownloadDisabledWrapper>
    );
  } else {
    return (
      <CsvDownloadWrapper onClick={onClick} id="menu_analysis_csv_download_button">
        <CsvDownloadIcon />
        <CsvDownloadText>CSVのダウンロード</CsvDownloadText>
      </CsvDownloadWrapper>
    );
  }
};

// marker部分に予測タグも付くため別コンポーネントとして作成
const PredictLi = () => {
  return (
    <ModalLi>
      <PredictLiDetailWrapper>
        <PredictIconWrapper>
          <PredictIcon>予測</PredictIcon>
        </PredictIconWrapper>
        <span>
          ＝分析タグが設定されていないため、AIが商品名から自動で推定し、フードまたはドリンクに振り分けている商品です。正確に分析するためには、Airレジバックオフィスで分析タグを設定してください。
          <PredictSettingLink href={settingPredictTagFaq} target={'_blank'} rel={'noopener noreferrer'}>
            分析タグの設定方法はこちら
            <OpenLinkBlueIcon />
          </PredictSettingLink>
        </span>
      </PredictLiDetailWrapper>
    </ModalLi>
  );
};

const TableTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 16px;
`;
const TableTopLeft = styled.div`
  display: flex;
  align-items: center;
`;

const ABCPanelWrapper = styled.div`
  display: flex;
`;

const ABCPanelContent = styled.div<{ abc: ABCType }>`
  margin-left: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 4px 2px 4px;
  font-size: 12px;
  ${props => props.abc === 'C' && `border: 1px solid ${lightgray};`}
  background-color: ${props => {
    switch (props.abc) {
      case 'A':
        return airlightblue;
      case 'B':
        return airverylightblue;
      case 'C':
        return white;
      default:
        return white;
    }
  }};
`;

const Infomation = styled.div`
  color: ${textLinkColor};
  font-size: 14px;
  cursor: pointer;
`;

const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const InfoTitle = styled.p`
  margin-left: 6px;
`;

const CsvDownloadDisabledWrapper = styled.div`
  display: flex;
  align-items: center;
  opacity: 0.3;
  &:hover {
    cursor: default;
  }
`;

const CsvDownloadWrapper = styled.div`
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

const CsvDownloadIcon = styled(DownloadIcon)`
  margin-right: 6px;
`;

const CsvDownloadText = styled.p`
  font-size: 14px;
  color: ${textLinkColor};
`;

const OpenLinkBlueIcon = styled(OpenLinkBlue)`
  margin-left: 4px;
`;

const ModalWrapper = styled.div`
  margin: 32px;
  color: #555555;
`;

const ModalTitle = styled.p`
  font-size: 14px;
  font-weight: 600;
`;

const ModalText = styled.p`
  margin-top: 12px;
  font-size: 14px;
  line-height: 22px;
`;

const ModalUl = styled.ul`
  margin-top: 24px;
  list-style: disc;
`;

const ModalLi = styled.li`
  margin-top: 16px;
  margin-left: 1.5em;
  font-size: 14px;
  line-height: 20px;
`;

const PredictIconWrapper = styled.div`
  display: flex;
  white-space: nowrap;
  align-items: flex-start;
  margin-right: 4px;
`;

const PredictIcon = styled.span`
  display: inline-block;
  padding: 0px 4px;
  font-size: 9px;
  border: solid 1px ${uploadBorderColor};
  border-radius: 4px;
`;

const PredictLiDetailWrapper = styled.div`
  display: flex;
`;

const PredictSettingLink = styled.a`
  color: ${textLinkColor};
`;
