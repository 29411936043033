import * as React from 'react';
import styled from 'styled-components';
import { Radio } from '@air-kit/air-kit';
import { Link } from 'react-router-dom';
import { dataOutputType } from '../../../../ui/pages/DataOuput/DataOutputConstants';
import { textLinkColor } from '../../../../constants/colors';
import { track } from '../../../../modules/logging';
import { genGaLog } from '../../../../gaLogger';

type Props = {
  readonly onChange: (a: keyof typeof dataOutputType) => void;
  readonly selectedType: keyof typeof dataOutputType;
  readonly track: typeof track;
};

const DataTypeSelector = (props: Props) => {
  const { onChange, selectedType, track } = props;
  return (
    <div>
      <RadioGroup id="data_output_data_select">
        <Radio
          name="dataType"
          value={dataOutputType.dailyOverview.value}
          label="日別成績（旧）"
          onChange={() => onChange(dataOutputType.dailyOverview.value)}
          checked={selectedType === dataOutputType.dailyOverview.value}
          data-test={`radio_${dataOutputType.dailyOverview.value}`}
        />
        {selectedType === dataOutputType.dailyOverview.value && (
          <RadioDescription>
            <p>
              以前の「全店舗一覧」画面で提供していた各種成績のデータです。店舗ごとに、日単位で生成されます。
            </p>
            <br />
            <p>
              ※より使いやすくなった日別成績のCSVデータは、
              <StyledLink
                to="/"
                onClick={() => {
                  track(_genClickStoreIndicesLinkLog());
                }}
              >
                成績
              </StyledLink>
              画面の「CSVダウンロード」から出力できます。
            </p>
            <br />
            <p>
              ※売上取込設定が「Airペイの決済額を売上として表示」になっている場合、こちらのデータには反映されません。
            </p>
          </RadioDescription>
        )}
        <Radio
          name="dataType"
          // @ts-ignore air-kitの型ではvalueはstringのみ
          value={dataOutputType.comparisonWithLastYear}
          label="主要指標前年比較"
          onChange={() => onChange(dataOutputType.comparisonWithLastYear.value)}
          checked={selectedType === dataOutputType.comparisonWithLastYear.value}
          data-test={`radio_${dataOutputType.comparisonWithLastYear.value}`}
        />
        {selectedType === dataOutputType.comparisonWithLastYear.value && (
          <RadioDescription>
            <p>
              売上・客数・客単価・人件費・仕入れ高という主要５指標を、前年実績と比べやすいフォーマットに整形したデータです。店舗ごとの日単位のデータに加え、月間の合計データも生成されます。
            </p>
            <br />
            <p>
              ※売上取込設定が「Airペイの決済額を売上として表示」になっている場合、こちらのデータには反映されません。
            </p>
          </RadioDescription>
        )}
        <Radio
          name="dataType"
          // @ts-ignore air-kitの型ではvalueはstringのみ
          value={dataOutputType.dailyDetail}
          label="日別コスト"
          onChange={() => onChange(dataOutputType.dailyDetail.value)}
          checked={selectedType === dataOutputType.dailyDetail.value}
          data-test={`radio_${dataOutputType.dailyDetail.value}`}
        />
        {selectedType === dataOutputType.dailyDetail.value && (
          <RadioDescription>
            店舗ごとの日単位のコストデータです。「日報」で入力したデータに加え、「月次コスト管理」で入力した月間コストを日割りしたデータも生成されます。
          </RadioDescription>
        )}
        <Radio
          name="dataType"
          // @ts-ignore air-kitの型ではvalueはstringのみ
          value={dataOutputType.customizeDailyReport}
          label="日別の日報追加集計項目"
          onChange={() => onChange(dataOutputType.customizeDailyReport.value)}
          checked={selectedType === dataOutputType.customizeDailyReport.value}
          data-test={`radio_${dataOutputType.customizeDailyReport.value}`}
        />
        {selectedType === dataOutputType.customizeDailyReport.value && (
          <RadioDescription>
            日報の追加集計項目に入力したデータです。店舗ごとに、日単位のデータで生成されます。
          </RadioDescription>
        )}
      </RadioGroup>
    </div>
  );
};
const RadioGroup = styled.div`
  display: flex;
  flex-flow: column;
  font-size: 14px;
  > * {
    margin-bottom: 12px;
  }
`;
const RadioDescription = styled.div`
  margin: 0 0 20px 24px;
`;
const StyledLink = styled(Link)`
  &:visited {
    color: ${textLinkColor};
  }
`;

const _genClickStoreIndicesLinkLog = () => {
  return genGaLog('data_output', 'data_output_store_indicestextlink', 'submit', {}, {}, 'click');
};

export default DataTypeSelector;
