import { createSelector } from 'reselect';
import { MonthlyLaborCostResponse } from '../typedef/api/LaborCost';
import { ShiftImportSettingForm } from '../typedef/api/ShiftImportSetting';
import {
  API_STATE_COMPLETED,
  API_STATE_FAILED,
  API_STATE_INITIAL,
  API_STATE_STARTED,
  apiState,
  ApiState,
  ErrorType,
} from '../typedef/api/Utility';
import { _InputSelector, _OutputSelector } from '../typedef/selector';
import { assertUnreachable } from '../helpers/util';

const monthlyLaborCostLoadingSelector: _InputSelector<boolean> = state =>
  state.laborCostAnalysis.monthlyData.type === API_STATE_INITIAL ||
  state.laborCostAnalysis.monthlyData.type === API_STATE_STARTED ||
  state.shiftImportSetting.shiftImportSettingFormState.type === API_STATE_INITIAL ||
  state.shiftImportSetting.shiftImportSettingFormState.type === API_STATE_STARTED;

const monthlyLaborCostLoadedSelector: _InputSelector<boolean> = state =>
  state.laborCostAnalysis.monthlyData.type === API_STATE_COMPLETED &&
  state.shiftImportSetting.shiftImportSettingFormState.type === API_STATE_COMPLETED;

const monthlyLaborCostErrorSelector: _InputSelector<ErrorType | null> = state => {
  if (state.laborCostAnalysis.monthlyData.type === API_STATE_FAILED) {
    return state.laborCostAnalysis.monthlyData.error;
  } else if (state.shiftImportSetting.shiftImportSettingFormState.type === API_STATE_FAILED) {
    return state.shiftImportSetting.shiftImportSettingFormState.error;
  } else {
    return null;
  }
};

export const monthlyDataState: _InputSelector<MonthlyLaborCostResponse | undefined> = state => {
  return state.laborCostAnalysis.monthlyData.type === 'API_STATE_COMPLETED' &&
    state.laborCostAnalysis.monthlyData.payload != null
    ? state.laborCostAnalysis.monthlyData.payload
    : undefined;
};

export const shiftImportSettingState: _InputSelector<ShiftImportSettingForm | undefined> = state => {
  return state.shiftImportSetting.shiftImportSettingFormState.type === 'API_STATE_COMPLETED' &&
    state.shiftImportSetting.shiftImportSettingFormState.payload != null
    ? state.shiftImportSetting.shiftImportSettingFormState.payload
    : undefined;
};

// @ts-ignore
export const monthlyLaborCostStateSelector: _OutputSelector<
  ApiState<{
    monthlyData: MonthlyLaborCostResponse;
    shiftImportSetting: ShiftImportSettingForm;
  }>
> = createSelector(
  [
    monthlyLaborCostLoadingSelector,
    monthlyLaborCostLoadedSelector,
    monthlyLaborCostErrorSelector,
    monthlyDataState,
    shiftImportSettingState,
  ],
  (loading, loaded, error, monthlyData, shiftImportSetting) => {
    if (loading) {
      return apiState.initial();
    } else if (error) {
      return apiState.failed(error);
    } else if (loaded) {
      return apiState.completed({ monthlyData, shiftImportSetting });
    } else {
      assertUnreachable();
      return apiState.initial();
    }
  }
);
