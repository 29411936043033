import { fork, call, take, put, select, all } from 'redux-saga/effects';
import { ApiCallEffectResult } from '../typedef/api/Utility';
import {
  FETCH_IMPORT_SETTING_START,
  INITIAL_FETCH_IMPORT_SETTING,
  POST_IMPORT_SETTING_START,
  actions,
} from '../modules/shiftImportSetting';
import ShiftImportSettingAPI from '../services/shiftImportSettingAPI';
import { ShiftImportSettingForm, ShiftImportSettingRequest } from '../typedef/api/ShiftImportSetting';
import { State } from '../modules';
import { StoresData } from '../modules/user';
import { assignedStoresSelector } from '../selectors/userDataSelector';
import _ from 'lodash';
import { AC } from '../constants/requestParameter';
import { types as uiTypes } from '../modules/uiConfig';
import { actions as laborCostAnalysisActions } from '../modules/laborCostAnalysis';

function* fetchShiftImportSettingSaga() {
  while (true) {
    const req: { readonly payload: string } = yield take(FETCH_IMPORT_SETTING_START);
    const { payload, error }: ApiCallEffectResult<ShiftImportSettingForm> = yield call(
      ShiftImportSettingAPI.fetchShiftImportSetting,
      req.payload
    );

    if (error) {
      yield put(actions.fetchShiftImportSettingFail(error));
    } else if (payload) {
      yield put(actions.fetchShiftImportSettingSuccess(payload));
    }
  }
}

function* postShiftImportSettingSaga() {
  while (true) {
    const req: { readonly payload: ShiftImportSettingRequest } = yield take(POST_IMPORT_SETTING_START);
    const akrCode: string = yield select((state: State) => state.laborCostAnalysis.selectedAkrCode);
    const { payload, error } = yield call(ShiftImportSettingAPI.postShiftImportSetting, req.payload);
    if (error) {
      yield put(actions.postShiftImportSettingFail(error));
    } else if (payload) {
      yield put(actions.postShiftImportSettingSuccess());
      yield put(actions.fetchShiftImportSetting(akrCode));
      yield put(laborCostAnalysisActions.fetchMonthlyLaborCost(true));
      yield put(laborCostAnalysisActions.fetchDailyLaborCost());
    }
  }
}

function* initialFetchShiftImportSettingSaga() {
  while (true) {
    yield all([take(uiTypes.SUCCESS_FETCH_BATCH_PROCCESSED_DATE), take(INITIAL_FETCH_IMPORT_SETTING)]);
    /**
     * 下記の場合、クエリパラメータacに付与されているAKRコードの店舗を初期表示に設定する
     * ・クエリパラメータacが付与されており、正常に取得できる
     * ・acのAKRコードが店舗一覧のAKRコードに含まれている
     */
    const stores: ReadonlyArray<StoresData> = yield select((state: State) => assignedStoresSelector(state));
    const sortedStores: ReadonlyArray<StoresData> = _.sortBy(stores, ['storeName']);
    const url = new URL(window.location.href);
    const ac = url.searchParams.get(AC);
    const hasAc = ac != null && sortedStores.some(store => store.akrCode === ac);
    const akrCode: string = hasAc ? ac : sortedStores[0].akrCode;
    const { payload, error }: ApiCallEffectResult<ShiftImportSettingForm> = yield call(
      ShiftImportSettingAPI.fetchShiftImportSetting,
      akrCode
    );
    if (error) {
      yield put(actions.fetchShiftImportSettingFail(error));
    } else if (payload) {
      yield put(actions.fetchShiftImportSettingSuccess(payload));
    }
  }
}

export default function* shiftImportSettingSaga() {
  yield fork(fetchShiftImportSettingSaga);
  yield fork(postShiftImportSettingSaga);
  yield fork(initialFetchShiftImportSettingSaga);
}
