import { fork, all, take, takeLatest, select, call, put } from 'redux-saga/effects';
import _ from 'lodash';
import { State as RootState } from '../modules';
import { StoresData } from '../modules/user';
import { actionTypes, actions } from '../modules/dailyCostList';
import DailyCostSummaryAPI from '../services/dailyCostSummaryAPI';
import { DailyCostListResponse } from '../typedef/api/DailyCostSummary';
import { ErrorType } from '../typedef/api/Utility';
import { assignedStoresSelector } from '../selectors/userDataSelector';
import { formatter, mclDayjs, parser } from '../helpers/mclDate';
import { getYearMonthObj } from '../helpers/mclDateHelper';

function* fetchDailyCostList() {
  yield takeLatest(actionTypes.FETCH_DAILY_COST_LIST, function* () {
    let akrCodes: Array<string> = [];
    const assignedStoreAkrCode = yield select((state: RootState) =>
      assignedStoresSelector(state).map(store => store.akrCode)
    );

    const selectedAkrCodes: Set<string> = yield select((state: RootState) => state.dailyCostList.akrCodes);
    // 表示店舗設定された時用の処理、表示設定されていないselectedAkrcodeがないかチェックする
    selectedAkrCodes.forEach(akrCode => {
      for (let i = 0; i < assignedStoreAkrCode.length; i++) {
        assignedStoreAkrCode[i] === akrCode && akrCodes.push(akrCode);
      }
    });
    yield put(actions.setAkrCode(new Set(akrCodes)));
    const businessMonth: string = yield select((state: RootState) => state.dailyCostList.businessMonth);
    const costCategoryTypes: Set<string> = yield select(
      (state: RootState) => state.dailyCostList.costCategoryTypes
    );
    const { payload, error }: { payload: ReadonlyArray<DailyCostListResponse>; error: ErrorType } =
      yield call(DailyCostSummaryAPI.getDailyCostList, {
        akrCodes: Array.from(akrCodes),
        businessMonth: mclDayjs(businessMonth, formatter.mapiDefaultYearMonthNotFixed).format(
          formatter.mapiYearMonth
        ),
        costCategoryTypes: Array.from(costCategoryTypes),
      });
    if (error) {
      // errorハンドリング
      yield put(actions.fetchDailyCostListFailure(error));
    } else if (payload) {
      yield put(actions.fetchDailyCostListSuccess(payload));
      yield put(actions.fetchDailyCostListSuccess(payload));
    }
  });
}

function* initalFetchSaga() {
  while (true) {
    yield take(actionTypes.INITIAL_FETCH_DAILY_COST_LIST);
    const stores: ReadonlyArray<StoresData> = yield select((state: RootState) =>
      assignedStoresSelector(state)
    );
    const sortedStores: ReadonlyArray<StoresData> = _.sortBy(stores, ['storeName']);
    const akrCodes: Array<string> = sortedStores.map(store => store.akrCode);
    const costCategoryTypes: Set<string> = new Set(['02', '06']);
    const nowYearMonth = mclDayjs();
    const businessMonth = nowYearMonth.format(formatter.mapiYearMonth);
    const minYearMonth = parser.fromDateObject({ year: 2013, month: 1, day: 1 });
    // 今の年月と2013-1-1の差分月を取得(2013-1-1まで含めるために+1する)
    const diffYearMonth = Math.abs(minYearMonth.diff(nowYearMonth, 'months')) + 1;
    yield put(actions.setAkrCode(new Set(akrCodes)));
    yield put(actions.setMonthList(getYearMonthObj(nowYearMonth, diffYearMonth)));
    yield put(actions.setCostCategoryTypes(costCategoryTypes));
    yield put(actions.setBusinessMonth(nowYearMonth.format(formatter.mapiDefaultYearMonthNotFixed)));
    const { payload, error }: { payload: ReadonlyArray<DailyCostListResponse>; error: ErrorType } =
      yield call(DailyCostSummaryAPI.getDailyCostList, {
        akrCodes,
        businessMonth,
        costCategoryTypes: Array.from(costCategoryTypes),
      });
    if (error) {
      // errorハンドリング
      yield put(actions.fetchDailyCostListFailure(error));
    } else if (payload) {
      yield put(actions.fetchDailyCostListSuccess(payload));
    }
  }
}

export default function* dailyCostSummary() {
  yield all([fork(fetchDailyCostList), fork(initalFetchSaga)]);
}
