import * as React from 'react';
import styled from 'styled-components';
import { bindActionCreators, Dispatch, Action } from 'redux';
import { connect } from 'react-redux';
import TitleHeader from '../../../components/common/TitleHeader';
import SelectBox from '../../../components/common/atoms/SelectBox';
import MonthlyLaborCost from './components/MonthlyLaborCost';
import DailyLaborCost from './components/DailyLaborCost';
import { laborCostAnalysisFaq } from '../../../constants/faqUrls';
import { State as ReduxState } from '../../../modules';
import { StoresData, UserData } from '../../../modules/user';
import { track } from '../../../modules/logging';
import { actions } from '../../../modules/laborCostAnalysis';
import { actions as shiftImportSettingActions } from '../../../modules/shiftImportSetting';
import { actions as targetSettingActions } from '../../../modules/targetSetting/ui';
import { getCookie } from '../../../helpers/cookieHelper';
import { MonthlyLaborCostResponse, DailyLaborCostResponse } from '../../../typedef/api/LaborCost';
import { API_STATE_COMPLETED, ApiState } from '../../../typedef/api/Utility';
import { BatchProcessedDate } from '../../../typedef/BatchProcessedDate';
import { genGaLog } from '../../../gaLogger';
import { isGourmetStore } from '../../../typedef/StoreGenre';
import { assignedStoresSelector } from '../../../selectors/userDataSelector';
import { isLaborCostNoAuthority } from '../../../helpers/util';
import { LocalYearMonthObj, formatter, mclDayjs, parser } from '../../../helpers/mclDate';
import { Waypoint } from 'react-waypoint';
import ShiftImportSettingsModal from './components/ShiftImportSettingsModal';
import FactorLegendModal from './components/FactorLegendModal';
import SalesInputMethodModal from './components/SalesInputMethodModal';
import { black, textLinkColor } from '../../../constants/colors';
import Setting from '../../../icons/shiftImportSetting.svg';
import { ShiftImportSettingForm } from '../../../typedef/api/ShiftImportSetting';
import { monthlyLaborCostStateSelector } from '../../../selectors/laborCostAnalysisSelector';
import { AC } from '../../../constants/requestParameter';

type StateProps = {
  readonly stores: ReadonlyArray<StoresData>;
  readonly batchProcessedDate: BatchProcessedDate;
  readonly selectedAkrCode: string;
  readonly selectedYearMonth: string;
  readonly selectedDate: string;
  readonly monthList: Array<LocalYearMonthObj>;
  readonly dailyData: ApiState<DailyLaborCostResponse>;
  readonly lastUpdateDate?: string | null;
  readonly laborCostViewScopeType: 'all' | 'manager';
  readonly userData: UserData | null;
  readonly monthlyLaborCostState: ApiState<{
    monthlyData: MonthlyLaborCostResponse;
    shiftImportSetting: ShiftImportSettingForm;
  }>;
};

type DispatchProps = {
  fetchMonthlyLaborCost: typeof actions.fetchMonthlyLaborCost;
  fetchDailyLaborCost: typeof actions.fetchDailyLaborCost;
  setAkrCode: typeof actions.setAkrCode;
  setYearMonth: typeof actions.setYearMonth;
  setDate: typeof actions.setDate;
  setMonthList: typeof actions.setMonthList;
  initialFetch: typeof actions.initialFetch;
  logger: typeof track;
  fetchShiftImportSetting: typeof shiftImportSettingActions.fetchShiftImportSetting;
  postShiftImportSetting: typeof shiftImportSettingActions.postShiftImportSetting;
  initialFetchShiftImportSetting: typeof shiftImportSettingActions.initialFetchShiftImportSetting;
  targetSettingSelectStore: typeof targetSettingActions.selectStore;
  openBaloon: typeof targetSettingActions.isChangeOpenBaloon;
};

type Props = StateProps & DispatchProps;

type State = {
  isShowModal: boolean;
  isShowShiftImportSettingsModal: boolean;
  isShowFactorLegendModal: boolean;
};

class LaborCostAnalysis extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isShowModal: false,
      isShowShiftImportSettingsModal: false,
      isShowFactorLegendModal: false,
    };
  }

  componentDidMount() {
    this.props.initialFetch();
    this.props.initialFetchShiftImportSetting();
    setTimeout(() => {
      const { selectedAkrCode } = this.props;
      const cookieData = getCookie('influxData');
      let vos: string | undefined;
      let lid: string | undefined;
      let viaPromoFlg: string | undefined;
      if (cookieData != null) {
        const cookieDataJson = JSON.parse(cookieData);
        vos = cookieDataJson.vos;
        lid = cookieDataJson.lid;
        viaPromoFlg = cookieDataJson.via_promo_flg;
      }
      this.props.logger(
        genGaLog(
          'labor_cost_analysis',
          'labor_cost_analysis',
          'on_load',
          {},
          {},
          'load',
          selectedAkrCode,
          vos,
          lid,
          viaPromoFlg
        )
      );
    }, 0);
  }

  render() {
    const {
      dailyData,
      selectedYearMonth,
      selectedDate,
      monthList,
      selectedAkrCode,
      batchProcessedDate,
      lastUpdateDate,
      laborCostViewScopeType,
      userData,
      monthlyLaborCostState,
    } = this.props;
    const { isShowShiftImportSettingsModal } = this.state;
    const {
      fetchMonthlyLaborCost,
      fetchDailyLaborCost,
      setDate,
      logger,
      fetchShiftImportSetting,
      postShiftImportSetting,
      targetSettingSelectStore,
      openBaloon,
    } = this.props;
    const sortedStores: Array<StoresData> = this.props.stores.map(x => x);
    const selectedStore = sortedStores.find(s => s.akrCode === selectedAkrCode);
    const isHideTimeRangePerOrderNumGraph =
      !isGourmetStore(selectedStore?.genre) &&
      selectedStore?.isRbActive === false &&
      selectedStore?.isHandyActive === false;
    sortedStores.sort((a, b) => {
      if (a.storeName < b.storeName) return -1;
      if (a.storeName > b.storeName) return 1;
      return 0;
    });

    const url = new URL(window.location.href);
    const ac = url.searchParams.get(AC);
    // 店舗プルダウンのプレースホルダー用、didmountより先にrenderの処理が始まってnull状態でプレースホルダーに反映されない為、didmountではなくrenderでacで受け取った店舗情報を取得してる
    const acStoreKey = sortedStores.find(store => store.akrCode === ac);

    // 店舗を引き継いで目標設定へ遷移する
    const toTargetSetting = () => {
      selectedStore != null && targetSettingSelectStore(selectedStore?.akrCode);
      openBaloon(true);
    };

    return (
      <React.Fragment>
        <Wrapper>
          <Waypoint
            onLeave={() => {
              logger(_genScrollLog());
            }}
          ></Waypoint>
          <TitleHeader
            track={this.props.logger}
            title="人件費分析"
            faqTitle="人件費分析の使い方"
            faqLink={laborCostAnalysisFaq}
            pageName="labor_cost_analysis"
            lastUpdateDate={lastUpdateDate != null ? lastUpdateDate : undefined}
            lastUpdateDateLog={genGaLog(
              'labor_cost_analysis',
              'labor_cost_analysis',
              'open_tooltip_lastUpdatedDateLabel_faq',
              {},
              {},
              'click'
            )}
          />
          <FormArea id="labor_cost_analysis_input_form">
            <StoreSelectBoxWrapper>
              <SelectBox.normal
                size="auto"
                isSelected
                isOneLine
                placeholder={
                  // クエリパラメータacが付与されておりAKRコードが店舗一覧のAKRコードに含まれている場合、初期表示時に付与されたAKRコードの店舗名を表示する
                  acStoreKey != null
                    ? { key: acStoreKey.akrCode, value: acStoreKey.storeName }
                    : selectedStore != null
                    ? { key: selectedStore.akrCode, value: selectedStore.storeName }
                    : undefined
                }
                options={sortedStores.map(store => ({ key: store.akrCode, value: store.storeName }))}
                onChange={option => {
                  // 一致しないことはないので空文字にする
                  const akrCode = sortedStores.find(s => s.akrCode === option.key)?.akrCode || '';
                  this.props.setAkrCode(akrCode);
                  fetchMonthlyLaborCost(true);
                  fetchShiftImportSetting(akrCode);
                  // 店舗変更時に対象年月の1日の詳細を表示する
                  setDate(mclDayjs(selectedYearMonth).startOf('month').format(formatter.mapiDate));
                  logger(
                    genGaLog(
                      'labor_cost_analysis',
                      'labor_cost_analysis',
                      'change_form',
                      {},
                      {
                        akrCode,
                        yearMonth: selectedYearMonth,
                      },
                      'change'
                    )
                  );
                  selectedDate !== '' && fetchDailyLaborCost();
                }}
              />
            </StoreSelectBoxWrapper>
            <StyledSelectBox
              placeholder={{
                key: parser
                  .fromYearMonthObject(
                    monthList.find(
                      yearMonth =>
                        parser.fromYearMonthObject(yearMonth).format(formatter.mapiYearMonth) ===
                        selectedYearMonth
                    ) || mclDayjs().toLocalYearMonthObj()
                  )
                  .format(formatter.mapiDefaultYearMonthNotFixed),
                value: parser
                  .fromYearMonthObject(
                    monthList.find(
                      yearMonth =>
                        parser.fromYearMonthObject(yearMonth).format(formatter.mapiYearMonth) ===
                        selectedYearMonth
                    ) || mclDayjs().toLocalYearMonthObj()
                  )
                  .format(formatter.mapiDefaultYearMonthNotFixed),
              }}
              options={monthList.map(yearMonth => ({
                key: parser.fromYearMonthObject(yearMonth).format(formatter.mapiDefaultYearMonthNotFixed),
                value: parser.fromYearMonthObject(yearMonth).format(formatter.mapiDefaultYearMonthNotFixed),
              }))}
              onChange={date => {
                // 一致しないことはないので現在年月にする
                const yearMonth = parser
                  .fromYearMonthObject(
                    monthList.find(
                      yearMonth =>
                        parser
                          .fromYearMonthObject(yearMonth)
                          .format(formatter.mapiDefaultYearMonthNotFixed) === date.value
                    ) || mclDayjs().toLocalYearMonthObj()
                  )
                  .format(formatter.mapiYearMonth);

                this.props.setYearMonth(yearMonth);
                fetchMonthlyLaborCost();
                fetchShiftImportSetting(selectedAkrCode);
                // 年月変更時に対象年月の1日の詳細を表示する
                setDate(mclDayjs(yearMonth).startOf('month').format(formatter.mapiDate));
                fetchDailyLaborCost();
                this.props.logger(
                  genGaLog(
                    'labor_cost_analysis',
                    'labor_cost_analysis',
                    'change_form',
                    {},
                    {
                      akrCode: selectedAkrCode,
                      yearMonth,
                    },
                    'change'
                  )
                );
              }}
            />
            {/* 選択店舗がシフト利用あり */}
            {selectedStore?.isShiftUse && monthlyLaborCostState.type === API_STATE_COMPLETED && (
              <ShiftImportSettingWrapper
                isSelectedTypeAttendance={
                  monthlyLaborCostState.payload.shiftImportSetting.shiftImportSetting.shiftImportType ===
                  'attendance'
                }
              >
                <p>
                  {monthlyLaborCostState.payload.shiftImportSetting.shiftImportSetting.shiftImportType ===
                  'attendance'
                    ? '打刻実績'
                    : '確定シフト'}
                  から人件費を算出中
                </p>
                <button
                  onClick={() => {
                    this.setState({ isShowShiftImportSettingsModal: true });
                    logger(_genChangeImportTypeButtonLog());
                  }}
                >
                  変更する
                </button>
                <Setting />
              </ShiftImportSettingWrapper>
            )}
          </FormArea>
          <MonthlyLaborCost
            yearMonth={selectedYearMonth}
            storeData={selectedStore}
            date={selectedDate}
            batchProcessedDate={batchProcessedDate}
            fetchDailyLaborCost={fetchDailyLaborCost}
            setDate={setDate}
            logger={logger}
            showFactorModal={() => {
              this.setState({ isShowFactorLegendModal: true });
            }}
            monthlyLaborCostState={monthlyLaborCostState}
            toTargetSetting={toTargetSetting}
          />
          {monthlyLaborCostState.type === API_STATE_COMPLETED && (
            <DailyLaborCost
              dailyData={dailyData}
              storeData={sortedStores.find(s => s.akrCode === selectedAkrCode)}
              isHideTimeRangePerOrderNumGraph={isHideTimeRangePerOrderNumGraph}
              logger={logger}
              isLaborCostNoAuthority={isLaborCostNoAuthority(laborCostViewScopeType, userData?.plfGrant)}
              showFactorModal={() => {
                this.setState({ isShowFactorLegendModal: true });
              }}
              shiftImportType={monthlyLaborCostState.payload.shiftImportSetting}
            />
          )}
        </Wrapper>
        {monthlyLaborCostState.type === API_STATE_COMPLETED && isShowShiftImportSettingsModal && (
          <ShiftImportSettingsModal
            onClickClose={() => {
              this.setState({ isShowShiftImportSettingsModal: false });
            }}
            postShiftImportSetting={postShiftImportSetting}
            shiftImportType={
              monthlyLaborCostState.payload.shiftImportSetting.shiftImportSetting.shiftImportType
            }
            selectedAkrCode={selectedAkrCode}
            track={logger}
          />
        )}

        {this.state.isShowModal && (
          <SalesInputMethodModal
            onClickClose={() => {
              this.setState({ isShowModal: false });
            }}
            track={logger}
          />
        )}
        {this.state.isShowFactorLegendModal && (
          <FactorLegendModal
            onClickClose={() => {
              this.setState({ isShowFactorLegendModal: false });
            }}
            onClickTargetSetting={toTargetSetting}
            track={logger}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: ReduxState): StateProps => ({
  stores: assignedStoresSelector(state),
  batchProcessedDate: state.uiConfig.batchProcessedDate,
  selectedAkrCode: state.laborCostAnalysis.selectedAkrCode,
  selectedYearMonth: state.laborCostAnalysis.selectedYearMonth,
  selectedDate: state.laborCostAnalysis.selectedDate,
  monthList: state.laborCostAnalysis.monthList,
  dailyData: state.laborCostAnalysis.dailyData,
  lastUpdateDate: state.uiConfig.batchProcessLastFinishDatetime,
  laborCostViewScopeType: state.uiConfig.laborCostViewScopeType,
  userData: state.user.data,
  monthlyLaborCostState: monthlyLaborCostStateSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps =>
  bindActionCreators(
    {
      ...actions,
      logger: track,
      fetchShiftImportSetting: shiftImportSettingActions.fetchShiftImportSetting,
      postShiftImportSetting: shiftImportSettingActions.postShiftImportSetting,
      initialFetchShiftImportSetting: shiftImportSettingActions.initialFetchShiftImportSetting,
      targetSettingSelectStore: targetSettingActions.selectStore,
      openBaloon: targetSettingActions.isChangeOpenBaloon,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(LaborCostAnalysis);

const Wrapper = styled.div`
  padding: 24px;
  // ツールチップが下からはみ出してしまうため
  padding-bottom: 60px;
`;

const StoreSelectBoxWrapper = styled.div`
  width: 100%;
  max-width: 512px;
`;

const ShiftImportSettingWrapper = styled.div<{ isSelectedTypeAttendance: boolean }>`
  display: flex;
  align-items: center;
  margin-left: auto;
  min-width: ${props => (props.isSelectedTypeAttendance ? 266 : 288)}px;
`;

const FormArea = styled.div`
  width: 100%;
  display: flex;
  margin-top: 16px;
  align-items: center;
  p {
    font-size: 14px;
    font-weight: 400;
    color: ${black};
    margin-right: 8px;
  }
  button {
    font-size: 14px;
    color: ${textLinkColor};
    font-weight: 400;
    border: none;
    background: none;
    margin-right: 4px;
    cursor: pointer;
  }
`;

const StyledSelectBox = styled(SelectBox.normal)`
  margin: 0 14px;
`;

const _genScrollLog = () => {
  return genGaLog('labor_cost_analysis', 'labor_cost_analysis', 'on_scroll', {}, {}, 'scroll');
};

const _genChangeImportTypeButtonLog = () => {
  return genGaLog('labor_cost_analysis', 'labor_cost_analysis_change_link', 'open', {}, {}, 'click');
};
