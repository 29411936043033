import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from '../../../../components/common/molecules/Airkit/AirModal';
import AirRadioButton from '../../../../components/common/molecules/Airkit/AirRadioButton';

import {
  airblue,
  airblueBorder,
  black,
  verylightgray,
  verylightgrayBorder,
  white,
} from '../../../../constants/colors';
import { SHIFT_DATA_TYPE } from '../LaborCostAnalysisConstants';
import { postShiftImportSetting } from '../../../../modules/shiftImportSetting';
import { ShiftImportType } from '../../../../typedef/api/ShiftImportSetting';
import { track } from '../../../../modules/logging';
import { genGaLog } from '../../../../gaLogger';
import { Waypoint } from 'react-waypoint';

type Props = {
  postShiftImportSetting: typeof postShiftImportSetting;
  onClickClose: () => void;
  shiftImportType: ShiftImportType;
  selectedAkrCode: string;
  track: typeof track;
};

/**
 *
 */
const ShiftImportSettingsModal: React.FC<Props> = props => {
  const { postShiftImportSetting, onClickClose, track, shiftImportType, selectedAkrCode } = props;

  const [importType, setImportType] = useState<ShiftImportType>(shiftImportType);

  return (
    <Modal title="" noHeader={true} noFooter={true} onClose={onClickClose}>
      <Waypoint
        onEnter={() => {
          track(_genChangeImportTypeModalImpressionLog());
        }}
      >
        <Wrapper>
          <Title>Airシフト連携設定</Title>
          <Lead>Airシフト連携人件費の算出元となるデータを選択できます。</Lead>
          <Lead>※「人件費分析」画面のみの設定です。他の画面では常に打刻実績を元に表示されます。</Lead>
          <RadioButtonWrapper>
            <StyledAirRadioButton
              name="shiftDataType"
              label={SHIFT_DATA_TYPE['attendance']}
              value={SHIFT_DATA_TYPE['attendance']}
              checked={importType === 'attendance'}
              onChange={() => {
                setImportType('attendance');
                track(_genChangeImportTypeModalRadioButtonLog('attendance'));
              }}
            />
            <StyledAirRadioButton
              name="shiftDataType"
              label={SHIFT_DATA_TYPE['confirmed_shift']}
              value={SHIFT_DATA_TYPE['confirmed_shift']}
              checked={importType === 'confirmed_shift'}
              onChange={() => {
                setImportType('confirmed_shift');
                track(_genChangeImportTypeModalRadioButtonLog('confirmed_shift'));
              }}
            />
          </RadioButtonWrapper>
          <ButtonLead>Airシフト上で勤怠管理をしていない場合はこちらを選択してください。</ButtonLead>
          <StyledButtonWrapper>
            <StyledButton
              isSubmitButton={false}
              isAirBlue={false}
              onClick={() => {
                onClickClose();
                track(_genChangeImportTypeModalClickLog('cancel'));
              }}
            >
              キャンセル
            </StyledButton>
            <Span />
            <StyledButton
              isSubmitButton={true}
              isAirBlue={true}
              onClick={() => {
                postShiftImportSetting({ akrCode: selectedAkrCode, shiftImportType: importType });
                onClickClose();
                track(_genChangeImportTypeModalClickLog('save'));
              }}
            >
              保存する
            </StyledButton>
          </StyledButtonWrapper>
        </Wrapper>
      </Waypoint>
    </Modal>
  );
};

export default ShiftImportSettingsModal;

const Wrapper = styled.div`
  padding: 32px 32px;
`;

const Title = styled.h4`
  font-size: 18px;
  color: ${black};
  font-weight: 600;
  margin-bottom: 24px;
`;

const Lead = styled.p`
  color: ${black};
  line-height: 26px;
`;

const StyledAirRadioButton = styled(AirRadioButton)`
  margin-top: 29px;
`;

const RadioButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonLead = styled.p`
  font-size: 14px;
  color: ${black};
  line-height: 20px;
  margin: 8px 0 44px 26px;
`;

const StyledButtonWrapper = styled.div`
  text-align: right;
`;

const StyledButton = styled.button<{ isAirBlue: boolean; isSubmitButton: boolean }>`
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
  display: inline-block;
  font-size: 16px;
  height: 40px;
  min-width: 70px;
  margin: auto 0;
  padding: 0 ${props => (props.isSubmitButton ? 48 : 16)}px;
  white-space: nowrap;
  background: ${props => (props.isAirBlue ? airblue : verylightgray)};
  box-shadow: inset 0 1px 0 0 ${props => (props.isAirBlue ? airblueBorder : verylightgrayBorder)},
    inset -1px 0 0 0 ${props => (props.isAirBlue ? airblueBorder : verylightgrayBorder)},
    inset 1px 0 0 0 ${props => (props.isAirBlue ? airblueBorder : verylightgrayBorder)},
    inset 0 -2px 0 0 ${props => (props.isAirBlue ? airblueBorder : verylightgrayBorder)};
  color: ${props => (props.isAirBlue ? white : black)};
  &:hover {
    cursor: pointer;
  }
`;

const Span = styled.span`
  margin-right: 16px;
`;

const _genChangeImportTypeModalImpressionLog = () => {
  return genGaLog(
    'labor_cost_analysis',
    'labor_cost_analysis_link_sft_import_modal',
    'impression',
    {},
    {},
    'impression'
  );
};

const _genChangeImportTypeModalClickLog = (func: string) => {
  return genGaLog('labor_cost_analysis', 'labor_cost_analysis_link_sft_import_modal', func, {}, {}, 'click');
};

const _genChangeImportTypeModalRadioButtonLog = (type: string) => {
  return genGaLog(
    'labor_cost_analysis',
    'labor_cost_analysis_link_sft_import_modal',
    'radio_switch',
    {},
    { type: [type] },
    'click'
  );
};
