import * as React from 'react';
import { Waypoint } from 'react-waypoint';
import styled from 'styled-components';

import {
  baseFontSize,
  prefixUnit,
  postfixUnit,
  LocaleInteger,
} from '../../../../components/common/atoms/Number';
import { gray, lightgray, verylightgray, white } from '../../../../constants/colors';

import ZIndex from '../../../../constants/z-index';
import { genGaLog } from '../../../../gaLogger';
import { track } from '../../../../modules/logging';

import { CustomTableData } from '../../../../typedef/api/DayofweekHourly';
import { ANALYSIS_TYPE, DAYOFWEEK_HOURLY_DATA_TYPE, LOG_RECORD_NUM } from '../DayofweekHourlyConstants';

const FormatYen = baseFontSize(16)(prefixUnit('¥')(LocaleInteger));
const FormatVisitorNum = baseFontSize(16)(postfixUnit('人')(LocaleInteger));

type Props = {
  readonly viewDataType: keyof typeof DAYOFWEEK_HOURLY_DATA_TYPE;
  readonly tableData: CustomTableData;
  innerHeight: number;
  isBatchFinish: boolean;
  readonly tracker: typeof track;
  readonly analysisType: keyof typeof ANALYSIS_TYPE;
};

const Table = (props: Props) => {
  const { tableData, viewDataType, innerHeight, isBatchFinish, tracker, analysisType } = props;
  return (
    <Wrapper innerHeight={innerHeight} isBatchFinish={isBatchFinish}>
      <StyledTable>
        <TableHeader>
          <tr>
            <RowNameCell />
            {tableData.header.map((item, idx) => (
              <HeaderItem isFirstRow={idx === 0} key={idx}>
                <TableHeaderItemWrapper>{item}</TableHeaderItemWrapper>
              </HeaderItem>
            ))}
          </tr>
        </TableHeader>
        <tbody>
          {tableData.data.map((record, idxRecord) => (
            <React.Fragment>
              {LOG_RECORD_NUM.includes(idxRecord) && (
                <Waypoint
                  onEnter={() => {
                    tracker(_genScrollLog(analysisType));
                  }}
                />
              )}
              <TableRow>
                {record.record.map((item, idxColumn) =>
                  idxColumn === 0 ? (
                    <DataItem isSticky={true} isFirstRow={true} isFirstRecord={idxRecord === 0} isBold={true}>
                      {item}
                    </DataItem>
                  ) : (
                    <DataItem
                      isBold={idxRecord === 0 || idxColumn === 1}
                      isFirstRecord={idxRecord === 0}
                      isSecondRow={idxColumn === 1}
                      isSticky={idxColumn === 1}
                    >
                      {viewDataType === 'visitorNum' ? (
                        <FormatVisitorNum value={item} />
                      ) : (
                        <FormatYen value={item} />
                      )}
                    </DataItem>
                  )
                )}
              </TableRow>
            </React.Fragment>
          ))}
        </tbody>
      </StyledTable>
    </Wrapper>
  );
};

export default Table;

const _genScrollLog = (selectedType: keyof typeof ANALYSIS_TYPE) => {
  return genGaLog(
    'dayofweek_hourly',
    'table',
    'scroll',
    {},
    { selected_type: ANALYSIS_TYPE[selectedType].logName },
    'scroll'
  );
};

const Wrapper = styled.div<{ innerHeight: number; isBatchFinish: boolean }>`
  overflow-x: auto;
  white-space: nowrap;
  max-height: calc(
    ${props => props.innerHeight && props.innerHeight}px - ${props => (props.isBatchFinish ? 124 : 164)}px
  );
  border: solid 1px ${lightgray};
`;

const StyledTable = styled.table`
  width: 100%;
  border-spacing: 0;
`;

const TableHeader = styled.thead`
  background-color: ${gray};
`;

const TableRow = styled.tr`
  border-bottom: solid 1px ${lightgray};
`;

const HeaderItem = styled.th<{ isFirstRow?: boolean }>`
  position: sticky;
  top: 0;
  left: 130px;
  min-width: ${props => (props.isFirstRow ? '130' : '110')}px;
  white-space: normal;
  background-color: ${gray};
  padding: 16px 12px;
  ${props =>
    props.isFirstRow &&
    `
  border-right: solid 1px ${lightgray};
  border-left: solid 1px ${lightgray};
  z-index:1;
  `};
`;

const RowNameCell = styled(HeaderItem)`
  z-index: ${ZIndex.tableRowNameCell};
  left: 0px;
  box-shadow: 1px 0 2px 0 rgba(0, 0, 0, 0.24);
  width: 130px;
  min-width: 130px;
`;

const TableHeaderItemWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
`;

const DataItem = styled.td<{
  isSticky?: boolean;
  isFirstRow?: boolean;
  isFirstRecord?: boolean;
  isBold?: boolean;
  isSecondRow?: boolean;
}>`
  border-bottom: solid 1px ${lightgray};
  position: ${props => props.isSticky && 'sticky'};
  background-clip: padding-box;
  box-shadow: ${props => props.isFirstRow && '1px 0 2px 0 rgba(0,0,0,0.24)'};
  left: ${props => (props.isSecondRow ? '130px' : 0)};
  white-space: ${props => (props.isFirstRow ? 'normal' : 'nowrap')};
  background-color: ${props => (props.isFirstRow ? gray : white)};
  text-align: ${props => (props.isFirstRow ? 'center' : 'right')};
  ${props =>
    props.isSecondRow &&
    `
  border-right: solid 1px ${lightgray};
  border-left: solid 1px ${lightgray};
  background-color: ${verylightgray};
  `};
  word-break: break-all;
  padding: 12px;
  ${props => props.isFirstRow && 'font-size: 14px;'}
  ${props => props.isBold && 'font-weight: 600;'}
`;
