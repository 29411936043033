import React from 'react';
import styled from 'styled-components';
import { Button } from '@air-kit/air-kit';
import CostInputViewHeader from './CostInputViewHeader';
import { CostCategoryTypeShort } from '../../../../../constants/CostCategory';
import { CostItem } from '../../../../../typedef/api/CostItems';
import { CustomItem } from '../../../../../typedef/api/CustomItems';
import { airblue, airGray, disabledTextColor, lightgray, navy } from '../../../../../constants/colors';
import BlueCalendar from '../../../../../icons/blueCalendar.svg';
import { formatter, mclDayjs } from '../../../../../helpers/mclDate';
type Props = {
  costItems: ReadonlyArray<CostItem>;
  extraItems: ReadonlyArray<CustomItem>;
  hasSalesManualInputStore: boolean;
  className?: string;
};

class CostInputView extends React.Component<Props> {
  render() {
    const { className, costItems, extraItems, hasSalesManualInputStore } = this.props;
    const nowDate = mclDayjs();
    return (
      <Wrapper className={className}>
        <CostInputViewHeader
          date={`${nowDate.format(formatter.monthDay)}(${nowDate.format(formatter.week)})`}
        />
        <ContentWrapper>
          <StoreName>エアメイト酒場 渋谷店</StoreName>
          <DateRow>
            <span>{nowDate.format(formatter.mapiDefaultDate)}</span>
            <ButtonStyle>
              <StyledBlueCalendar />
              変更
            </ButtonStyle>
          </DateRow>
          {hasSalesManualInputStore && (
            <React.Fragment>
              <SubTitleRow>売上</SubTitleRow>
              <RowWrapper>
                <ItemRow>売上</ItemRow>
                <NumberRow>¥0</NumberRow>
              </RowWrapper>
              <SubTitleRow>客数</SubTitleRow>
              <RowWrapper>
                <ItemRow>客数</ItemRow>
                <NumberRow>0人</NumberRow>
              </RowWrapper>
            </React.Fragment>
          )}
          <SubTitleRow>コスト項目</SubTitleRow>
          {costItems.map(item => (
            <RowWrapper key={item.costItemId}>
              <ItemRow>
                {item.costItemName}
                <CategoryType>
                  {item.costCategoryType != null ? CostCategoryTypeShort[item.costCategoryType] : ''}
                </CategoryType>
              </ItemRow>
              <NumberRow>¥0</NumberRow>
            </RowWrapper>
          ))}
          <SubTitleRow>追加集計項目</SubTitleRow>
          {extraItems.map(
            item =>
              item.isDailyReportInput && (
                <RowWrapper key={item.customItemId}>
                  <ItemRow>{item.customItemName}</ItemRow>
                  <NumberRow>0</NumberRow>
                </RowWrapper>
              )
          )}
          <StyledButton primary> 保存して次へ </StyledButton>
        </ContentWrapper>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  width: 88%;
  height: 86%;
  overflow-y: scroll;
  background-color: white;
  border-radius: 0px 0px 10% 10% / 0px 0px 5% 5%;
  ::-webkit-scrollbar {
    display: none;
  }
`;
const ContentWrapper = styled.div`
  width: 100%;
`;
const StyledButton = styled(Button)`
  position: absolute;
  width: 40%;
  height: 10%;
  font-size: xx-small;
  padding: 0 4%;
  right: 12px;
  margin-bottom: 12px;
  font-weight: initial;
  margin-top: 12px;
`;
const StoreName = styled.div`
  color: white;
  font-size: 8px;
  line-height: 20px;
  font-weight: 600;
  height: 20px;
  width: 100%;
  background: ${navy};
  padding: 0 8px;
`;
const DateRow = styled.div`
  height: 30px;
  padding: 10px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 10px;
`;
const ButtonStyle = styled.div`
  font-size: 9px;
  color: ${airblue};
  display: flex;
  align-items: center;
`;
const StyledBlueCalendar = styled(BlueCalendar)`
  margin-right: 4px;
`;
const SubTitleRow = styled.div`
  background: ${airGray};
  height: 20px;
  font-size: 8px;
  line-height: 20px;
  padding: 0 8px;
`;
const RowWrapper = styled.div`
  padding: 0 8px 5px;
`;
const ItemRow = styled.div`
  font-size: 9px;
`;
const CategoryType = styled.span`
  font-size: 10px;
  transform: scale(0.6);
  margin-left: 4px;
  border: 0.5px solid ${disabledTextColor};
  border-radius: 2px;
  display: inline-block;
`;
const NumberRow = styled.div`
  font-size: 10px;
  padding: 5px 0;
  border-bottom: 1px solid ${lightgray};
  text-align: right;
`;
export default CostInputView;
