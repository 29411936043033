export const setTargetFaq = 'https://faq.mate.airregi.jp/hc/ja/articles/900000657383'; //目標設定
export const costPredictionSettingFaq = 'https://faq.mate.airregi.jp/hc/ja/articles/900000849343'; //月次コスト管理
export const menuFaq = 'https://faq.mate.airregi.jp/hc/ja/articles/900000748386'; //商品分析
export const monthlyLookbackFaq = 'https://faq.mate.airregi.jp/hc/ja/articles/900000735586'; //月次レポート
export const basicSettingFaq = 'https://faq.mate.airregi.jp/hc/ja/articles/360011275994'; //基本設定
export const costItemSettingFaq = 'https://faq.mate.airregi.jp/hc/ja/articles/900000392486'; //コスト項目設定
export const customizeDailyReportFaq = 'https://faq.mate.airregi.jp/hc/ja/articles/900000875406'; //日報設定
export const dataOutputFaq = 'https://faq.mate.airregi.jp/hc/ja/articles/900000758546'; //データ出力
export const pastDataImportFaq = 'https://faq.mate.airregi.jp/hc/ja/articles/900000881126'; //売上一括取込
export const realtimeFaq = 'https://faq.mate.airregi.jp/hc/ja/articles/900001111926'; // リアルタイム
export const inputDailyReportCostFaq = 'https://faq.mate.airregi.jp/hc/ja/articles/900001112066'; // 日報入力コスト・追加集計項目
export const inputDailyReportNoteFaq = 'https://faq.mate.airregi.jp/hc/ja/articles/900001116963'; // 日報入力振り返りコメント
export const dailyReportManagement = 'https://faq.mate.airregi.jp/hc/ja/articles/900001166283'; // 日次コスト管理
export const dailyReportListFaq = 'https://faq.mate.airregi.jp/hc/ja/articles/900001280466'; // 日報
export const laborCostAnalysisFaq = 'https://faq.mate.airregi.jp/hc/ja/articles/900001118883'; // 人件費分析
export const dayofweekHourlyFaq = 'https://faq.mate.airregi.jp/hc/ja/articles/14801343412121'; // 曜日・時間帯別分析
export const storeIndexFaq = 'https://faq.mate.airregi.jp/hc/ja/articles/900001214463'; // 成績
export const voucherAnalysisFaq = 'https://faq.mate.airregi.jp/hc/ja/articles/900001802623'; // 成績伝票一覧
export const latestDateUpdateTooltipFaq = 'https://faq.mate.airregi.jp/hc/ja/articles/4408115500569'; // データ更新日時ラベルツールチップ内リンク
export const dataImportSettingFaq = 'https://faq.mate.airregi.jp/hc/ja/articles/4406850826649'; // 売上取込設定
export const faqTop = 'https://faq.mate.airregi.jp/hc/ja/articles/4407443838745'; // faqのTop画面
export const spAirMateFaq = 'https://faq.mate.airregi.jp/hc/ja/articles/4408476775961'; // スマートフォン版Airメイトについて
export const dailyDetailPastFaq = 'https://faq.mate.airregi.jp/hc/ja/articles/4411000086553'; // 日別詳細過去のFaq
export const dailyDetailFutureFaq = 'https://faq.mate.airregi.jp/hc/ja/articles/4413999719449'; // 日別詳細未来のFaq
export const handyFaq = 'https://faq.mate.airregi.jp/hc/ja/articles/4408208548121'; // オーダー連携
export const cardSettingFaq = 'https://faq.mate.airregi.jp/hc/ja/articles/11254644254361'; // Airカード連携設定
export const cardCostListFaq = 'https://faq.mate.airregi.jp/hc/ja/articles/11254604011161'; // Airカード連携コスト管理
export const settingPredictTagFaq = 'https://faq.mate.airregi.jp/hc/ja/articles/900000882446'; // 分析タグ設定方法
export const transferFaq = 'https://faq.mate.airregi.jp/hc/ja/articles/20585805672857'; // 入金情報
export const laborCostSettingFaq = 'https://faq.mate.airregi.jp/hc/ja/articles/22438222560281'; // 人件費管理
export const newYearReportFaq = 'https://faq.mate.airregi.jp/hc/ja/articles/25677833740185'; // 年始サマリー
export const invoiceCostListFaq = 'https://faq.mate.airregi.jp/hc/ja/articles/22306819799193'; // Airインボイス連携コスト管理Faq
export const monthlySalesPredictedFaq = 'https://faq.mate.airregi.jp/hc/ja/articles/32829523484697'; // 売上予測Faq
export const addStoreFaq = 'https://faq.mate.airregi.jp/hc/ja/articles/4408115457177'; // 店舗追加のFaq
export const hourlyWageSettingFaq = 'https://faq.airshift.jp/hc/ja/articles/42130834629657'; // 時給設定方法案内のFAQ
