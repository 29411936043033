/**
 * 人件費分析　日別のデータ
 */

import * as React from 'react';
import styled from 'styled-components';
import {
  baseFontSize,
  prefixUnit,
  postfixUnit,
  Decimal,
  LocaleInteger,
} from '../../../../components/common/atoms/Number';
import { lightgray, red, green, verylightgray, textLinkColor } from '../../../../constants/colors';
import { DailyLaborCostResponse } from '../../../../typedef/api/LaborCost';
import TimeIconGray from '../../../../icons/TimeIconGray.svg';
import HumanoidIcon from '../../../../icons/HumanoidIconGray.svg';
import YenIcon from '../../../../icons/YenIconGray.svg';
import { ShiftImportSettingForm } from '../../../../typedef/api/ShiftImportSetting';
import { genGaLog } from '../../../../gaLogger';
import { track } from '../../../../modules/logging';

type Props = {
  data: DailyLaborCostResponse;
  readonly isLaborCostNoAuthority: boolean;
  readonly showFactorModal: () => void;
  readonly shiftImportType: ShiftImportSettingForm;
  readonly track: typeof track;
};

const FormatYen = baseFontSize(20)(prefixUnit('¥')(LocaleInteger));
const FormatYenMedium = baseFontSize(14)(prefixUnit('¥')(LocaleInteger));
const FormatYenSmall = baseFontSize(12)(prefixUnit('¥')(LocaleInteger));
const FormatRate = baseFontSize(20)(postfixUnit('%')(Decimal));
const FormatRateSmall = baseFontSize(12)(postfixUnit('%')(Decimal));

class DailyTopPane extends React.Component<Props> {
  render() {
    const { data, isLaborCostNoAuthority, showFactorModal, shiftImportType, track } = this.props;
    const {
      summary,
      costItems,
      shiftLaborCost,
      isShiftReviewFlag,
      isAttendanceManagementFlag,
      isSalesImprovementMeasuresFlag,
    } = data;

    /**
     * attendance:打刻
     * confirmed_shift:確定シフト
     */
    const importType =
      shiftImportType.shiftImportSetting.shiftImportType === 'attendance'
        ? 'attendanceBasedValue'
        : 'confirmedShiftBasedValue';
    /**
     * シフト計画超過・労働時間超過・売上未達成のいずれかの要因がある場合、trueを返す
     */
    const hasFactor = isShiftReviewFlag || isAttendanceManagementFlag || isSalesImprovementMeasuresFlag;
    return (
      <TopPane>
        <LeftPane>
          <LeftPaneTitle>人件費率</LeftPaneTitle>
          <LeftPaneContent>
            <ColoringRed
              isOver={
                summary.laborCostRate.target != null
                  ? summary.laborCostRate.target < summary.laborCostRate[importType]
                  : false
              }
            >
              <FormatRate value={summary.laborCostRate[importType]} />
            </ColoringRed>
            <SmallText>
              (目標　
              {summary.laborCostRate.target != null ? (
                <FormatRateSmall value={summary.laborCostRate.target} />
              ) : (
                '-%'
              )}
              )
            </SmallText>
          </LeftPaneContent>
          <LeftPaneTitle>人件費額</LeftPaneTitle>
          <LeftPaneContent>
            <FormatYen value={summary.totalLaborCost[importType]} />
            <SmallText>
              (目標　
              {summary.totalLaborCost.target != null ? (
                <FormatYenSmall value={summary.totalLaborCost.target} />
              ) : (
                '¥-'
              )}
              )
            </SmallText>
          </LeftPaneContent>
          <LeftPaneTitle>売上</LeftPaneTitle>
          <LeftPaneContent>
            <ColoringRedGreen
              isOver={summary.sales.target != null ? summary.sales.target > summary.sales.value : undefined}
            >
              <FormatYen value={summary.sales.value} />
            </ColoringRedGreen>
            <SmallText>
              (目標　
              {summary.sales.target != null ? <FormatYenSmall value={summary.sales.target} /> : '¥-'})
            </SmallText>
          </LeftPaneContent>
          {/* シフト計画超過・労働時間超過・売上未達成のいずれかの要因がある場合に要因表示 */}
          {hasFactor && (
            <FactorWrapper>
              <div>
                <h4>考えられる目標未達成要因</h4>
                <button
                  onClick={() => {
                    showFactorModal();
                    track(_genShowModalLog());
                  }}
                >
                  詳しく見る
                </button>
              </div>
              {/* 各要因フラグがtrueの時に表示 */}
              {isShiftReviewFlag && (
                <div>
                  <p>
                    <TimeIconGray />
                    <Span margin={7} />
                    シフト計画超過
                  </p>
                  <p>
                    （計画時点&nbsp;
                    <FormatRateSmall value={summary.laborCostRate.estimationBasedValue} />）
                  </p>
                </div>
              )}
              {isAttendanceManagementFlag &&
                data.shiftAttendance?.workingTime.overtimeWorkingMinutes != null && (
                  <div>
                    <p>
                      <Span margin={2} />
                      <HumanoidIcon />
                      &ensp; 労働時間超過
                    </p>
                    <p>
                      （+
                      {Math.floor(data.shiftAttendance?.workingTime.overtimeWorkingMinutes / 60)}時間
                      {data.shiftAttendance?.workingTime.overtimeWorkingMinutes % 60}分）
                    </p>
                  </div>
                )}
              {isSalesImprovementMeasuresFlag && (
                <div>
                  <p>
                    <Span margin={1} />
                    <YenIcon />
                    &ensp; 売上未達成
                  </p>
                  <p>
                    （
                    <FormatRateSmall value={summary.sales.targetAchievementRate} />）
                  </p>
                </div>
              )}
            </FactorWrapper>
          )}
        </LeftPane>
        {!isLaborCostNoAuthority && (
          <RightPane>
            {costItems.map((item, index) => (
              <RightPaneContent key={`key_${index}`}>
                <ContentWrapper>
                  <SmallText>{item.costItemName}</SmallText>
                  <MediumText>
                    {item.value != null ? <FormatYenMedium value={item.value} /> : '¥-'}
                  </MediumText>
                </ContentWrapper>
              </RightPaneContent>
            ))}
            {shiftLaborCost != null && (
              <RightPaneContent>
                <ContentWrapper>
                  <SmallText>Airシフト連携人件費</SmallText>
                  <SmallText>
                    <FormatYenMedium value={shiftLaborCost.total.actualValue} />
                    <span>
                      　(シフト上　
                      <FormatYenSmall value={shiftLaborCost.total.plannedValue} />)
                    </span>
                  </SmallText>
                </ContentWrapper>
                {shiftLaborCost.details.map((item, index) => (
                  <ContentWrapper key={`key_${index}`}>
                    <SmallTextSub>{item.shiftLaborCostName}</SmallTextSub>
                    <SmallText>
                      <FormatYenSmall value={item.actualValue} />
                      {item.plannedValue != null ? (
                        <span>
                          　(シフト上　
                          <FormatYenSmall value={item.plannedValue} />)
                        </span>
                      ) : null}
                    </SmallText>
                  </ContentWrapper>
                ))}
              </RightPaneContent>
            )}
          </RightPane>
        )}
      </TopPane>
    );
  }
}

export default DailyTopPane;

const TopPane = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LeftPane = styled.div`
  width: 40%;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
`;

const RightPane = styled.div`
  width: 50%;
`;

const LeftPaneTitle = styled.p`
  font-size: 14px;
  width: 40%;
`;

const LeftPaneContent = styled.div`
  width: 60%;
  text-align: right;
  margin-bottom: 24px;
`;

const RightPaneContent = styled.div`
  border-bottom: solid 1px ${lightgray};
  padding-bottom: 8px;
  :not(:first-child) {
    padding-top: 14px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  :not(:first-child) {
    margin-top: 12px;
  }
`;

const SmallText = styled.p`
  font-size: 12px;
`;

const MediumText = styled.p`
  font-size: 14px;
`;

const SmallTextSub = styled(SmallText)`
  margin-left: 24px;
`;

const ColoringRed = styled.p<{ isOver: boolean }>`
  ${props => props.isOver && `color: ${red};`}
`;

const ColoringRedGreen = styled.p<{ isOver?: boolean }>`
  color: ${props => (props.isOver != null ? (props.isOver ? red : green) : '')};
`;

const FactorWrapper = styled.div`
  background-color: ${verylightgray};
  width: 100%;
  padding: 18px 16px 20px;
  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    :not(:last-child) {
      margin-bottom: 19px;
    }
    p {
      display: flex;
      align-items: center;
    }
    h4 {
      font-weight: 600;
    }
    button {
      background: none;
      border: none;
      color: ${textLinkColor};
      font-size: 12px;
      font-weight: 400;
      cursor: pointer;
    }
  }
`;

const Span = styled.span<{ margin: number }>`
  margin-left: ${props => props.margin}px;
`;

const _genShowModalLog = () => {
  return genGaLog('labor_cost_analysis', 'labor_cost_analysis_detail_link', 'open', {}, {}, 'click');
};
