import React from 'react';
import styled from 'styled-components';
import Modal from '../../../../components/common/molecules/Airkit/AirModal';
import TimeIcon from '../../../../icons/TimeIconGray.svg';
import HumanoidIcon from '../../../../icons/HumanoidIconGray.svg';
import YenIcon from '../../../../icons/YenIconGray.svg';
import { black, textLinkColor } from '../../../../constants/colors';
import { Link } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import { track } from '../../../../modules/logging';
import { genGaLog } from '../../../../gaLogger';

type Props = {
  onClickClose: () => void;
  onClickTargetSetting: () => void;
  readonly track: typeof track;
};

/**
 * 人件費分析の要因凡例モーダル
 */
const FactorLegendModal: React.FC<Props> = props => {
  const { onClickClose, onClickTargetSetting, track } = props;

  return (
    <Modal
      title="目標未達成の要因について"
      noFooter={true}
      onClose={() => {
        onClickClose();
        track(_genCloseModalLog());
      }}
    >
      <Waypoint
        onEnter={() => {
          track(_genImpressionModalLog());
        }}
      >
        <Wrapper>
          <Text>
            人件費率目標が未達成だった場合に、考えられる要因を表示しています。改善のヒントとしてご利用ください。
          </Text>
          <Text marginTop={14}>
            ※未達成要因は、人件費目標を設定している場合に表示されます。（
            <Link
              to={'/set_target/'}
              onClick={() => {
                onClickTargetSetting();
                track(_genOpenTargetSettingLinkLog());
              }}
            >
              目標を設定する
            </Link>
            ）
          </Text>
          <Text>※「シフト計画超過」「労働時間超過」はAirシフトで勤怠管理をしていると表示されます。</Text>
          <SectionTitle>
            <StyledTimeIcon />
            シフト計画超過
          </SectionTitle>
          <LeadWrapper>
            <Text>シフト時点での想定人件費率が、すでに目標を超過していました。</Text>
            <Text>（想定人件費率は、確定シフトで計算した人件費と、売上目標から算出しています。）</Text>
          </LeadWrapper>
          <SectionTitle>
            <StyledHumanoidIcon />
            労働時間超過
          </SectionTitle>
          <LeadWrapper>
            <Text>シフトよりも打刻実績が多く、人件費率目標を超過しています。</Text>
            <Text>オペレーション効率化、労働時間の意識づけなどの対策があります。</Text>
            <Text>※Airシフトで打刻を行うと表示されます。</Text>
          </LeadWrapper>
          <SectionTitle>
            <StyledYenIcon />
            売上未達成
          </SectionTitle>
          <LeadWrapper>
            <Text>売上が目標達成率95％未満と想定より低くなったため、人件費率目標を超過しています。</Text>
            <Text>早上げや出勤を遅らせるなどの対策があります。</Text>
          </LeadWrapper>
        </Wrapper>
      </Waypoint>
    </Modal>
  );
};

export default FactorLegendModal;

const Wrapper = styled.div`
  padding: 32px 38px 44px;
`;

const Text = styled.p<{ marginTop?: number }>`
  font-size: 14px;
  color: ${black};
  font-weight: 400;
  line-height: 22px;
  margin-top: ${props => props.marginTop != null && `${props.marginTop}px`};
  a {
    color: ${textLinkColor};
  }
`;

const SectionTitle = styled.h4`
  font-size: 16px;
  color: ${black};
  font-weight: 600;
  margin: 34px 0 8px;
  display: flex;
  align-items: center;
`;

const LeadWrapper = styled.div`
  margin-left: 23px;
`;

const StyledTimeIcon = styled(TimeIcon)`
  margin-right: 8px;
`;

const StyledHumanoidIcon = styled(HumanoidIcon)`
  margin-right: 12px;
`;

const StyledYenIcon = styled(YenIcon)`
  margin-right: 10px;
`;

const _genImpressionModalLog = () => {
  return genGaLog(
    'labor_cost_analysis',
    'labor_cost_analysis_set_target_link_modal',
    'impression',
    {},
    {},
    'impression'
  );
};

const _genOpenTargetSettingLinkLog = () => {
  return genGaLog(
    'labor_cost_analysis',
    'labor_cost_analysis_set_target_link_modal',
    'open',
    {},
    {},
    'click'
  );
};

const _genCloseModalLog = () => {
  return genGaLog(
    'labor_cost_analysis',
    'labor_cost_analysis_set_target_link_modal',
    'close',
    {},
    {},
    'click'
  );
};
