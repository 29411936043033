// カレンダー詳細
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import ClickOutside from 'react-click-outside';
import ZIndex from '../../../../../constants/z-index';
import Sunny from '../../../../../icons/sunGray.svg';
import Cloudy from '../../../../../icons/cloudGray.svg';
import Rainy from '../../../../../icons/rainGray.svg';
import Snow from '../../../../../icons/snowGray.svg';
import Sleet from '../../../../../icons/sleetGray.svg';
import ArrowUp from '../../../../../icons/arrow_up.svg';
import ArrowDown from '../../../../../icons/arrow_down.svg';
import {
  baseFontSize,
  prefixUnit,
  postfixUnit,
  LocaleInteger,
  Decimal,
} from '../../../../../components/common/atoms/Number';
import { uploadBorderColor, white, airGray, black, red, blue } from '../../../../../constants/colors';
import { SalesData } from '../../../../../typedef/api/StoreIndices';
import Big from 'big.js';
import { formatter, mclDayjs } from '../../../../../helpers/mclDate';
import { lessThanOrEqualTo } from '../../../../../helpers/numberHelper';

type Props = {
  readonly className?: string;
  readonly onClick?: () => void;
  readonly isOpen: boolean;
  readonly isRight: boolean;
  readonly salesData: SalesData;
};

const big = Big();
big.RM = 0; // Round modeを切り捨てに設定
const FormatYen = baseFontSize(20)(prefixUnit('¥')(LocaleInteger));
const FormatSmallYen = baseFontSize(16)(prefixUnit('¥')(LocaleInteger));
const FormatPercentDiff = baseFontSize(12)(postfixUnit('%')(Decimal));
const FormatVisitorNum = baseFontSize(16)(postfixUnit('人')(LocaleInteger));

class CalendarDetail extends PureComponent<Props> {
  _handleToggleSelectBox = (onClick?: Function) => {
    onClick && onClick();
  };

  WeatherCell = (weather?: string | null) => {
    switch (weather) {
      case '晴':
        return (
          <CommonWetherCell>
            <Sunny />
          </CommonWetherCell>
        );
      case '曇':
        return (
          <CommonWetherCell>
            <Cloudy />
          </CommonWetherCell>
        );
      case '雨':
        return (
          <CommonWetherCell>
            <Rainy />
          </CommonWetherCell>
        );
      case '雪':
        return (
          <CommonWetherCell>
            <Snow />
          </CommonWetherCell>
        );
      case 'みぞれ':
        return (
          <CommonWetherCell>
            <Sleet />
          </CommonWetherCell>
        );
      default:
        return null;
    }
  };

  ArrowCell = (target: number | string, goal?: number | null, lastYearAchievement?: number | null) => {
    if (goal != null) {
      return lessThanOrEqualTo(target, goal) ? <StyledArrowUp /> : <StyledArrowDown />;
    }

    if (lastYearAchievement != null) {
      return lessThanOrEqualTo(target, lastYearAchievement) ? <StyledArrowUp /> : <StyledArrowDown />;
    }

    if (big(target).lt(100)) {
      return <StyledArrowDown />;
    }
    return <StyledArrowUp />;
  };

  render() {
    const { isRight, isOpen, salesData } = this.props;
    return (
      isOpen && (
        <ClickOutside
          className={this.props.className}
          onClickOutside={() => this._handleToggleSelectBox(this.props.onClick)}
        >
          <Balloon isRight={isRight}>
            <Contents>
              <DateWrapper>
                {mclDayjs(salesData.businessDate).format(formatter.monthDay)}
                <WeekDay>({mclDayjs(salesData.businessDate).format(formatter.week)})</WeekDay>
                <IconWrapper>{this.WeatherCell(salesData.weather)}</IconWrapper>
                <TemperatureWrapper>
                  <span>{salesData.maximumTemperature}&#8451;</span>
                  <span>{salesData.minimumTemperature}&#8451;</span>
                </TemperatureWrapper>
              </DateWrapper>
              <SalesWrapper>
                <TitleText fontSize={12}>売上</TitleText>
                <ValueWrapper>
                  <FormatYen value={salesData.sales} />
                </ValueWrapper>
              </SalesWrapper>
              <SalesGoalRateWrapper>
                <TitleText fontSize={11}>目標達成率</TitleText>
                <ValueWrapper>
                  {salesData.goalSalesRate != null ? (
                    <React.Fragment>
                      <FormatPercentDiff value={salesData.goalSalesRate} />
                      {this.ArrowCell(salesData.goalSalesRate)}
                    </React.Fragment>
                  ) : (
                    '-%'
                  )}
                </ValueWrapper>
              </SalesGoalRateWrapper>
              <SalesLastYearWeekAverageRateWrapper>
                <TitleText fontSize={11}>前年同曜日比</TitleText>
                <ValueWrapper>
                  {salesData.salesLastYearWeekAverageRate != null ? (
                    <React.Fragment>
                      <FormatPercentDiff value={salesData.salesLastYearWeekAverageRate} />
                      {this.ArrowCell(salesData.salesLastYearWeekAverageRate)}
                    </React.Fragment>
                  ) : (
                    '-%'
                  )}
                </ValueWrapper>
              </SalesLastYearWeekAverageRateWrapper>
              <VisitorNumWrapper>
                <TitleText fontSize={12}>客数</TitleText>
                <ValueWrapper>
                  {salesData.visitorNum != null ? (
                    <React.Fragment>
                      <FormatVisitorNum value={salesData.visitorNum} />
                      {(salesData.goalVisitorNum != null ||
                        salesData.lastYearWeekAverageVisitorNum != null) &&
                        this.ArrowCell(
                          salesData.visitorNum,
                          salesData.goalVisitorNum,
                          salesData.lastYearWeekAverageVisitorNum
                        )}
                    </React.Fragment>
                  ) : (
                    <FormatVisitorNum value={0} />
                  )}
                </ValueWrapper>
              </VisitorNumWrapper>
              <CustomerPaymentWrapper>
                <TitleText fontSize={12}>客単価</TitleText>
                <ValueWrapper>
                  {salesData.customerPayment != null ? (
                    <React.Fragment>
                      <FormatSmallYen value={salesData.customerPayment} />
                      {(salesData.goalCustomerPayment != null ||
                        salesData.lastYearWeekAverageCustomerPayment != null) &&
                        this.ArrowCell(
                          salesData.customerPayment,
                          salesData.goalCustomerPayment,
                          salesData.lastYearWeekAverageCustomerPayment
                        )}
                    </React.Fragment>
                  ) : (
                    <FormatSmallYen value={0} />
                  )}
                </ValueWrapper>
              </CustomerPaymentWrapper>
            </Contents>
          </Balloon>
        </ClickOutside>
      )
    );
  }
}

const Balloon = styled.div<{ isRight: boolean }>`
  position: absolute;
  z-index: ${ZIndex.modalPortal};
  width: 200px;
  height: 250px;
  background-color: ${white};
  border: 2px solid ${uploadBorderColor};
  border-radius: 6px;
  left: ${props => (props.isRight ? '-180px' : '80%')};
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    left: ${props => (props.isRight ? '175' : '5')}px;
    top: -8px;
    border-right: 8px solid transparent;
    border-bottom: 8px solid ${uploadBorderColor};
    border-left: 8px solid transparent;
  }
  &::after {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    left: ${props => (props.isRight ? '175' : '5')}px;
    top: -5px;
    border-right: 8px solid transparent;
    border-bottom: 8px solid ${white};
    border-left: 8px solid transparent;
  }
`;

const Contents = styled.div`
  display: flex;
  flex-flow: column;
  padding: 16px;
`;

const DateWrapper = styled.div`
  display: flex;
  font-size: 12px;
  padding: 5px;
  width: 170px;
  height: 22px;
  align-items: center;
  font-weight: 600;
  background-color: ${airGray};
`;

const WeekDay = styled.span`
  font-size: 12px;
  margin-right: 10px;
`;

const SalesWrapper = styled.div`
  display: flex;
  margin-top: 15px;
`;

const ValueWrapper = styled.div`
  margin-left: auto;
  font-weight: 600;
  display: flex;
  align-items: center;
`;

const TitleText = styled.div<{ fontSize: number }>`
  font-size: ${props => props.fontSize}px;
`;

const SalesGoalRateWrapper = styled.div`
  display: flex;
  margin-top: 15px;
  margin-left: 10px;
  align-items: center;
  justify-content: center;
`;

const SalesLastYearWeekAverageRateWrapper = styled.div`
  display: flex;
  margin-top: 13px;
  margin-left: 10px;
  align-items: center;
  justify-content: center;
`;

const VisitorNumWrapper = styled.div`
  display: flex;
  margin-top: 15px;
  padding-top: 5px;
  border-top: 1px dotted ${uploadBorderColor};
  align-items: center;
  justify-content: center;
`;

const CustomerPaymentWrapper = styled.div`
  display: flex;
  margin-top: 5px;
  padding-top: 5px;
  border-top: 1px dotted ${uploadBorderColor};
  align-items: center;
  justify-content: center;
`;

const StyledArrowUp = styled(ArrowUp)`
  margin-left: 8px;
`;

const StyledArrowDown = styled(ArrowDown)`
  margin-left: 8px;
`;

const IconWrapper = styled.div`
  width: 16px;
  margin-top: 4px;
  margin-right: 4px;
`;

const CommonWetherCell = styled.span`
  > svg {
    > g {
      fill: ${black};
    }
    > path {
      fill: ${black};
    }
  }
`;

const TemperatureWrapper = styled.div`
  > span {
    &:first-child {
      color: ${red};
      &::after {
        /* 最高気温と最低気温の間のスラッシュ */
        content: '/';
        color: ${black};
        margin: 0 2px;
      }
    }
    &:last-child {
      color: ${blue};
    }
  }
  margin-left: 2px;
`;

export default CalendarDetail;
