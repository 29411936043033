import React from 'react';
import styled from 'styled-components';
import Modal from '../../../../components/common/molecules/Airkit/AirModal';
import RegiImage from '../../../../icons/RegiImage.svg';
import AirRegiLogo from '../../../../icons/airRegiLogo.svg';
import RegiQRCode from '../../../../icons/RegiQRCode.svg';
import AppStore from '../../../../icons/AppStore.svg';
import { airblue, black, textLinkColor, uploadBorderColor } from '../../../../constants/colors';
import { Link } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import { track } from '../../../../modules/logging';
import { genGaLog } from '../../../../gaLogger';
import { airRegiAppAdjust } from '../../../../constants/externalLink';

type Props = {
  onClickClose: () => void;
  readonly track: typeof track;
};

/**
 * 売上の入力方法モーダル
 */
const SalesInputMethodModal: React.FC<Props> = props => {
  const { onClickClose, track } = props;

  return (
    <Modal
      title="売上の入力方法"
      noFooter={true}
      onClose={() => {
        onClickClose();
        track(_genCloseModalLog());
      }}
    >
      <Waypoint
        onEnter={() => {
          track(_genImpressionModalLog());
        }}
      >
        <Wrapper>
          <TopText>
            Airメイトに売上データを入力または連携すると、人件費率の自動算出のほか、さまざまな分析を行うことができます。
          </TopText>
          <SectionTitle marginTop={32}>日報から入力</SectionTitle>
          <Text>
            「
            <Link
              to="/daily_report_list"
              onClick={() => {
                track(_genOpenLinkLog('labor_cost_analysis_daily_report_link_modal'));
              }}
            >
              日報
            </Link>
            」から日々の売上を入力できます。
          </Text>
          <Text>
            ※日報に売上入力欄が出ていない場合は、「売上取込設定」で「日報から入力」を選択してください。
          </Text>
          <SectionTitle marginTop={40}>CSVデータを取込</SectionTitle>
          <Text>
            「データ出入力」＞「
            <Link
              to="/past_data_import"
              onClick={() => {
                track(_genOpenLinkLog('labor_cost_analysis_data_import_setting_link_modal'));
              }}
            >
              売上一括取込
            </Link>
            」からCSVファイルでまとめてアップロードできます。
          </Text>
          <SectionTitle marginTop={40}>Airレジから自動連携</SectionTitle>
          <Text>Airレジを利用して会計を行うと、売上データが自動で連携されます。</Text>
          <BottomWrapper>
            <RegiImage role="img" aria-label="Airレジのイメージ" />
            <LogoWrapper>
              <p>０円でカンタンに使えるPOSレジアプリ</p>
              <StyledAirRegiLogo role="img" aria-label="Airレジのロゴ" />
            </LogoWrapper>
            <AppWrapper>
              <div>
                <StyledRegiQRCode role="img" aria-label="AppStoreのQR" />
                <a href={airRegiAppAdjust} target="_blank" rel="noreferrer">
                  <AppStore role="img" aria-label="AppStoreの画像" />
                </a>
              </div>
              <p>Airレジアプリのダウンロードはこちらから</p>
            </AppWrapper>
          </BottomWrapper>
        </Wrapper>
      </Waypoint>
    </Modal>
  );
};

export default SalesInputMethodModal;

const Wrapper = styled.div`
  padding: 50px 48px;
`;

const TopText = styled.p`
  font-size: 16px;
  color: ${black};
  font-weight: 400;
  line-height: 26px;
`;

const SectionTitle = styled.h4<{ marginTop: number }>`
  font-size: 16px;
  color: ${black};
  font-weight: 600;
  margin: ${props => props.marginTop}px 0 8px;
`;

const Text = styled.p`
  font-size: 16px;
  color: ${black};
  font-weight: 400;
  line-height: 26px;
  a {
    cursor: pointer;
    color: ${textLinkColor};
  }
`;

const BottomWrapper = styled.div`
  width: 100%;
  height: 100px;
  border: solid 1px ${uploadBorderColor};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
`;

const LogoWrapper = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 47px 0 26px;
  p {
    font-size: 12px;
    color: ${airblue};
    font-weight: 600;
  }
`;

const StyledAirRegiLogo = styled(AirRegiLogo)`
  width: 178px;
  margin-top: 8px;
`;

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 6px;
  p {
    margin-top: 8px;
    font-size: 10px;
    color: ${black};
    font-weight: 600;
  }
  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const StyledRegiQRCode = styled(RegiQRCode)`
  margin-right: 16px;
`;

const _genImpressionModalLog = () => {
  return genGaLog('labor_cost_analysis', 'labor_cost_analysis_link', 'impression', {}, {}, 'impression');
};

const _genOpenLinkLog = (feature: string) => {
  return genGaLog('labor_cost_analysis', feature, 'open', {}, {}, 'click');
};

const _genCloseModalLog = () => {
  return genGaLog(
    'labor_cost_analysis',
    'labor_cost_analysis_data_import_setting_link_modal',
    'close',
    {},
    {},
    'click'
  );
};
