import { LocalDateObj, formatter, mclDayjs, parser, LocalYearMonthObj, MclDayjs } from './mclDate';
import _ from 'lodash';
import { BatchProcessedDate } from '../typedef/BatchProcessedDate';
import { ApiState, API_STATE_COMPLETED } from '../typedef/api/Utility';
import { ExistMonthResultList } from '../typedef/api/StoreIndices';

/**
 *　引数で渡された日付に+1day-1monthした日付を最新月として過去13ヶ月分の年月を返す。
 * @param date　LocalDateObj型の日付
 * @returns　文字列YYYY/M形式でArrayを返す
 */
export const getThirteenMonthsPeriod = (date: LocalDateObj): Array<string> => {
  const dateMclDayjs = parser.fromDateObject(date);
  // 引数で受け取った日付+1day-1monthが最新月になる。
  const baseDate: LocalDateObj = dateMclDayjs.add(1, 'day').add(-1, 'month').toLocalDateObj();
  const endYM = parser.fromDateObject(baseDate);
  // 最新月までの１３ヶ月分
  const beginYM = endYM.add(-12, 'month');
  const objectArray = mclDayjs.getRangeInclusive(beginYM.toLocalYearMonthObj(), endYM.toLocalYearMonthObj());
  return Array.from(objectArray)
    .map(yearMonth => parser.fromYearMonthObject(yearMonth).format(formatter.mapiDefaultYearMonthNotFixed))
    .reverse();
};

/**
 * JST時間を受け取り営業開始時間を基準に開始時刻 / 終了時刻を返す関数
 * @param {string} date JST時間
 * @param {number} businessStartHour 営業開始時間
 */
export const stringDateToHour = (date: string, businessStartHour: number): number => {
  const mclDayjsDate = mclDayjs(date);
  return (mclDayjsDate.hour() + mclDayjsDate.minute() / 60 + 24 - businessStartHour) % 24;
};

/**
 * 営業日切り替え時間を基準に+24時間分のマスを作るための配列を生成する関数
 * @param {number} startHour 営業日切り替え時間
 */
export const timeRange = (startHour: number) => {
  return _.range(startHour, startHour + 24);
};

/**
 * 2013年1月1日から指定した年月までの年月を算出する関数
 * @param {BatchProcessedDate} batchProcessedDate バッチ年月日
 */
export const displayYearMonthList = (batchProcessedDate: BatchProcessedDate): LocalYearMonthObj[] => {
  const endYM = parser.fromDateObject(batchProcessedDate).toLocalYearMonthObj();
  const beginYM = parser.fromDateObject({ year: 2013, month: 1, day: 1 }).toLocalYearMonthObj();
  return mclDayjs.getRangeInclusive(beginYM, endYM);
};

/**
 * 2013年1月1日から現在年月とデータの存在有無が入ったオブジェクトの配列を返す関数
 * @param {BatchProcessedDate} batchProcessedDate バッチ年月日
 * @param {ReadonlyArray<{ yearMonth: string; isExist: boolean }>} existMonthList
 * @param {Array<{ yearMonth: string; isExist: boolean }>} monthList
 */
export const generateExistMonthList = (
  batchProcessedDate: BatchProcessedDate,
  existMonthList: ReadonlyArray<{ yearMonth: string; isExist: boolean }>,
  monthList: Array<{ yearMonth: string; isExist: boolean }>
) => {
  const endYM = parser.fromDateObject(batchProcessedDate);
  const beginYM = parser.fromDateObject({ year: 2013, month: 1, day: 1 });
  const sortList = _.sortBy(existMonthList, ['yearMonth']).reverse();
  sortList.forEach(element => {
    const yearMonth = mclDayjs(element.yearMonth, formatter.mapiYearMonth);
    if (yearMonth != null) {
      // 2013年1月から現在まで表示する
      if (yearMonth.isBetween(beginYM, endYM, 'date', '[]')) {
        monthList.push(element);
      }
    }
  });
};

/**
 * 成績画面の日別詳細モーダルで、引数で指定された日が日付移動可能な日なのかをチェックし、結果を返す
 * @param {LocalDateObj} checkDate チェックしたい日付
 * @param {ApiState<ExistMonthResultList>} existMonthResultList 選択不可年月リスト
 * @returns true:可能 false:不可能
 */
export const isMoveDateStoreIndices = (
  checkDate: LocalDateObj,
  existMonthResultList: ApiState<ExistMonthResultList>
): boolean => {
  const date = parser.fromDateObject(checkDate);
  const dateTo = mclDayjs().endOf('month');
  const dateFrom = parser.fromDateObject({ year: 2013, month: 1, day: 1 });
  // 成績画面で表示可能な範囲内かを判定
  if (date.isBetween(dateFrom, dateTo, 'date', '[]')) {
    // 表示できる成績データがある期間を判定
    if (existMonthResultList.type === API_STATE_COMPLETED) {
      const currentYearMonth = mclDayjs();
      const yyyyMM = date.format(formatter.mapiYearMonth);
      const keyIndex = existMonthResultList.payload.existMonthList.findIndex(
        item => yyyyMM === item.yearMonth
      );

      if (keyIndex >= 0 && existMonthResultList.payload.existMonthList[keyIndex].isExist) {
        return true;
      } else if (
        keyIndex >= 0 &&
        !existMonthResultList.payload.existMonthList[keyIndex].isExist &&
        existMonthResultList.payload.existMonthList[keyIndex].yearMonth ===
          currentYearMonth.format(formatter.mapiYearMonth)
      ) {
        // 月初は今月分のデータが生成されていないが、成績は表示可能なため選択可能状態(true)にする
        return true;
      }
    }
  }
  return false;
};

/** 過去月から来月までの年月の配列を返す関数
 * @param {string} minMonth
 * @param {BatchProcessedDate} batchProcessedDate
 * @returns 文字列'YYYY/MM'が格納された配列
 */
export const getYearMonthFromPastToNextMonth = (minMonth, batchProcessedDate) => {
  const beginYM = minMonth;
  const batchDate: BatchProcessedDate = batchProcessedDate;
  const endYM = parser.fromDateObject(batchDate).add(1, 'month');
  const objectArray = mclDayjs.getRangeInclusive(
    beginYM != null
      ? mclDayjs(beginYM, formatter.mapiYearMonth).toLocalYearMonthObj()
      : parser.fromDateObject(batchDate).toLocalYearMonthObj(),
    endYM.toLocalYearMonthObj()
  );

  // YYYY/MM形式で降順の配列
  const yearMonthList = Array.from(objectArray)
    .map(yearMonth => parser.fromYearMonthObject(yearMonth).format(formatter.mapiDefaultYearMonthNotFixed))
    .reverse()
    .map(yearMonth => {
      const formatDate = mclDayjs(yearMonth, formatter.mapiDefaultYearMonthNotFixed);
      return formatDate != null && formatDate.isValid()
        ? formatDate.format(formatter.mapiDefaultYearMonth)
        : '';
    });
  return yearMonthList;
};

/** 指定した月数から指定した月数分だけ当月と過去の年月オブジェクトの入った配列を返す関数
 * @param {MclDayjs} batchDate
 * @param {number} months
 * @returns yearとmonthが格納されたオブジェクトの配列
 */
export const getYearMonthObj = (batchDateMclDayjs, months) => {
  let monthList: Array<LocalYearMonthObj> = [];
  for (let i = 0; i < months; i++) {
    monthList.push(batchDateMclDayjs.add(-i, 'month').toLocalYearMonthObj());
  }
  return monthList;
};

/** 月末までの年月日のリストを作成して返す関数
 * @param {string} businessMonth
 * @return yearとmonthとdayが格納されたオブジェクトの配列
 */
export const getDatesUntilEndOfMonth = businessMonth => {
  const dateEndOfMonth = mclDayjs(businessMonth, formatter.mapiDefaultYearMonthNotFixed).endOf('month');
  const dayList: Array<LocalDateObj> = [...Array(dateEndOfMonth.isValid() ? dateEndOfMonth.date() : 0)].map(
    (_, index) => {
      const day = index + 1;
      const result: LocalDateObj = { ...dateEndOfMonth.toLocalDateObj(), day };
      return result;
    }
  );
  return dayList;
};

/** フォーマットの違う年月のkeyとvalueが入ったオブジェクトを返す関数(Airカード連携コスト管理 / Airインボイス連携コスト管理画面用)
 * @param {array} useYearMonthList
 * @return keyとvalueが格納されたオブジェクトの配列 ex.[{key: "YYYY-MM",value: "YYYY/MM"},...]
 */
export const getYearMonthKeyAndValue = useYearMonthList => {
  const yearMonthList: Array<{ key: string; value: string }> = [];
  useYearMonthList.forEach(useYearMonth => {
    const yearMonth = mclDayjs(
      useYearMonth.useYearMonth != null ? useYearMonth.useYearMonth : useYearMonth.uploadYearMonth,
      formatter.mapiDefaultYearMonthNotFixed
    ).toLocalYearMonthObj();
    if (yearMonth != null) {
      yearMonthList.push({
        key: useYearMonth.useYearMonth != null ? useYearMonth.useYearMonth : useYearMonth.uploadYearMonth,
        value: parser.fromYearMonthObject(yearMonth).format(formatter.mapiDefaultYearMonthNotFixed),
      });
    }
  });
  return yearMonthList;
};

/** 選択日付が今月中か判定する関数
 * @param {{ dateFrom: LocalDateObj; dateTo: LocalDateObj } | null}selectedDate
 * @param {BatchProcessedDate} batchDate
 * @returns boolean
 */
export const isSelectedThisMonth = (
  selectedDate: { dateFrom: LocalDateObj; dateTo: LocalDateObj } | null,
  batchDate: BatchProcessedDate
): boolean => {
  if (selectedDate == null) {
    return false;
  }
  if (
    parser.fromDateObject(selectedDate.dateFrom).isSame(parser.fromDateObject(batchDate).startOf('month')) &&
    parser.fromDateObject(selectedDate.dateTo).isSame(parser.fromDateObject(batchDate))
  ) {
    return true;
  }
  return false;
};

/** 2013年1月から現在までの年月+指定した月数分の年月を取得し、keyとvalueに格納したオブジェクトの入った配列を返す関数
 * @param {number} 月数
 * @returns keyとvalueが格納されたオブジェクトの配列 ex.[{key: "YYYY/MM",value: "YYYY/MM"},...]
 */
export const constructSelectableDate = (months): Array<{ key: string; value: string }> => {
  // 2013年1月から現在までの年月+指定した月数分の年月を取得
  const maxYearMonth = mclDayjs().add(months, 'month');
  const minYearMonth = parser.fromDateObject({ year: 2013, month: 1, day: 1 });
  const diffYearMonth = Math.abs(minYearMonth.diff(maxYearMonth, 'months'));
  const selectableDate = [
    {
      key: minYearMonth.format(formatter.mapiDefaultYearMonth).toString(),
      value: minYearMonth.format(formatter.mapiDefaultYearMonth).toString(),
    },
  ];
  let selectableYYYYMM: string;

  // 降順表示になるようunshift
  for (let i = 0; i < diffYearMonth; i++) {
    selectableYYYYMM = minYearMonth
      .add(i + 1, 'month')
      .format(formatter.mapiDefaultYearMonth)
      .toString();
    selectableDate.unshift({ key: selectableYYYYMM, value: selectableYYYYMM });
  }

  return selectableDate;
};

/**
 * 開始日がカレンダー上で選択可能かどうか返す関数
 * @param {moment.Moment} date
 * @param {BatchProcessedDate} batchProcessedDate
 * @param {LocalDateObj} dateTo
 * @returns {boolen}
 */
export const isValidateDateFromRange = (
  date: moment.Moment,
  batchProcessedDate: BatchProcessedDate,
  dateTo: LocalDateObj
): boolean => {
  const convertedDate = mclDayjs(date.toISOString());
  const localDate = convertedDate.toLocalDateObj();
  const batchDate = parser.fromDateObject(batchProcessedDate).add(1, 'day');

  if (parser.fromDateObject(localDate).isAfter(batchDate)) {
    // 未来は選択不可能にする
    return true;
  }

  const isBetweenInclusive = parser
    .fromDateObject(dateTo)
    .isBetween(parser.fromDateObject(localDate), batchDate, 'date', '[]');
  return !isBetweenInclusive;
};

/**
 * 終了日がカレンダー上で選択可能かどうか返す関数
 * @param {moment.Moment} date
 * @param {BatchProcessedDate} batchProcessedDate
 * @param {LocalDateObj} dateFrom
 * @returns {boolean}
 */
export const isValidateDateToRange = (
  date: moment.Moment | null,
  batchProcessedDate: BatchProcessedDate,
  dateFrom: LocalDateObj
): boolean => {
  if (date != null) {
    const convertedDate = mclDayjs(date.toISOString());
    const localDate = convertedDate.toLocalDateObj();
    const batchDate = parser.fromDateObject(batchProcessedDate).add(1, 'day');

    const isBetweenInclusive = parser
      .fromDateObject(localDate)
      .isBetween(parser.fromDateObject(dateFrom), batchDate, 'date', '[]');

    return !isBetweenInclusive;
  } else {
    return true;
  }
};

/**
 * 起点日からから指定した年月まで過去の配列を生成する関数
 * @param {MclDayjs} start 起点日
 * @param {number} months 月数(当月も取得する場合は+1する)
 * @returns ["YYYY/M",...]
 */

export const buildPeriodStringArray = (start: MclDayjs, months: number) => {
  let date = start;
  let periodStringArray: Array<string> = [];

  for (let cnt = 0; cnt < months; cnt++) {
    let dateStr = `${date.year()}/${date.pureMonth()}`;
    date = date.subtract(1, 'months');
    periodStringArray.push(dateStr);
  }
  return periodStringArray;
};
