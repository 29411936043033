/**
 * 成績表画面の日別カレンダーに対応するコンテナ
 */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators, Action } from 'redux';
import styled from 'styled-components';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Template from '../../../components/common/templates';
import { ActivityIndicator } from '../../../components/common';
import FaqLink from '../../../components/common/FaqLink';
import SelectBox from '../../../components/common/atoms/SelectBox';
import MonthlySummary from './components/Indices/MonthlySummary';
import MonthSelectbox from './components/Indices/MonthSelectbox';
import Calendar from './components/Indices/CalendarContent';
import Tab from './components/common/Toggle';
import { State as ReduxState } from '../../../modules';
import { StoresData, UserData } from '../../../modules/user';
import { changeSelectIndicesType, changeSelectBannerType } from '../../../modules/stores';
import { track } from '../../../modules/logging';
import { actions as storeIndicesSummaryActions } from '../../../modules/storeIndices/storeIndicesSummary';
import {
  actions as existMonthResultListActions,
  State as ExistMonthResultListState,
} from '../../../modules/storeIndices/existMonthResultList';
import { selectedStoreSelector, judgeThisMonthSelector } from '../../../selectors/storeSelector';
import { storeCalendarIndicesStateSelector } from '../../../selectors/storeIndicesSelector';
import { BatchProcessedDate } from '../../../typedef/BatchProcessedDate';
import { IndexSummary, IndexAnalysis } from '../../../typedef/api/StoreIndices';
import * as AkrCode from '../../../typedef/AkrCode';
import { PlfGrant } from '../../../typedef/PlfGrant';
import {
  ApiState,
  API_STATE_INITIAL,
  API_STATE_STARTED,
  API_STATE_COMPLETED,
  API_STATE_FAILED,
} from '../../../typedef/api/Utility';
import { StoreList } from '../../../typedef/api/AllIndex';
import { storeIndexFaq } from '../../../constants/faqUrls';
import { INDICES_TYPE, BANNER_TYPE } from './storesConstants';
import { selectStoreData } from '../../../modules/storeIndices/ui';
import { genGaLog } from '../../../gaLogger';
import { actions as targetSettingActions } from '../../../modules/targetSetting/ui/index';
import * as TargetSettingPeriod from '../../../modules/targetSetting/ui/settingAllStoreTarget/targetSettingPeriod';
import { assertUnreachable } from '../../../helpers/util';
import { SELECTED_INDICES_TYPE_KEY_NAME } from '../../../constants/LocalStorage';
import { BadgeAcquiredInfo } from '../../../typedef/api/Badge';
import { actions as badgeListactions } from '../../../modules/badge';
import { actions as commonUiActions } from '../../../modules/uiConfig';
import { indexAnalysisDummyData, summaryDummyData } from '../../../constants/dummy';
import AirRegiAppealPopup from './components/Indices/AirRegiAppealPopup';
import { Waypoint } from 'react-waypoint';
import { actions as cardSettingActions } from '../../../modules/cardSetting/cardSetting';
import { CurrentMonthLastMonth } from '../../../typedef/api/CurrentMonthLastMonth';
import { CardStatusResponse } from '../../../typedef/api/CardSetting/CardStatus';
import { actions as currentMonthLastMonthActions } from '../../../modules/storeIndices/currentMonthLastMonth';
import { MclDayjs, formatter, mclDayjs, parser } from '../../../helpers/mclDate';
import { ConfigDailySalesList, DataImportType } from '../../../typedef/api/DailySales';
import { displayYearMonthList } from '../../../helpers/mclDateHelper';

type DispatchProps = {
  readonly fetchStoreIndicesSummaryStart: () => void;
  readonly selectedMonth: typeof existMonthResultListActions.selectedMonth;
  readonly track: typeof track;
  readonly fetchStoreIndicesAllData: typeof storeIndicesSummaryActions.fetchStoreIndicesAllData;
  readonly changeSelectIndicesType: typeof changeSelectIndicesType;
  readonly fetchExistMonthResultList: typeof existMonthResultListActions.fetchExistMonthResultList;
  readonly changePeriod: typeof targetSettingActions.changePeriod;
  readonly selectStore: typeof targetSettingActions.selectStore;
  readonly changeViewMode: typeof targetSettingActions.changeViewMode;
  readonly resetStoreIndices: () => void;
  readonly postBadgeList: typeof badgeListactions.postBadgeList;
  readonly unCheckedBadge: typeof commonUiActions.unCheckedBadge;
  readonly changeSelectBannerType: typeof changeSelectBannerType;
  readonly startFetchCardStatus: typeof cardSettingActions.startFetchCardStatus;
  readonly fetchCurrentMonthLastMonth: typeof currentMonthLastMonthActions.fetchCurrentMonthLastMonth;
};

type StateProps = {
  readonly userData: UserData | null;
  readonly storeCalendarIndicesState: ApiState<{
    summary: IndexSummary;
    analysis: IndexAnalysis;
    currentMonthLastMonth: CurrentMonthLastMonth;
    cardStatusData: CardStatusResponse;
  }>;
  readonly selectedStore?: StoresData;
  readonly selectedDate?: string | null;
  readonly batchProcessedDate: BatchProcessedDate;
  readonly isDisplayedStoreList: boolean;
  readonly isSelectedThisMonth: boolean;
  readonly selectedIndicesType: keyof typeof INDICES_TYPE;
  readonly existMonthResultList: ExistMonthResultListState;
  readonly plfGrant?: PlfGrant;
  readonly badgeAcquiredInfo: BadgeAcquiredInfo | null;
  isLoading: boolean;
  isLoaded: boolean;
  readonly selectedBannerType: BANNER_TYPE;
  readonly tutorialLoadingFlag: boolean;
};

type State = {
  isShowPopup: boolean;
};

type PassedProps = {
  readonly handleClickStoreListItem: (store: StoresData & StoreList) => void;
  readonly isSingleStore: boolean;
  readonly dailySalesStoreDataState: ApiState<ReadonlyArray<StoresData & ConfigDailySalesList> | undefined>;
};

type Props = Readonly<RouteComponentProps<{}> & DispatchProps & StateProps & PassedProps>;
const Content = ({
  selectedStore,
  storeCalendarIndicesState,
  selectedDate,
  tracking,
  plfGrant,
  isDisplayedStoreList,
  batchProcessedDate,
  firstEventDate,
  badgeAcquiredInfo,
  postBadgeList,
  unCheckedBadge,
  isSingleStore,
  tutorialLoadingFlag,
  showPopup,
  dataImportType,
}: {
  selectedStore?: StoresData;
  storeCalendarIndicesState: ApiState<{
    summary: IndexSummary;
    analysis: IndexAnalysis;
    currentMonthLastMonth: CurrentMonthLastMonth;
    cardStatusData: CardStatusResponse;
  }>;
  selectedDate?: string | null;
  tracking: typeof track;
  plfGrant?: PlfGrant;
  isDisplayedStoreList: boolean;
  batchProcessedDate: BatchProcessedDate;
  firstEventDate?: string | null;
  badgeAcquiredInfo: BadgeAcquiredInfo | null;
  postBadgeList: typeof badgeListactions.postBadgeList;
  unCheckedBadge: typeof commonUiActions.unCheckedBadge;
  isFirstDayOfMonth?: boolean;
  isSelectedThisYear?: boolean;
  isSelectedThisMonth?: boolean;
  isSingleStore?: boolean;
  tutorialLoadingFlag: boolean;
  showPopup: (isShow: boolean) => void;
  dataImportType: DataImportType | null;
}) => {
  let yearMonth: MclDayjs | undefined;
  if (selectedDate != null) {
    yearMonth = mclDayjs(selectedDate, formatter.mapiYearMonth);
  }

  switch (storeCalendarIndicesState.type) {
    case API_STATE_INITIAL:
    case API_STATE_STARTED:
      return (
        <IndexWrapper>
          <Template.Center>
            <ActivityIndicator />
          </Template.Center>
        </IndexWrapper>
      );
    case API_STATE_COMPLETED:
      const { analysis } = storeCalendarIndicesState.payload;
      return (
        <React.Fragment>
          {selectedStore != null && yearMonth !== undefined && (
            <IndexWrapper id="store_indices_calendar">
              <Calendar
                indexAnalysisData={analysis}
                yearMonth={yearMonth.toLocalYearMonthObj()}
                tracker={tracking}
                plfGrant={plfGrant}
                selectedAkrCode={selectedStore.akrCode}
                isDisplayedStoreList={isDisplayedStoreList}
                batchProcessedDate={batchProcessedDate}
                firstEventDate={firstEventDate}
                badgeAcquiredInfo={badgeAcquiredInfo}
                postBadgeList={postBadgeList}
                unCheckedBadge={unCheckedBadge}
                selectedStore={selectedStore}
                isSingleStore={isSingleStore}
                tutorialLoadingFlag={tutorialLoadingFlag}
                showPopup={showPopup}
                isDummy={false}
                dataImportType={dataImportType}
              />
            </IndexWrapper>
          )}
        </React.Fragment>
      );
    case API_STATE_FAILED:
      return (
        <React.Fragment>
          {selectedStore != null && yearMonth !== undefined && (
            <IndexWrapper id="store_indices_calendar">
              <Calendar
                indexAnalysisData={indexAnalysisDummyData(yearMonth?.toLocalYearMonthObj())}
                yearMonth={yearMonth?.toLocalYearMonthObj()}
                tracker={tracking}
                plfGrant={plfGrant}
                selectedAkrCode={selectedStore.akrCode}
                isDisplayedStoreList={isDisplayedStoreList}
                batchProcessedDate={batchProcessedDate}
                firstEventDate={firstEventDate}
                badgeAcquiredInfo={badgeAcquiredInfo}
                postBadgeList={postBadgeList}
                unCheckedBadge={unCheckedBadge}
                selectedStore={selectedStore}
                isSingleStore={isSingleStore}
                tutorialLoadingFlag={tutorialLoadingFlag}
                showPopup={showPopup}
                isDummy={true}
                dataImportType={dataImportType}
              />
            </IndexWrapper>
          )}
        </React.Fragment>
      );
    default:
      assertUnreachable();
      return <React.Fragment />;
  }
};

const IndexContent = ({
  storeCalendarIndicesState,
  isDisplayedStoreList,
  onClick,
  onClickDailyReportList,
  onClickCostPredictionSetting,
  selectedStore,
  userData,
  tracking,
  selectedBannerType,
  changeBannerType,
  isShowPopup,
  dataImportType,
}: {
  storeCalendarIndicesState: ApiState<{
    summary: IndexSummary;
    analysis: IndexAnalysis;
    currentMonthLastMonth: CurrentMonthLastMonth;
    cardStatusData: CardStatusResponse;
  }>;
  isDisplayedStoreList: boolean;
  readonly onClick: () => void;
  readonly onClickDailyReportList: (logType: 'laborCostRate' | 'foodCostRate') => void;
  readonly onClickCostPredictionSetting: (logType: 'laborCostRate' | 'foodCostRate') => void;
  selectedStore?: StoresData;
  userData: UserData | null;
  tracking: typeof track;
  isFirstDayOfMonth: boolean;
  selectedBannerType: BANNER_TYPE;
  changeBannerType: typeof changeSelectBannerType;
  isSelectedThisYear?: boolean;
  isSelectedThisMonth?: boolean;
  isShowPopup: boolean;
  dataImportType: DataImportType | null;
}) => {
  switch (storeCalendarIndicesState.type) {
    case API_STATE_INITIAL:
    case API_STATE_STARTED:
      return <React.Fragment />;

    case API_STATE_COMPLETED:
      const { summary, analysis, currentMonthLastMonth, cardStatusData } = storeCalendarIndicesState.payload;
      const {
        currentMonthSales,
        currentMonthFoodCost,
        currentMonthLaborCost,
        lastMonthFoodCost,
        lastMonthLaborCost,
        lastMonthSales,
      } = currentMonthLastMonth.pastTwoMonthsPerformance;
      // 前月 or 当月の売上が1円以上か
      const hasCurrentLastSales = currentMonthSales > 0 || lastMonthSales > 0;
      // 前月 or 当月の売上が1円以上 & 前月 & 当月の人件費日次コスト入力が0円 & シフト未連携
      const isShowShiftModalLink =
        hasCurrentLastSales &&
        currentMonthLaborCost === 0 &&
        lastMonthLaborCost === 0 &&
        !selectedStore?.isShiftUse;
      // 前月 or 当月の売上が1円以上 & 前月 & 当月の原価日次コスト入力が0円 & 過去にカード契約なし
      const isShowCardModalLink =
        hasCurrentLastSales &&
        currentMonthFoodCost === 0 &&
        lastMonthFoodCost === 0 &&
        !cardStatusData.cardStatus.isContracted;
      return (
        <React.Fragment>
          <IndexWrapper id="store_indices_monthly_summary">
            <MonthlySummary
              key={'toppane'}
              totalSales={summary.totalSales}
              goalSalesRate={analysis.totalSalesData.goalSalesRate}
              salesLastYearRate={analysis.totalSalesData.salesLastYearRate}
              visitorNum={analysis.totalSalesData.visitorNum}
              customerPayment={analysis.totalSalesData.customerPayment}
              laborCostRate={analysis.totalSalesData.laborCostRate}
              goalLaborCostRate={analysis.goalLaborCostRate}
              foodCostRate={analysis.totalSalesData.foodCostRate}
              goalFoodCostRate={analysis.goalFoodCostRate}
              isDisplayedStoreList={isDisplayedStoreList}
              onClick={onClick}
              onClickDailyReportList={onClickDailyReportList}
              onClickCostPredictionSetting={onClickCostPredictionSetting}
              selectedStore={selectedStore}
              userData={userData}
              tracking={tracking}
              selectedBannerType={selectedBannerType}
              changeSelectBannerType={changeBannerType}
              isShowPopup={isShowPopup}
              isShowCardModalLink={isShowCardModalLink}
              isShowShiftModalLink={isShowShiftModalLink}
              dataImportType={dataImportType}
            />
          </IndexWrapper>
        </React.Fragment>
      );
    case API_STATE_FAILED:
      return (
        <React.Fragment>
          <IndexWrapper>
            <MonthlySummary
              key={'toppane'}
              totalSales={summaryDummyData.totalSales}
              isDisplayedStoreList={isDisplayedStoreList}
              onClick={onClick}
              onClickDailyReportList={onClickDailyReportList}
              onClickCostPredictionSetting={onClickCostPredictionSetting}
              selectedStore={selectedStore}
              userData={userData}
              tracking={tracking}
              isDummy={true}
              selectedBannerType={selectedBannerType}
              changeSelectBannerType={changeBannerType}
              isShowPopup={isShowPopup}
              isShowCardModalLink={false}
              isShowShiftModalLink={false}
              dataImportType={dataImportType}
            />
          </IndexWrapper>
        </React.Fragment>
      );
  }
};

class StoreCalendarIndicesContainer extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isShowPopup: false,
    };
  }
  componentDidMount() {
    const {
      selectedStore,
      fetchStoreIndicesSummaryStart,
      fetchExistMonthResultList,
      batchProcessedDate,
      selectedDate,
      startFetchCardStatus,
      fetchCurrentMonthLastMonth,
      selectedMonth,
    } = this.props;

    let selectYear: number;
    if (selectedDate != null) {
      // 選択済みの場合は選択年を指定
      selectYear = mclDayjs(selectedDate, formatter.mapiYearMonth).year();
    } else {
      // 未選択の場合はバッチ実行年を指定
      selectYear = parser.fromDateObject(batchProcessedDate).year();
      selectedMonth(parser.fromDateObject(batchProcessedDate).format(formatter.mapiYearMonth));
    }

    fetchStoreIndicesSummaryStart();
    startFetchCardStatus();
    if (selectedStore != null) {
      fetchExistMonthResultList(AkrCode.of(selectedStore.akrCode), selectYear);
      fetchCurrentMonthLastMonth(AkrCode.of(selectedStore.akrCode));
    }
  }

  // date: YYYY-MM
  _onChangeMonth = (date: string): void => {
    const { selectedStore, selectedMonth, track, selectedIndicesType } = this.props;
    selectedMonth(date);
    track(_genOnChangeMonthLog(selectedStore?.akrCode, date, selectedIndicesType));
    this.props.fetchStoreIndicesAllData();
  };
  // date: {  key: YYYY年, value: YYYY年}
  _onChangeYear = (date: { key: string; value: string }): void => {
    const {
      selectedStore,
      fetchExistMonthResultList,
      selectedMonth,
      selectedDate,
      track,
      selectedIndicesType,
    } = this.props;
    if (selectedStore != null && selectedDate != null) {
      const selectedYear = mclDayjs(date.value, formatter.year);
      if (selectedYear != null) {
        fetchExistMonthResultList(AkrCode.of(selectedStore.akrCode), selectedYear.year());
        const yearMonth = mclDayjs(selectedDate, formatter.mapiYearMonth);
        if (yearMonth != null) {
          selectedMonth(selectedYear.year() + '-' + yearMonth.format(formatter.fixedMonth));
          track(_genOnChangeYearLog(selectedStore?.akrCode, date, selectedIndicesType));
        }
      }
      this.props.fetchStoreIndicesAllData();
    }
  };

  // タブ変更時の処理
  _handleChangeTab = (indicesType: keyof typeof INDICES_TYPE) => {
    const { changeSelectIndicesType, track } = this.props;
    changeSelectIndicesType(indicesType);
    localStorage.setItem(SELECTED_INDICES_TYPE_KEY_NAME, indicesType);
    track(_genChangeTabLog(indicesType === 'daily' ? 'indices' : indicesType));
  };

  getDisplayYearList = () => {
    const { batchProcessedDate } = this.props;
    return displayYearMonthList(batchProcessedDate);
  };

  _onClickGoalSetting = () => {
    const { selectedStore, changePeriod, selectStore, changeViewMode } = this.props;
    this.props.track(_genClickGoalSalesTooltipLink());
    if (selectedStore != null) {
      changeViewMode('eachStore');
      changePeriod(TargetSettingPeriod.currentYear);
      selectStore(selectedStore.akrCode);
    }
  };

  _onClickDailyReportList = (logType: 'laborCostRate' | 'foodCostRate') => {
    const feature: string =
      logType === 'laborCostRate' ? 'labor_cost_rate_tooltip' : 'prime_cost_rate_tooltip';
    const funcName: string =
      logType === 'laborCostRate'
        ? 'click_labor_cost_rate_tooltip_link'
        : 'click_prime_cost_rate_tooltip_link';
    this.props.track(_genClickDailyReportListTooltipLink(feature, funcName));
  };

  _onClickCostPredictionSetting = (logType: 'laborCostRate' | 'foodCostRate') => {
    const feature: string =
      logType === 'laborCostRate' ? 'labor_cost_rate_tooltip' : 'prime_cost_rate_tooltip';
    const funcName: string =
      logType === 'laborCostRate'
        ? 'click_labor_cost_rate_tooltip_link'
        : 'click_prime_cost_rate_tooltip_link';
    this.props.track(_genClickCostPredictionSettingTooltipLink(feature, funcName));
  };
  componentDidUpdate(prevState: Pick<StateProps, 'selectedStore'>): void {
    const { selectedStore, fetchCurrentMonthLastMonth } = this.props;
    if (prevState.selectedStore !== selectedStore) {
      if (selectedStore != null) {
        fetchCurrentMonthLastMonth(AkrCode.of(selectedStore.akrCode));
      }
    }
  }
  render() {
    const {
      selectedDate,
      selectedMonth,
      batchProcessedDate,
      track,
      selectedStore,
      storeCalendarIndicesState,
      selectedIndicesType,
      existMonthResultList,
      isDisplayedStoreList,
      userData,
      badgeAcquiredInfo,
      postBadgeList,
      unCheckedBadge,
      selectedBannerType,
      changeSelectBannerType,
      tutorialLoadingFlag,
      isSingleStore,
      dailySalesStoreDataState,
    } = this.props;
    if (
      selectedDate == null &&
      existMonthResultList.fetchExistMonthResultListState.type === API_STATE_COMPLETED
    ) {
      selectedMonth(parser.fromDateObject(batchProcessedDate).format(formatter.mapiYearMonth));
    }

    // 月初(毎月1日)かどうか判定するフラグ
    const isFirstDayOfMonth: boolean = mclDayjs().date() === 1;
    // 選択年が今年かどうか判定するフラグ
    const isSelectedThisYear: boolean =
      selectedDate != null && mclDayjs().year() === mclDayjs(selectedDate, formatter.mapiYearMonth).year();
    // 選択月が今月かどうか判定するフラグ
    const isSelectedThisMonth: boolean =
      selectedDate != null &&
      mclDayjs().pureMonth() === mclDayjs(selectedDate, formatter.mapiYearMonth).pureMonth();

    // 検索項目用の年リストを作成
    const yearOptions = Array.from(
      new Set(
        Array.from(this.getDisplayYearList())
          .map(yearMonth => parser.fromYearMonthObject(yearMonth).format(formatter.year))
          .reverse()
      ).values()
    );

    // 選択店舗の売上取込方法を取得
    const dataImportType =
      dailySalesStoreDataState.type === API_STATE_COMPLETED &&
      dailySalesStoreDataState.payload != null &&
      selectedStore?.akrCode != null
        ? dailySalesStoreDataState.payload.filter(item => item.akrCode === selectedStore?.akrCode)[0]
            .dataImportType
        : null;

    return (
      <Container>
        {/* Airレジ訴求ポップアップ */}
        {/* データがない場合はAPIがFAILで返る為、FAILも含む */}
        {(storeCalendarIndicesState.type === API_STATE_COMPLETED ||
          storeCalendarIndicesState.type === API_STATE_FAILED) &&
          this.state.isShowPopup && (
            <Waypoint
              onEnter={() => {
                selectedStore != null &&
                  track(_genImpressionPopupLog(selectedStore.isRegiUse, selectedStore.akrCode));
              }}
            >
              <PopUpWrapper>
                <AirRegiAppealPopup
                  onClickClose={() => {
                    this.setState({ isShowPopup: false });
                  }}
                  tracker={track}
                  isRegiUse={selectedStore != null && selectedStore.isRegiUse}
                  akrCode={selectedStore?.akrCode}
                />
              </PopUpWrapper>
            </Waypoint>
          )}
        <HeaderContents>
          <SelectStoreDate>
            <StyledTab selectedIndicesType={selectedIndicesType} onClickItem={this._handleChangeTab} />
            {selectedDate != null && (
              <StyledYearSelectBox
                id="store_indices_type_year_select"
                size="small"
                isOneLine
                placeholder={{
                  key: mclDayjs(selectedDate, formatter.mapiYearMonth).format(formatter.year),
                  value: mclDayjs(selectedDate, formatter.mapiYearMonth).format(formatter.year),
                }}
                options={yearOptions.map(yearOption => ({ key: yearOption, value: yearOption }))}
                isSelected
                onChange={this._onChangeYear}
              />
            )}
            {selectedDate != null && (
              <MonthSelectbox
                id="store_indices_type_month_select"
                onChange={this._onChangeMonth}
                selectedItem={selectedDate}
                targetList={existMonthResultList.fetchExistMonthResultListState}
                batchProcessedDate={batchProcessedDate}
                isFirstDayOfMonth={isFirstDayOfMonth}
              />
            )}
          </SelectStoreDate>
          <FaqLink track={track} faqTitle="使い方" faqLink={storeIndexFaq} pageName="store_indices" />
        </HeaderContents>
        <div>
          <IndexContent
            storeCalendarIndicesState={storeCalendarIndicesState}
            isDisplayedStoreList={isDisplayedStoreList}
            onClick={this._onClickGoalSetting}
            onClickDailyReportList={this._onClickDailyReportList}
            onClickCostPredictionSetting={this._onClickCostPredictionSetting}
            selectedStore={selectedStore}
            userData={userData}
            tracking={this.props.track}
            isFirstDayOfMonth={isFirstDayOfMonth}
            selectedBannerType={selectedBannerType}
            changeBannerType={changeSelectBannerType}
            isSelectedThisYear={isSelectedThisYear}
            isSelectedThisMonth={isSelectedThisMonth}
            isShowPopup={this.state.isShowPopup}
            dataImportType={dataImportType}
          />
          <Content
            storeCalendarIndicesState={storeCalendarIndicesState}
            selectedDate={selectedDate}
            selectedStore={selectedStore}
            tracking={this.props.track}
            plfGrant={this.props.plfGrant}
            isDisplayedStoreList={isDisplayedStoreList}
            batchProcessedDate={batchProcessedDate}
            firstEventDate={userData?.firstEventDate}
            badgeAcquiredInfo={badgeAcquiredInfo}
            postBadgeList={postBadgeList}
            unCheckedBadge={unCheckedBadge}
            isFirstDayOfMonth={isFirstDayOfMonth}
            isSelectedThisYear={isSelectedThisYear}
            isSelectedThisMonth={isSelectedThisMonth}
            isSingleStore={isSingleStore}
            tutorialLoadingFlag={tutorialLoadingFlag}
            showPopup={(isShow: boolean) => {
              this.setState({ isShowPopup: isShow });
            }}
            dataImportType={dataImportType}
          />
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state: ReduxState): StateProps => {
  return {
    userData: state.user.data,
    storeCalendarIndicesState: storeCalendarIndicesStateSelector(state),
    selectedStore: selectedStoreSelector(state),
    selectedDate: state.storeIndices.existMonthResultList.selectedMonth,
    batchProcessedDate: state.uiConfig.batchProcessedDate,
    isDisplayedStoreList: state.stores.isDisplayedStoreList,
    isSelectedThisMonth: judgeThisMonthSelector(state),
    selectedIndicesType: state.stores.selectedIndicesType,
    existMonthResultList: state.storeIndices.existMonthResultList,
    plfGrant: state.user.data != null ? state.user.data.plfGrant : undefined,
    badgeAcquiredInfo: state.badge.data,
    isLoading: state.stores.realTimeLoading,
    isLoaded: state.stores.realTimeLoaded,
    selectedBannerType: state.stores.selectedBannerType,
    tutorialLoadingFlag: state.noticeAndTodo.tutorialLoadingFlag,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => ({
  ...bindActionCreators(
    {
      fetchStoreIndicesSummaryStart: storeIndicesSummaryActions.fetchStoreIndicesSummaryStart,
      fetchStoreIndicesAllData: storeIndicesSummaryActions.fetchStoreIndicesAllData,
      track: track,
      changeSelectIndicesType: changeSelectIndicesType,
      fetchExistMonthResultList: existMonthResultListActions.fetchExistMonthResultList,
      selectedMonth: existMonthResultListActions.selectedMonth,
      selectStoreData,
      changePeriod: targetSettingActions.changePeriod,
      selectStore: targetSettingActions.selectStore,
      changeViewMode: targetSettingActions.changeViewMode,
      resetStoreIndices: storeIndicesSummaryActions.resetStoreIndices,
      postBadgeList: badgeListactions.postBadgeList,
      unCheckedBadge: commonUiActions.unCheckedBadge,
      changeSelectBannerType: changeSelectBannerType,
      startFetchCardStatus: cardSettingActions.startFetchCardStatus,
      fetchCurrentMonthLastMonth: currentMonthLastMonthActions.fetchCurrentMonthLastMonth,
    },
    dispatch
  ),
});

const Container = styled.div`
  height: 100%;
  position: relative;
`;

const HeaderContents = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const IndexWrapper = styled.div`
  height: calc(100% - 22px);
  width: 100%;
`;

const SelectStoreDate = styled.div`
  display: flex;
  top: 0;
`;

const StyledTab = styled(Tab)`
  margin-right: 16px;
`;

const StyledYearSelectBox = styled(SelectBox.normal)`
  margin-right: 12px;
  width: 110px;
`;

const PopUpWrapper = styled.div`
  position: fixed;
  bottom: 28px;
  right: 38px;
  z-index: 1;
`;

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StoreCalendarIndicesContainer));

const _genOnChangeMonthLog = (akrCode, date, indicesType: keyof typeof INDICES_TYPE) => {
  return genGaLog(
    'store_indices',
    'select_box',
    'select_month',
    {},
    { selected_date: date, akr_code: akrCode, selected_indices_type: INDICES_TYPE[indicesType].logName },
    'click'
  );
};

const _genOnChangeYearLog = (akrCode, date, indicesType: keyof typeof INDICES_TYPE) => {
  return genGaLog(
    'store_indices',
    'select_box',
    'select_year',
    {},
    {
      selected_date: date,
      akr_code: akrCode,
      selected_indices_type: INDICES_TYPE[indicesType].logName,
    },
    'change'
  );
};

const _genChangeTabLog = (indicesType: string) => {
  return genGaLog(
    'store_indices',
    'select_toggle',
    'select_Indices_type',
    {},
    { selected_indices_type: indicesType },
    'click'
  );
};

const _genClickGoalSalesTooltipLink = () => {
  return genGaLog('store_indices', 'goal_sales_tooltip', 'click_goal_sales_tooltip_link', {}, {}, 'click');
};

const _genClickDailyReportListTooltipLink = (feature: string, funcName: string) => {
  return genGaLog('store_indices', feature, funcName, {}, {}, 'click');
};

const _genClickCostPredictionSettingTooltipLink = (feature: string, funcName: string) => {
  return genGaLog('store_indices', feature, funcName, {}, {}, 'click');
};

const _genImpressionPopupLog = (isRegiUse: boolean, akrCode?: string) => {
  return genGaLog(
    'store_indices',
    'store_indices_crossuse_popup',
    'impression',
    {},
    {
      type: ['arg'],
      selected_indices_type: 'calendar',
      cu_status: isRegiUse ? 'use' : 'not_use',
    },
    'impression',
    akrCode
  );
};
