import React from 'react';
import styled from 'styled-components';
import LaborCostSampleCalendar from '../../../../icons/LaborCostSampleCalendar.svg';
import Vector from '../../../../icons/LaborCostSampleCalendarVector.svg';
import CloseIcon from '../../../../icons/close.svg';
import { lightgrayBorder, uploadBorderColor, white } from '../../../../constants/colors';
import { track } from '../../../../modules/logging';
import { Waypoint } from 'react-waypoint';
import { genGaLog } from '../../../../gaLogger';

type Props = {
  onClickClose: () => void;
  readonly track: typeof track;
};

/**
 * 人件費分析カレンダーポップアップ
 */
const CalendarSamplePopup = (props: Props) => {
  const { onClickClose, track } = props;
  return (
    <React.Fragment>
      <Waypoint
        onEnter={() => {
          track(_genImpressionPopupLog());
        }}
      >
        <Wrapper>
          <CloseButton
            onClick={() => {
              onClickClose();
              track(_genClosePopupLog());
            }}
          >
            <CloseIcon />
          </CloseButton>
          <Title>カレンダーの見かた</Title>
          <Content>
            <LaborCostSampleCalendar />
            <StyledVector />
            <NameWrapper>
              <strong>人件費率</strong>
              <p>
                人件費目標が未達成の場合は赤
                <br />
                字になります。
              </p>
              <strong>売上</strong>
              <strong>目標未達成の要因</strong>
            </NameWrapper>
          </Content>
        </Wrapper>
      </Waypoint>
    </React.Fragment>
  );
};

export default CalendarSamplePopup;

const Wrapper = styled.div`
  position: relative;
  width: 400px;
  height: 200px;
  border-radius: 8px;
  border: solid 1px ${uploadBorderColor};
  padding: 24px 24px 27px;
  display: flex;
  background: ${white};
  box-shadow: 0px 1px 6px 0px ${lightgrayBorder};
  display: flex;
  flex-direction: column;
`;

const Title = styled.h4`
  font-size: 14px;
  font-weight: 600;
`;

const Content = styled.div`
  position: relative;
  margin-top: 10px;
  display: flex;
`;
const NameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0px;
  right: 10px;
  strong {
    font-size: 14px;
    :nth-child(n + 2) {
      margin-top: 14px;
    }
  }
  p {
    font-size: 12px;
    font-weight: 400;
  }
`;

const StyledVector = styled(Vector)`
  position: absolute;
  top: 9px;
  left: 90px;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 16px;
  right: 18px;
  border: none;
  background: none;
  cursor: pointer;
`;

const _genImpressionPopupLog = () => {
  return genGaLog(
    'labor_cost_analysis',
    'labor_cost_analysis_sample_link',
    'impression',
    {},
    {},
    'impression'
  );
};

const _genClosePopupLog = () => {
  return genGaLog('labor_cost_analysis', 'labor_cost_analysis_sample_link', 'close', {}, {}, 'click');
};
