import { combineReducers, Reducer } from 'redux';
import { routerReducer } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';
import storeReducer from './stores';
import storeIndicesReducer from './storeIndices';
import voucherAnalysisReducer, { State as VoucherAnalysis } from './voucherAnalysis';
import userReducer from './user';
import userSetting, { State as UserSettingState } from './userSetting';
import launchReducer from './launch';
import allMenu from './allMenu';
import allIndex from './allIndex';
import basicSetting from './basicSetting';
import uiConfig from './uiConfig';
import dataOutput from './dataOutput';
import realtimeReducer from './realtime';
import logging from './logging';
import costManagement from './costManagement';
import costItemSetting from './costItemSetting';
import customizeDailyReportApi, { State as CustomizeDailyReportApiState } from './customizeDailyReport/api';
import customizeDailyReportUi, { State as CustomizeDailyReportUiState } from './customizeDailyReport/ui';
import targetSetting from './targetSetting';
import pastDataImport from './pastDataImport';
import monthlyLookbackApi, { State as MonthlyLookbacApikState } from './monthlyLookback/api';
import monthlyLookbackUi, { State as MonthlyLookbackUiState } from './monthlyLookback/ui';
import noticeAndTodo from './noticeAndTodo';
import assignedStores from './assignedStores';
import dailyReportReducer from './dailyReport';
import dailyCostList from './dailyCostList';
import laborCostAnalysis from './laborCostAnalysis';
import channelIO from './channelIO/channelIO';
import channelIOUserInfo from './channelIO/channelIOUserInfo';
import cardSetting from './cardSetting';
import cardCostList from './cardCostList';
import invoiceCostList from './invoiceCostList/';
import payment from './payment';
import newYearReport from './newYearReport';
import yearlyReport from './yearlyReport';
import shiftImportSetting from './shiftImportSetting';

import { State as AllIndexState } from '../modules/allIndex';
import { State as AllMenuState } from '../modules/allMenu';
import { State as CostManagementState } from '../modules/costManagement';
import { State as DataOutputState } from '../modules/dataOutput';
import { State as LaunchState } from '../modules/launch';
import { State as LoggingState } from '../modules/logging';
import { State as NoticeAndTodoState } from '../modules/noticeAndTodo';
import { State as PastDataImportState } from '../modules/pastDataImport';
import { State as RealtimeState } from '../modules/realtime';
import { State as StoreIndicesState } from '../modules/storeIndices';
import { State as StoresState } from '../modules/stores';
import { State as TargetSettingState } from '../modules/targetSetting';
import { State as BasicSettingState } from './basicSetting';
import { State as UiConfigState } from '../modules/uiConfig';
import { State as UserState } from '../modules/user';
import { State as DailyReportState } from '../modules/dailyReport';
import { State as AssignedStoresState } from '../modules/assignedStores';
import { State as DailyReportManagementState } from './dailyCostList';
import { State as LaborCostAnalysisState } from '../modules/laborCostAnalysis';
import storeMonthlyIndices, { State as StoreMonthlyIndicesState } from '../modules/storeMonthlyIndices';
import { State as ChannelIOState } from '../modules/channelIO/channelIO';
import { State as ChannelIOUserInfoState } from '../modules/channelIO/channelIOUserInfo';
import dailySales, { State as DailySalesState } from '../modules/dailySales';
import dayofweekHourly, { State as DayofweekHourly } from '../modules/dayofweekHourly';
import { State as CardSettingState } from '../modules/cardSetting/';
import { State as cardCostListState } from '../modules/cardCostList/';
import { State as BadgeState } from '../modules/badge';
import { State as InvoiceCostListState } from '../modules/invoiceCostList/';
import badgeReducer from './badge';
import { State as PaymentState } from '../modules/payment/';
import { State as NewYearReportState } from '../modules/newYearReport/';
import { State as YearlyReportState } from '../modules/yearlyReport/';
import { State as ShiftImportSettingState } from '../modules/shiftImportSetting';

// reduxのstore全体の型を作成
export type State = {
  readonly stores: StoresState;
  readonly storeIndices: StoreIndicesState;
  readonly storeMonthlyIndices: StoreMonthlyIndicesState;
  readonly dayofweekHourly: DayofweekHourly;
  readonly voucherAnalysis: VoucherAnalysis;
  readonly user: UserState;
  readonly userSetting: UserSettingState;
  readonly launch: LaunchState;
  readonly allMenu: AllMenuState;
  readonly allIndex: AllIndexState;
  readonly basicSetting: BasicSettingState;
  readonly uiConfig: UiConfigState;
  readonly dataOutput: DataOutputState;
  readonly realtime: RealtimeState;
  readonly logging: LoggingState;
  readonly costManagement: CostManagementState;
  readonly targetSetting: TargetSettingState;
  readonly monthlyLookbackApi: MonthlyLookbacApikState;
  readonly monthlyLookbackUi: MonthlyLookbackUiState;
  readonly noticeAndTodo: NoticeAndTodoState;
  readonly pastDataImport: PastDataImportState;
  readonly customizeDailyReportApi: CustomizeDailyReportApiState;
  readonly customizeDailyReportUi: CustomizeDailyReportUiState;
  readonly assignedStores: AssignedStoresState;
  readonly dailyReport: DailyReportState;
  readonly dailyCostList: DailyReportManagementState;
  readonly laborCostAnalysis: LaborCostAnalysisState;
  readonly channelIO: ChannelIOState;
  readonly channelIOUserInfo: ChannelIOUserInfoState;
  readonly dailySales: DailySalesState;
  readonly cardSetting: CardSettingState;
  readonly cardCostList: cardCostListState;
  readonly badge: BadgeState;
  readonly payment: PaymentState;
  readonly newYearReport: NewYearReportState;
  readonly invoiceCostList: InvoiceCostListState;
  readonly yearlyReport: YearlyReportState;
  readonly shiftImportSetting: ShiftImportSettingState;
};

// reduxのツリーを結合し、app全体のreducerを作る
const rootReducer: Reducer = combineReducers({
  router: routerReducer,
  // react-router-redux
  form: formReducer,
  // redux-form
  stores: storeReducer,
  storeIndices: storeIndicesReducer,
  storeMonthlyIndices,
  dayofweekHourly,
  voucherAnalysis: voucherAnalysisReducer,
  user: userReducer,
  userSetting,
  launch: launchReducer,
  allMenu,
  allIndex,
  basicSetting,
  uiConfig: uiConfig,
  dataOutput,
  realtime: realtimeReducer,
  logging,
  costManagement,
  customizeDailyReportApi,
  customizeDailyReportUi,
  costItemSetting,
  targetSetting,
  monthlyLookbackApi,
  monthlyLookbackUi,
  noticeAndTodo,
  pastDataImport,
  assignedStores,
  dailyReport: dailyReportReducer,
  dailyCostList,
  laborCostAnalysis,
  channelIO,
  channelIOUserInfo,
  dailySales,
  cardSetting,
  cardCostList,
  badge: badgeReducer,
  payment,
  newYearReport,
  invoiceCostList,
  yearlyReport,
  shiftImportSetting,
});
export default rootReducer;
